// ================================================================================
// Block Editor
// ブロックエディタのスタイル - 管理画面側
// ================================================================================

// ----------------------------------------------------------------
// ページタイトル
// ----------------------------------------------------------------

.edit-post-visual-editor__post-title-wrapper {
	position: relative;
	.editor-post-title__input {
		position: relative;
		padding-top: 0;
		padding-bottom: 0;
		margin: 0 auto 2.4rem;
		// typography
		font-family: $font_title;
		color: $color_dark;
		font-weight: 700;
		font-size: 2.3rem;
		line-height: 1.62;
		@media #{$md_devices} {
			font-size: 4.2rem;
		}
	}
}



// ----------------------------------------------------------------
// コンテンツ
// ----------------------------------------------------------------

// NOTE: elements の html, body に height: 100%; が指定されいている場合、Editor 内がおかしくなるのでオーバーライト
.editor-styles-wrapper {
	height: auto !important;
}

// NOTE: wp-block クラスは各ブロックに必ず付与されている。
.wp-block {
	box-sizing: border-box;
	max-width: calc( 100vw - 8.53% * 2 );
	margin-left: auto;
	margin-right: auto;
	@media #{$md_devices} {
		width: calc( 100vw - 2rem * 2 );
		max-width: 100rem;
	}

	&[data-align="wide"],
	&.alignwide {
	}

	&[data-align="full"],
	&.alignfull {
	}

	&.wp-block-group {
	}

}

.editor-styles-wrapper .wp-block-html {
	margin-bottom: 2.4rem;
}
