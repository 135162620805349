@charset "UTF-8";
// ================================================================================
// Page
// 固定ページのスタイル
// ================================================================================

// ----------------------------------------------------------------
//	General
// ----------------------------------------------------------------

.page-content {
	margin-top: 3.6rem;

	@media #{$md_devices} {
		margin-top: 8.8rem;
	}

	p:not([class]) {

		@media #{$sm_devices_only} {
			text-align: left;
		}

	}
}

.page-container {
	text-align: center;
	overflow: hidden;

	& + & {
		margin-top: 6.4rem;

		@media #{$md_devices} {
			margin-top: 11.2rem;
		}
	}
}
.page-container--low {

	& + .page-container {

		@media #{$md_devices} {
			margin-top: 0;
		}
	}
}


// Background
// --------------------------------------------------------

.page-container--wave {
	margin: 2rem 0;
	background-color: $color_bg_light_green;
	z-index: 2;
	overflow: inherit;

	@media #{$md_devices} {
		margin: 6rem 0;
	}

	&::before {
		@include ps_icon(-2rem, 0, auto, 0);
		width: 100%;
		height: 2rem;
		background: url('../images/common/bg_wave_top.png') 50% 50% repeat-x;
		background-size: cover;

		@media #{$md_devices} {
			height: 6rem;
			top: -6rem;
		}
	}
	&::after {
		@include ps_icon(auto, 0, -2rem, 0);
		width: 100%;
		height: 2rem;
		background: url('../images/common/bg_wave_bottom.png') 50% 50% repeat-x;
		background-size: cover;

		@media #{$md_devices} {
			height: 6rem;
			bottom: -6rem;
		}
	}
}
.page-container--primary {
	padding: 4.8rem 0;
	background: $color_bg_primary;

	@media #{$md_devices} {
		padding: 8rem 0;
	}
}
.page-container--secondary {
	padding: 4.8rem 0;
	background: $color_bg_secondary;

	@media #{$md_devices} {
		padding: 8rem 0;
	}
}
.page-container--tertiary {
	padding: 4.8rem 0;
	background: $color_bg_tertiary;

	@media #{$md_devices} {
		padding: 8rem 0;
	}
}

.page-bg-cloud--orange {
	margin-top: 4rem;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		margin: 7.2rem 0 14rem;
	}

	&::before {
		@include ps_icon(-2.6rem, 0, auto, auto);
		width: 60.7rem;
		height: 56.6rem;
		background: url('../images/common/bg_cloud_lightorange.svg') 50% 50% no-repeat;
		background-size: contain;
		z-index: -1;

		@media #{$md_devices} {
			width: 79.8rem;
			height: 74.4rem;
			top: -7.2rem;
			left: -22.2rem;
			right: auto;
		}
	}
}
.page-bg-cloud--orange-r {
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		margin: 6.7rem auto 11.8rem;
	}

	&::before {
		@include ps_icon(19rem, -31.5rem, auto, auto);
		width: 59rem;
		height: 60.6rem;
		background: url('../images/common/bg_cloud_lightorange-r.svg') 50% 50% no-repeat;
		background-size: contain;
		z-index: -1;

		@media #{$md_devices} {
			width: 78.6rem;
			height: 80.8rem;
			top: -6.7rem;
			right: -43.1rem;
		}
	}
}

.page-bg-coversion {
	padding: 5.6rem 0;
	background: url('../images/common/bg_conversion.png') 50% 0 no-repeat;
	background-size: cover;

	@media #{$md_devices} {
		padding: 9.4rem 0;
		background-position: 50% 50%;
	}
}

.page-bg--gold {
	padding: 4.8rem 0;
	color: #fff;
	background-color: #A99560;

	@media #{$md_devices} {
		padding: 8rem 0;
	}

	.headline-01,
	.headline-02,
	.headline-03 {
		color: #fff
	}
}


// Heading
// --------------------------------------------------------

.page-heading {
	@extend .inner;
	text-align: center;

	.inner & {
		width: 100%;
	}
	.inner & ~ & {
		margin-top: 5.6rem;

		@media #{$md_devices} {
			margin-top: 11.2rem;
		}
	}

	.headline-01 {
		margin-bottom: 3.2rem;

		@media #{$md_devices} {
			margin-bottom: 4rem;
		}
	}
	.headline-02 {
		margin-bottom: 3.2rem;

		@media #{$md_devices} {
			margin-bottom: 4rem;
		}

		&.is-shop-archive {
			margin-bottom: 1.6rem;

			@media #{$md_devices} {
				margin-bottom: 2.4rem;
			}
		}
	}
	.headline-03 {
		margin-bottom: 2rem;

		@media #{$md_devices} {
			margin-bottom: 3.2rem;
		}
	}
	.headline-unique-02 {
		margin-bottom: 4.4rem;

		@media #{$md_devices} {
			margin-bottom: 5.6rem;
		}
	}
}

.page-heading--sub {
	width: 100%;
	margin-top: 3.2rem;

	@media #{$md_devices} {
		margin-top: 5.6rem;
	}
}


// Button
// --------------------------------------------------------

.page-button-container {
	margin-top: 3.2rem;
	text-align: center;

	@media #{$md_devices} {
		margin-top: 5.6rem;
	}
}
.page-button-container--md {
	margin-top: 2.4rem;
	text-align: center;

	@media #{$md_devices} {
		margin-top: 3.2rem;
	}
}


// ----------------------------------------------------------------
//	Order Pillow List
// ----------------------------------------------------------------

.order-pillow-lineup {
	width: 100%;
	margin-top: 3.6rem;
	text-align: left;

	@media #{$md_devices} {
		margin-top: 4.8rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
.order-pillow-lineup__item {
	width: 100%;
	margin-top: 4.8rem;
	color: $color_default;
	text-decoration: none;
	display: block;

	@media #{$sm_devices_only} {

		&:first-child {
			margin-top: 0;
		}
	}

	@media #{$md_devices} {
		width: calc( ( 100% - 5.6rem ) / 2 );
		padding-top: 4.8rem;
		border-top: .1rem solid $color_border;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&:nth-child(-n+2) {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
		}
	}
}
.order-pillow-lineup-content-box {

	@media #{$md_devices} {
		padding: 0 1.8rem;
	}
}
.order-pillow-lineup-content-box__label {
	width: 100%;
	max-width: 35.2rem;
	margin: 0 auto 2.4rem;
	padding: .4rem .3rem;
	font-size: 1.4rem;
	font-weight: 600;
	color: $color_default;
	text-align: center;
	background-color: $color_bg_gray;
	border-radius: 1.95rem;

	@media #{$md_devices} {
		font-size: 1.6rem;
		padding: .3rem;
	}

	// setting
	&.order-pillow-lineup-content-box__label--standard {
		color: $color_main;
		background-color: #E0F7EE;
	}
	&.order-pillow-lineup-content-box__label--premium {
		color: #fff;
		background-color: $color_main;
	}
	&.order-pillow-lineup-content-box__label--prime-regular {
		color: #716034;
		background-color: #EBE4D2;
	}
	&.order-pillow-lineup-content-box__label--prime-premium {
		color: #fff;
		background-color: #9B854B;
	}
}
.order-pillow-lineup-content-box__img {
	width: 100%;
	height: 0;
	margin: 0 auto 1.6rem;
	padding-top: calc( 280 / 400 * 100% );
	border-radius: .6rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: calc( 100% - 3.6rem );
		margin: 0 auto 2.4rem;
		padding-top: calc( 280 / 436 * 100% );
	}

	img {
		@include ps_icon(-100%,-100%,-100%,-100%);
		content: none;
		width: auto;
		max-width: inherit;
		height: 100%;
		z-index: 1;
	}
}
.order-pillow-lineup-content-box__title {
	margin-bottom: 1.2rem;
	font-size: 1.6rem;
	font-weight: 600;
	color: $color_dark;
	text-align: center;
	line-height: 1.363;

	@media #{$md_devices} {
		margin-bottom: 2.4rem;
		font-size: 2.2rem;
	}
}
.order-pillow-lineup-content-box__info {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.order-pillow-lineup-content-price {
	font-size: 2rem;
	font-weight: 600;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 2.6rem;
	}

	span {
		margin-left: .2rem;
		font-size: .46em;
	}
}
.order-pillow-lineup-content-label {
	padding: .5rem 1rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: $color_alert;
	line-height: 1.38;
	background-color: #fff;
	display: inline-block;
	border: .1rem solid $color_alert;
	border-radius: .2rem;

	@media #{$md_devices} {
		padding: .7rem 1rem;
		font-size: 1.3rem;
	}

	// setting
	&.order-pillow-lineup-content-label--red {
		color: #fff;
		background-color: $color_alert;
	}

	.order-pillow-lineup-content-price + & {
		margin-left: .8rem;

		@media #{$md_devices} {
			margin-left: 1.6rem;
		}
	}
}


// ----------------------------------------------------------------
//	Mattress
// ----------------------------------------------------------------

.mattress-index-check-img {
	width: 100%;

	@media #{$md_devices} {
		width: 41.2rem;
		margin: 0 0 0 auto;
	}
}

.mattress-index-figure-box {
	width: 100%;
	margin: 3.2rem auto 0;

	@media #{$md_devices} {
		max-width: 72.2rem;
		margin: 4.8rem auto 0;
		display: flex;
		justify-content: center;
	}
}
.mattress-index-figure-box__item {
	@include reset;
	width: 100%;

	@media #{$md_devices} {
		width: calc( 295 / 722 * 100% );
	}

	&:last-child {
		margin: 1.6rem 0 0 0;

		@media #{$md_devices} {
			width: calc( 406 / 722 * 100% );
			margin: 0 0 0 2rem;
		}
	}
}
.mattress-index-comparison {
	margin-top: 4rem;

	@media #{$md_devices} {
		margin-top: 4.8rem;
		display: flex;
		justify-content: space-between;
	}
}
.mattress-index-comparison__item {
	width: 100%;
	padding: 2rem;
	font-size: 1.3rem;
	border-radius: .8rem;
	background-color: #F6F6F6;
	text-align: left;

	@media #{$md_devices} {
		width: calc( ( 100% - 3.2rem ) / 2 );
		padding: 2.4rem;
		font-size: 1.4rem;
	}

	& + & {
		margin-top: 2rem;

		@media #{$md_devices} {
			margin-top: 0;
		}
	}
}
.mattress-index-comparison-title {
	margin-bottom: 1.2rem;
	font-size: 1.6rem;
	font-weight: 600;

	@media #{$md_devices} {
		margin-bottom: 1.6rem;
		font-size: 1.8rem;
		text-align: center;
	}
}
.mattress-index-lineup-list {
	width: 100%;
	margin-top: 3.2rem;
	text-align: left;

	@media #{$md_devices} {
		margin-top: 4.8rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
.mattress-index-lineup-list__item {
	width: 100%;
	margin-top: 4.8rem;
	color: $color_default;
	text-decoration: none;
	display: block;

	@media #{$sm_devices_only} {
		&:first-child {
			margin-top: 0;
		}
	}

	@media #{$md_devices} {
		width: calc( ( 100% - 5.6rem ) / 2 );
		margin-top: 4.8rem;
		padding-top: 4.8rem;
		border-top: .1rem solid $color_border;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&:nth-child(-n+2) {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
		}
	}
}
.mattress-index-lineup-content-label {
	width: 100%;
	max-width: 21.7rem;
	margin: 0 auto 1.6rem;
	padding: .3rem 1rem;
	font-size: 1.4rem;
	font-weight: 600;
	color: #fff;
	text-align: center;
	background-color: #D71A26;
	border-radius: 1.95rem;

	@media #{$md_devices} {
		max-width: 26.4rem;
		margin: 0 auto 2.4rem;
		font-size: 1.6rem;
	}
}
.mattress-index-lineup-content-box {
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media #{$md_devices} {
		align-items: center;
	}
}
.mattress-index-lineup-content-box__head {
	@media #{$sm_devices_only} {
		padding-right: .8rem;
		flex: 1;
	}
}
.mattress-index-lineup-content-logo {
	margin-bottom: .8rem;
	display: flex;
	align-items: center;

	@media #{$md_devices} {
		margin-bottom: 1.6rem;
	}

	img {
		max-width: inherit;
		max-height: 3.6rem;

		@media #{$md_devices} {
			max-height: 4rem;
		}

		// setting
		.airportable & {
			max-height: 2.6rem;

			@media #{$md_devices} {
				max-height: 3.6rem;
			}
		}
		.andfree & {
			max-height: 1.8rem;

			@media #{$md_devices} {
				max-height: 2.5rem;
			}
		}
	}
}
.mattress-index-lineup-content-logo__text {
	margin: 0 -4.5rem 0 .7rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.6;
	position: relative;
	z-index: 2;

	@media #{$md_devices} {
		margin: 0 -2rem 0 .8rem;
		font-size: 1.1rem;
		line-height: 1.636;
	}
}
.mattress-index-lineup-content-title {
	margin: 0;
	font-size: 1.4rem;
	color: $color_dark;
	line-height: 1.75;

	@media #{$md_devices} {
		padding-right: .4rem;
		font-size: 1.6rem;
	}
}
.mattress-index-lineup-content-box__img {
	width: 40%;

	@media #{$md_devices} {
		width: auto;
	}

	img {

		@media #{$md_devices} {
			max-width: inherit;
			max-height: 13.8rem;
		}

		// setting
		.andfree & {
			border-radius: .6rem;
		}
	}
}
.mattress-index-lineup-content-description {
	margin-top: 2rem;
	padding: .8rem 1.6rem;
	font-size: 1.3rem;
	font-weight: 600;
	color: $color_default;
	text-align: left;
	line-height: 1.53;
	background-color: #F6F6F6;
	border-radius: .4rem;

	@media #{$md_devices} {
		margin-top: 2.4rem;
		padding: .7rem 1rem;
		font-size: 1.4rem;
		text-align: center;
		line-height: inherit;
	}
}

.mattress-index-fitting {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;

	@media #{$md_devices} {
		flex-direction: row;
		justify-content: space-between;
	}
}
.mattress-index-fitting__img {
	width: 100%;
	margin: 2rem 0 0 0;

	@media #{$md_devices} {
		max-width: 48.1%;
		margin: 0 0 0 -8%;
	}
}
.mattress-index-fitting__item {
	width: 100%;

	@media #{$md_devices} {
		width: 58%;
		text-align: left;
	}
}


// ----------------------------------------------------------------
//	Order Pillow
// ----------------------------------------------------------------

.order-pillow {

	.page-container--wave {
		margin-top: 7.2rem;

		@media #{$md_devices} {
			margin-top: 11.8rem;
		}
	}
}
.order-pillow-intro {
	padding-top: 7.2rem;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		padding-top: 10rem;
	}

	&::before {
		@include ps_icon(-4rem, 0, auto, 0);
		width: 5.9rem;
		height: 9.6rem;
		background: url('../images/order_pillow/img_order_pillow_intro_title.svg') 50% 50% no-repeat;
		background-size: contain;
		z-index: 2;

		@media #{$md_devices} {
			width: 9.9rem;
			height: 16rem;
			top: -8.2rem;
		}
	}
}

.order-pillow-babble {
	width: calc( 100% + 1.4rem );
	margin: 3.2rem 0 0 -.7rem;
	padding-bottom: 7.2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@media #{$md_devices} {
		width: 100%;
		margin: 6rem 0 0 0;
		padding-bottom: 9.5rem;
	}

	.babble {
		margin: 3.2rem 1.2rem 0 0;
		padding: 1.2rem;
		font-size: 1.2rem;
		text-align: left;

		@media #{$md_devices} {
			margin: 4.8rem 4rem 0 0;
			padding: 1.2rem 3.2rem;
			font-size: 1.6rem;
		}

		&:last-child {
			margin-right: 0;
		}

		@media #{$sm_devices_only} {

			&:nth-child(2n) {
				margin-right: 0;
			}

			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}

		@media #{$md_devices} {

			&:nth-child(-n+3) {
				margin-top: 0;
			}
		}
	}
}

.order-pillow-quote {
	width: 100%;
	margin: 3.6rem auto 6.8rem;
	border: .4rem solid $color_main;
	border-radius: 1.2rem;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		max-width: 48rem;
		margin: 4.8rem auto 6.2rem;
	}

	&::before,
	&::after {
		@include ps_icon(0, 0, 0, 0);
		width: 4rem;
		height: 4rem;
		border: .6rem solid #fff;
		z-index: 2;

		@media #{$md_devices} {
			width: 4.8rem;
			height: 4.8rem;
		}
	}
	&::before {
		top: -.5rem;
		left: -.5rem;
		right: auto;
		bottom: auto;
		border-right: none;
		border-bottom: none;
		border-radius: 1.2rem 0 0 0;
	}
	&::after {
		top: auto;
		left: auto;
		right: -.5rem;
		bottom: -.5rem;
		border-top: none;
		border-left: none;
		border-radius: 0 0 1.2rem 0;
	}
}
.order-pillow-quote__inner {
	padding: 2.3rem;
	text-align: center;
	position: relative;
	z-index: 3;

	@media #{$md_devices} {
		padding: 2rem;
	}

	&::before,
	&::after {
		@include ps_icon(0, 0, 0, 0);
		width: 3.2rem;
		height: 3.2rem;
		color: #fff;
		background-color: $color_main;
		border-radius: 100%;
		z-index: 4;

		@media #{$md_devices} {
			width: 4rem;
			height: 4rem;
		}
	}
	&::before {
		top: -1.4rem;
		left: -1.4rem;
		right: auto;
		bottom: auto;
		background-image: url('../images/common/icon_ld_quote.svg');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 2.08rem 1.6rem;

		@media #{$md_devices} {
			background-size: 2.6rem 2rem;
		}
	}
	&::after {
		top: auto;
		left: auto;
		right: -1.4rem;
		bottom: -1.4rem;
		background-image: url('../images/common/icon_rd_quote.svg');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 2.08rem 1.6rem;

		@media #{$md_devices} {
			background-size: 2.6rem 2rem;
		}
	}
}
.order-pillow-quote-text {
	margin: 0 0 .4rem 0;
	font-size: 1.6rem;
	font-family: $font_title;
	font-weight: 600;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 1.8rem;

		.msie & {
			margin-bottom: 3.2rem;
		}
	}
}
.order-pillow-quote-text--lg {
	margin: 0;
	font-size: 2rem;
	line-height: 1;

	@media #{$md_devices} {
		font-size: 2.4rem;
	}

	span {
		font-size: 6.4rem;
		color: $color_alert;
		letter-spacing: -.04rem;

		@media #{$md_devices} {
			font-size: 8.8rem;
		}
	}
}
.order-pillow-quote-note {
	margin: 1.8rem 0 0 0;
	font-size: 1.1rem;
	color: $color_dark_thin;

	@media #{$md_devices} {

		.msie & {
			margin-top: -4rem;
		}
	}
}

.order-pillow-case {
	width: 100%;
	margin-top: 3.2rem;

	@media #{$md_devices} {
		display: flex;
		align-items: center;
	}
}
.order-pillow-case__description {
	font-size: 1.3rem;
	color: $color_dark;
	text-align: left;

	@media #{$md_devices} {
		font-size: 1.4rem;
		flex: 1;
	}
}
.order-pillow-case__img {
	width: 70%;
	margin: 3.2rem auto 0;

	@media #{$md_devices} {
		width: 26.1rem;
		margin: 0 0 0 3.5rem;
	}
}

.order-pillow-necessity {
	width: 100%;
	padding: 4.8rem 0;
	padding-bottom: 100%;
	text-align: left;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		padding: 8.8rem 0;
	}

	&::after {
		@include ps_icon(auto, 0, -9.8rem, 0);
		width: 27.6rem;
		height: 38.3rem;
		background: url('../images/order_pillow/img_order_pillow_03.png') 50% 50% no-repeat;
		background-size: cover;
		z-index: -1;

		@media #{$md_devices} {
			width: 43.9rem;
			height: 60.9rem;
			top: 2.8rem;
			left: auto;
			right: 3.2rem;
			bottom: auto;
		}
	}
}
.order-pillow-necessity__content {
	width: 100%;
	margin: 0 auto;

	@media #{$md_devices} {
		width: 50%;
		margin: 0 auto 0 0;
		padding-right: 2rem;
	}

	.page-heading {
		text-align: left;
	}
}

.order-pillow-measure {
	width: 100%;
	display: flex;
	flex-direction: column;

	@media #{$md_devices} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

}
.order-pillow-measure__description {
	width: 100%;
	text-align: left;

	@media #{$md_devices} {
		max-width: 58%;
	}
}
.order-pillow-measure__img {
	width: 100%;
	margin: 4rem 0 0 0;
	position: relative;
	z-index: -1;

	@media #{$md_devices} {
		max-width: 52.9%;
		margin: 1.8rem -15.1% 0 0;
	}
}


// ----------------------------------------------------------------
//	Guide
// ----------------------------------------------------------------

.guide {

	.page-container--secondary + .page-container--tertiary {
		margin-top: 3.2rem;

		@media #{$md_devices} {
			margin-top: 4.8rem;
		}
	}
}
.guide-flow {
	width: 100%;
	margin-top: 4rem;

	@media #{$md_devices} {
		margin-top: 4.8rem;
	}
}
.guide-flow__item {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
	}

	& + & {
		margin-top: 4.8rem;
	}
}
.guide-flow-description {
	width: 100%;
	padding-left: 5.6rem;
	text-align: left;
	flex: 1;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		max-width: 57.5rem;
		margin-right: 2.4rem;
		padding-left: 9.6rem;
	}
}
.guide-flow-description-num {
	width: 4.4rem;
	height: 4.4rem;
	font-size: 3.2rem;
	font-family: $font_english;
	color: #fff;
	line-height: 4.4rem;
	letter-spacing: .216rem;
	text-align: center;
	text-indent: -.5rem;
	background-color: $color_main;
	border-radius: 100%;
	position: absolute;
	top: -.8rem;
	left: 0;
	z-index: 1;

	@media #{$md_devices} {
		width: 6.4rem;
		height: 6.4rem;
		font-size: 4.4rem;
		line-height: 6.4rem;
		top: 0;
	}
}
.guide-flow-description-title {
	@extend .headline-03;
	margin: 0 0 2.4rem;

	@media #{$md_devices} {
		margin: 1.1rem 0 1.6rem;
	}
}
.guide-flow-description-text {
	margin-left: -5.6rem;

	@media #{$md_devices} {
		margin-left: 0;
	}
}
.guide-flow-img {
	width: 100%;
	margin-top: 2.4rem;
	text-align: center;

	@media #{$md_devices} {
		width: 40rem;
		margin-top: 0;
	}
}

.guide-check-img {
	width: 100%;

	@media #{$md_devices} {
		width: 41.2rem;
		margin: 0 0 0 auto;
	}
}

.guide-washing-anchor {
	width: 100%;
	margin-bottom: 5.6rem;

	@media #{$md_devices} {
		display: flex;
		margin-bottom: 6.4rem;
	}
}
.guide-washing-anchor__item {
	width: 100%;

	@media #{$sm_devices_only} {

		& + & {
			margin-top: 2.8rem;
		}

	}

	@media #{$md_devices} {
		width: calc( ( 100% - 3.2rem ) / 2 );
		margin-right: 3.2rem;

		&:last-child {
			margin-right: 0;
		}
	}

	.button--anchor {
		padding: 1.2rem 1rem 1.6rem;
		font-size: 1.4rem;
		border-radius: 3.3rem;

		@media #{$md_devices} {
			padding: 1.9rem 1rem;
			font-size: 1.4rem;
			border-radius: 2.8rem;
		}
	}
}

.guide-washing-spec {
	width: 100%;
	text-align: left;

	@media #{$md_devices} {
		display: flex;
	}
}
.guide-washing-spec__item {
	width: 100%;

	@media #{$sm_devices_only} {
		margin-top: 3.2rem;
	}

	@media #{$md_devices} {
		width: calc( ( 100% - 3.2rem ) / 2 );
		margin-right: 3.2rem;

		&:last-child {
			margin-right: 0;
		}
	}
}
.guide-washing-spec-title {
	margin: 0 0 1.2rem 0;
	font-size: 1.6rem;
	font-weight: 600;
	color: $color_dark;
	line-height: 1.67;

	@media #{$md_devices} {
		margin: 0 0 1.6rem 0;
		font-size: 1.8rem;
	}

	& ~ & {
		margin-top: 2.4rem;

		@media #{$md_devices} {
			margin-top: 3.2rem;
		}
	}
}
.guide-washing-spec-table {

	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		border: .1rem solid #CECECE;
		border-radius: .8rem;
		table-layout: fixed;

		@media #{$md_devices} {
			table-layout: inherit;
		}

		th {
			padding: .9rem 2.4rem;
			font-size: 1.2rem;
			font-weight: 600;
			color: $color_default;
			text-align: left;
			background-color: $color_bg_gray;
			border-bottom: .1rem solid #CECECE;

			@media #{$md_devices} {
				font-size: 1.4rem;
			}

			& + th {
				width: 7.2rem;
				padding: .9rem 1.1rem;
				text-align: center;
				border-left: .1rem solid #CECECE;

				@media #{$md_devices} {
					width: 16rem;
					padding: .9rem 2.4rem;
				}
			}
		}
		td {
			padding: 1.2rem 2.4rem;
			font-size: 1.2rem;
			line-height: 1.5;
			border-bottom: .1rem solid #CECECE;

			@media #{$md_devices} {
				padding: 1.1rem 2.4rem;
				font-size: 1.4rem;
			}

			& + td {
				text-align: center;
				border-left: .1rem solid #CECECE;
			}
		}
		tr:last-child td {
			border-bottom: none;
		}

		tr:first-child th:first-child {
			border-radius: .7rem 0 0 0;
		}
		tr:first-child th:last-child {
			border-radius: 0 .7rem 0 0;
		}
		tr:last-child td:first-child {
			border-radius: 0 0 .7rem 0;
		}
		tr:last-child th:last-child {
			border-radius: 0 0 0 .7rem;
		}
	}

	// setting
	&.order {
		th {
			background-color: $color_bg_secondary;
		}
	}
	&.prime {
		th {
			background-color: $color_bg_tertiary;
		}
	}
}

.guide-box {
	width: 100%;
	padding: 2.4rem;
	background-color: #fff;
	border-radius: 1.2rem;

	@media #{$md_devices} {
		padding: 3.2rem;
	}

	&:first-of-type {
		margin-top: 3.2rem;

		@media #{$md_devices} {
			margin-top: 4.8rem;
		}
	}

	& + & {
		margin-top: 3.2rem;
	}

	// setting
	&.prime {
		.headline-03 {
			color: $color_blue;
		}
	}
}
.guide-box-content {
	width: 100%;
	margin-top: 2rem;
	font-size: 1.3rem;
	text-align: left;

	@media #{$md_devices} {
		margin-top: 3rem;
		font-size: 1.4rem;
		display: flex;
		flex-direction: row-reverse;
	}
}
.guide-box-content__description {

	@media #{$md_devices} {
		flex: 1;
	}

	.list-num__item {

		&::before {

			// setting
			.order & {
				color: #fff;
				background-color: $color_main;
			}
			.prime & {
				color: #fff;
				background-color: $color_blue;
			}
		}
	}

	.text--note {
		font-size: 1.1rem;

		@media #{$md_devices} {
			font-size: 1.2rem;
		}
	}
}
.guide-box-content__img {
	width: 100%;
	margin: 3.2rem 0 0 0;
	text-align: center;

	@media #{$md_devices} {
		width: 36.5%;
		margin: 0 4rem 0 0;
	}
}

.guide-box-download {
	margin-top: 4.8rem;
}
.guide-box-download__item {
	text-align: left;

	@media #{$md_devices} {
		text-align: center;
	}

	& + & {
		margin-top: 2.4rem;
	}
}

// ----------------------------------------------------------------
//	Material
// ----------------------------------------------------------------

.material {}

.material-lineup-list {
	width: 100%;
	min-height: 33.3rem;
	display: flex;

	@media #{$md_devices} {
		min-height: auto;
		flex-direction: column;
	}
}
.material-lineup-list__item {
	display: none;

	@media #{$md_devices} {
		display: flex;
		justify-content: space-between;
	}

	&.material-lineup-list__item--free {

		@media #{$md_devices} {
			justify-content: inherit;
		}
	}

	&.material-lineup-list__item--sm {
		padding: 2.8rem 0 2.8rem .8rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;

		@media #{$md_devices} {
			display: none;
		}
	}
}
.material-lineup-list__bar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	z-index: 1;

	@media #{$sm_devices_only} {
		margin-left: -1.5rem;

		span {
			padding: .2rem .4rem;
			font-size: 1rem;
			font-weight: bold;
			color: $color_dark;
			line-height: 1.4;
			text-align: center;
			display: inline-block;
			border-radius: .9rem;
			background-color: rgba(255, 255, 255, .9);
			box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, .15);
		}

		&::after {
			@include ps_icon(0, 0, 0, 0);
			width: .8rem;
			height: 100%;
			background-image: linear-gradient(179deg, #5BAA53 0%, #098653 50%, #0E5022 100%);
			border-radius: .4rem;
			z-index: -1;
		}

	}

	@media #{$md_devices} {
		width: 100%;
		margin: 2.4rem auto;
		padding: .3rem 2.3rem;
		font-size: 1.2rem;
		font-weight: 600;
		color: #fff;
		line-height: 1.5;
		background-image: linear-gradient(90deg, #5BAA53 0%, #098653 50%, #0E5022 100%);
		flex-direction: row;
	}
}
.material-lineup-box {
	width: 100%;
	display: flex;
	flex-direction: row;

	@media #{$sm_devices_only} {

		& + & {
			margin-top: 2rem;
		}

		// setting
		&.regular-air-cell-pipe {
			margin-top: 6.4rem;
		}
		&.regular-bincho-pipe {
			margin-top: 6.4rem;
		}
		&.premium-mini-bincho {
			margin-top: auto;
			margin-bottom: 3.2rem;
		}
		&.prime-regular-elastomer {
			margin-top: 6.4rem;
		}
		&.prime-regular-mini-bincho {
			margin-top: 4.8rem;
			margin-bottom: 4.8rem;
		}
		&.prime-premium-andfree-hard {
			margin-top: 12.8rem;
		}
	}

	@media #{$md_devices} {
		width: 28.8rem;
		flex-direction: column;
		align-items: center;
		text-align: center;

		// setting
		&.premium-mini-bincho,
		&.prime-regular-mini-bincho, {
			margin-right: 16%;
		}
		&.prime-regular-buff {
			margin-left: 5.4rem;
		}
		&.prime-regular-angel {
			margin-left: .4rem;
			margin-right: auto;
		}
		&.prime-premium-n-down {
			margin-left: 3.4rem;
		}
		&.prime-premium-cotton {
			margin-left: .4%;
			margin-right: auto;
		}
		&.prime-premium-andfree-regular {
			margin-left: auto;
			margin-right: 38.8%;
		}
	}
}
.material-lineup-box--sm {

	@media #{$md_devices} {
		width: 18rem;
	}
}
.material-lineup-box--md {

	@media #{$md_devices} {
		width: 22rem;
	}
}

.material-lineup-box__thumb {
	margin: 0 1.6rem 0 0;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		margin: 0 0 .8rem 0;
	}
}
.material-lineup-box-img {
	width: 9.8rem;
	height: 0;
	padding-top: ( 118 / 160 * 100% );
	margin: 0 auto;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: 16rem;
	}

	img {
		@include ps_icon(-100%, -100%, -100%, -100%);
		content: none;
		width: auto;
		height: 100%;
		max-width: inherit;
		z-index: 1;
	}
}
.material-lineup-box-label {
	text-align: center;
	line-height: 1;
	display: inline-block;
	white-space: nowrap;
	position: absolute;
	top: -.9rem;
	left: 50%;
	right: auto;
	z-index: 2;
	transform: translateX(-50%);

	@media #{$md_devices} {
		top: -.7rem;
		left: 0;
		right: 0;
		transform: none;
	}

	span {
		padding: .3rem .8rem;
		font-size: 1rem;
		font-weight: bold;
		color: #fff ;
		line-height: 1.454;
		border-radius: 1.2rem;
		background-color: $color_btn_secondary;
		display: inline-block;

		@media #{$md_devices} {
			padding: .4rem .9rem .3rem;
			font-size: 1.1rem;
		}
	}
}
.material-lineup-box-label--pink {

	span {
		background-color: $color_pink;
	}
}
.material-lineup-box-label--orange {

	span {
		background-color: $color_orange;
	}
}
.material-lineup-list-box__description {
	max-width: 100%; // for IE11
	text-align: left;

	@media #{$md_devices} {
		text-align: center;
	}
}
.material-lineup-list-box-title {
	margin: 0 0 .5rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: $color_dark;
	letter-spacing: -.045rem;

	@media #{$md_devices} {
		margin: 0 0 .4rem 0;
		font-size: 1.4rem;
		letter-spacing: .04em;
	}
}
.material-lineup-list-box-text {
	margin: 0;
	font-size: 1rem;
	color: $color_dark;
	line-height: 1.67;

	@media #{$md_devices} {
		margin: 0 0 .4rem 0;
		padding: 0 1rem;
		font-size: 1.2rem;
	}
}

.material-quality {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		flex-wrap: wrap;
	}
}
.material-quality__item {
	width: 100%;

	@media #{$sm_devices_only} {

		& + & {
			margin-top: 4rem;
		}
	}

	@media #{$md_devices} {
		width: calc( ( 100% - 8.8rem ) / 3 );
		margin: 4.4rem 4.4rem 0 0;

		&:nth-child(3n) {
			margin-right: 0;
		}
		&:nth-child(-n+3) {
			margin-top: 0;
		}
	}
}
.material-quality-img {
	width: 100%;
	height: 0;
	padding-top: calc( 200 / 304 * 100% );
	border-radius: 1.2rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	img {
		@include ps_icon(-100%, -100%, -100%, -100%);
		content: none;
		width: auto;
		height: 100%;
		max-width: inherit;
		z-index: 1;
	}
}
.material-quality-text {
	margin: 1.6rem 0 0 0;
	font-size: 1.4rem;
	text-align: left;

	@media #{$md_devices} {
		font-size: 1.6rem;
	}
}

.material-quality-andfree {
	width: 100%;

	@media #{$md_devices} {
		max-width: 76rem;
		margin: 0 auto;
	}
}
.material-quality-andfree__item {
	width: 100%;

	& + & {
		margin-top: 4rem;
	}

	@media #{$md_devices} {
		display: flex;
		align-items: center;

		&.material-quality-andfree__item--reverse {
			flex-direction: row-reverse;
		}
	}
}
.material-quality-andfree-img {
	width: 100%;
	height: 0;
	padding-top: calc( 216 / 360 * 100% );
	border-radius: 1.2rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: 36rem;
		padding-top: calc( 216 / 760 * 100% );
		margin-right: 4rem;

		.material-quality-andfree__item--reverse & {
			margin-left: 4rem;
			margin-right: 0;
		}
	}

	img {
		@include ps_icon(-100%, -100%, -100%, -100%);
		content: none;
		width: auto;
		height: 100%;
		max-width: inherit;
		z-index: 1;
	}
}
.material-quality-andfree-text {
	margin-top: 1.6rem;
	font-size: 1.4rem;
	text-align: left;

	@media #{$md_devices} {
		margin: 0;
		font-size: 1.6rem;
		flex: 1;
	}
}



// ----------------------------------------------------------------
//	Flow
// ----------------------------------------------------------------

.flow {}

.flow-list {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		flex-wrap: wrap;
	}
}
.flow-list__item {
	width: 100%;
	text-align: left;
	position: relative;
	z-index: 1;

	@media #{$sm_devices_only} {

		&::after {
			@include ps_icon(auto, 0, -3.2rem, 0);
			width: 2.8rem;
			height: 1.6rem;
			background: url('../images/common/icon_flow_arrow_b.svg') 50% 50% no-repeat;
			background-size: contain;
			z-index: 2;
		}

		&:last-child {
			&::after {
				content: none;
			}
		}

		& + & {
			margin-top: 4.8rem;
		}
	}

	@media #{$md_devices} {
		width: calc( ( 100% - 8.8rem ) / 3 );
		margin: 4.8rem 4.4rem 0 0;

		&::after {
			@include ps_icon(8.6rem, -3rem, auto, auto);
			width: 1.6rem;
			height: 2.8rem;
			background: url('../images/common/icon_flow_arrow_r.svg') 50% 50% no-repeat;
			background-size: contain;
			z-index: 2;
		}

		&:nth-child(3n) {
			margin-right: 0;

			&::after {
				content: none;
			}
		}
		&:nth-child(-n+3) {
			margin-top: 0;
		}
	}
}
.flow-list-img {
	width: 100%;
	height: 0;
	margin-bottom: 1.2rem;
	padding-top: calc( 200 / 304 * 100% );
	border-radius: 1.2rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	&::before {
		@include ps_icon(0, auto, auto, 0);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6.4rem 6.4rem 0 0;
		border-color: $color_main transparent transparent transparent;
		z-index: 2;
	}

	img {
		@include ps_icon(-100%, -100%, -100%, -100%);
		content: none;
		width: auto;
		height: 100%;
		max-width: inherit;
		z-index: 1;
	}

	span {
		font-size: 3.4rem;
		font-family: $font_english;
		color: #fff;
		line-height: 1;
		position: absolute;
		top: .2rem;
		left: .6rem;
		z-index: 4;
	}

}
.flow-list-content {}
.flow-list-title {
	margin-bottom: 1.2rem;
	font-size: 1.8rem;
	font-weight: 600;
	color: $color_dark;
	text-align: center;
}
.flow-list-text {
	font-size: 1.4rem;
}

.flow-conversion-box {
	width: calc( 100% + 6.4rem );
	margin-left: -3.2rem;
	padding: 4rem 3.2rem;
	background-color: rgba(255, 255, 255, .8);

	@media #{$md_devices} {
		width: 100%;
		padding: 5.6rem 2.4rem;
		border-radius: 1.2rem;
	}
}
.flow-conversion-box__text {}

.flow-counseling {
	width: 100%;
	display: flex;
	flex-direction: column;

	@media #{$md_devices} {
		flex-direction: row;
		justify-content: space-between;
	}
}
.flow-counseling__img {
	width: 100%;
	margin: 0 0 3.2rem;

	@media #{$md_devices} {
		max-width: 48.1%;
		margin: 0 0 0 -8%;
	}
}
.flow-counseling__item {
	width: 100%;

	@media #{$md_devices} {
		width: 58%;
		text-align: left;
	}
}

.flow-balloon {
	width: 100%;
	margin: 3.2rem 0 0 0;
	display: flex;
	flex-wrap: wrap;

	@media #{$md_devices} {
		width: 100%;
		margin: 6.4rem 0 0 0;
		padding-bottom: 3.2rem;
		justify-content: center;
	}

	.balloon {
		margin: 0 1.1rem 2.4rem 0;
		padding: 1.2rem 1.5rem;
		font-size: 1.2rem;
		text-align: left;

		@media #{$md_devices} {
			margin: 4.8rem 4rem 0 0;
			padding: 1.2rem 3.2rem;
			font-size: 1.6rem;
		}

		&:last-child {
			margin-right: 0;
		}

		@media #{$sm_devices_only} {

			&:nth-child(2n) {
				margin-right: 0;
			}

			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}

		@media #{$md_devices} {

			&:nth-child(-n+3) {
				margin-top: 0;
			}
		}
	}
}


// ----------------------------------------------------------------
//	Maintenance
// ----------------------------------------------------------------

.maintenance {

	.page-container {
		text-align: left;
	}
}

.maintenance-table {
	width: 100%;

	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		border: .1rem solid #CECECE;
		border-radius: .8rem;
		table-layout: fixed;

		@media #{$md_devices} {
			table-layout: inherit;
		}

		th {
			padding: 1.2rem 1rem;
			font-size: 1.2rem;
			font-weight: 600;
			color: #fff;
			text-align: center;
			background-color: $color_main;
			border-bottom: .1rem solid #CECECE;

			@media #{$md_devices} {
				padding: .9rem 1rem;
				font-size: 1.4rem;
			}

			& + th {
				border-left: .1rem solid #CECECE;
			}
		}
		td {
			padding: 1.2rem 1.6rem;
			font-size: 1.2rem;
			line-height: 1.5;
			border-bottom: .1rem solid #CECECE;

			@media #{$md_devices} {
				padding: .9rem 2.4rem;
				font-size: 1.4rem;
			}

			&:first-child {
				font-weight: 600;
			}

			& + td {
				border-left: .1rem solid #CECECE;
			}
		}
		tr:last-child td {
			border-bottom: none;
		}

		tr:first-child th:first-child {
			border-radius: .7rem 0 0 0;
		}
		tr:first-child th:last-child {
			border-radius: 0 .7rem 0 0;
		}
		tr:last-child td:first-child {
			border-radius: 0 0 .7rem 0;
		}
		tr:last-child th:last-child {
			border-radius: 0 0 0 .7rem;
		}
	}
}

.maintenance-box {

	.box {
		padding: 2rem;
		font-size: 1.3rem;
		color: $color_dark;

		@media #{$md_devices} {
			padding: 2rem 3.2rem;
			font-size: 1.4rem;
		}

		.box-title {
			margin-bottom: .8rem;
			text-align: left;
		}
	}
}


// ----------------------------------------------------------------
//	Faq
// ----------------------------------------------------------------

.faq {

	.page-container {
		text-align: left;
	}
}

.faq-list {}
.faq-list__item {
	padding-bottom: 4.8rem;
	border-bottom: .1rem solid $color_border;

	@media #{$md_devices} {
		padding-bottom: 4rem;
	}

	& + & {
		margin-top: 4rem;

		@media #{$md_devices} {
			margin-top: 4.8rem;
		}
	}
}
.faq-list-title {
	padding-left: 4.6rem;
	font-size: 1.9rem;
	font-weight: 600;
	color: $color_main;
	line-height: 1.5;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		padding-left: 6.4rem;
		font-size: 2.8rem;
	}

	&::before {
		@include ps_icon(0, auto, auto, 0);
		content: 'Q';
		width: 2.8rem;
		height: 2.8rem;
		padding-top: .1rem;
		font-size: 1.6rem;
		font-weight: 600;
		color: $color_main;
		line-height: 1.333;
		text-align: center;
		border: .2rem solid $color_main;
		border-radius: 100%;
		z-index: 2;

		@media #{$md_devices} {
			width: 4rem;
			height: 4rem;
			padding-top: .3rem;
			font-size: 2.4rem;
		}
	}
}
.faq-list-content {
	margin-top: 2.4rem;
	padding-left: 4.6rem;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		margin-top: 2.9rem;
		padding-left: 6.4rem;
	}

	&::before {
		@include ps_icon(-.5rem, auto, auto, 0);
		content: 'A';
		width: 2.8rem;
		height: 2.8rem;
		padding-top: .1rem;
		font-size: 1.6rem;
		font-weight: 600;
		color: $color_alert;
		line-height: 1.333;
		text-align: center;
		border: .2rem solid $color_alert;
		border-radius: 100%;
		z-index: 2;

		@media #{$md_devices} {
			width: 4rem;
			height: 4rem;
			padding-top: .3rem;
			font-size: 2.4rem;
		}
	}
}