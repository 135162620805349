@charset "UTF-8";
// ================================================================================
// Headline
// 見出しのスタイル
// ================================================================================

// ----------------------------------------------------------------
// 固定ページの見出し
// ----------------------------------------------------------------

.headline-01 {
	margin: 0;
	font-size: 2.1rem;
	font-weight: 600;
	font-family: $font_title;
	color: $color_dark;
	line-height: 1.764;
	letter-spacing: .04rem;

	@media #{$md_devices} {
		font-size: 3.4rem;
		letter-spacing: -.064rem;
	}

	& ~ & {
		margin-top: 3.2rem;

		@media #{$md_devices} {
			margin-top: 4.8rem;
		}
	}

	span {
		margin-bottom: .4rem;
		font-size: 1.6rem;
		color: $color_main;
		line-height: 1.5;
		display: block;

		@media #{$md_devices} {
			margin-bottom: .8rem;
			font-size: 2rem;
		}
	}
}
.headline-02 {
	margin: 0;
	font-size: 2.8rem;
	font-weight: 600;
	font-family: $font_title;
	color: $color_main;
	line-height: 1.523;
	letter-spacing: -.075rem;

	@media #{$md_devices} {
		font-size: 4.2rem;
	}

	span {

	}
}
.headline-03 {
	margin: 0;
	font-size: 1.9rem;
	font-weight: 600;
	font-family: $font_title;
	color: $color_main;
	line-height: 1.523;
	letter-spacing: -.084rem;

	@media #{$md_devices} {
		font-size: 2.8rem;
	}
}
.headline-04 {
	margin: 0;
	font-size: 1.6rem;
	font-weight: 600;
	color: $color_main;
	line-height: 1.523;
	letter-spacing: -.084rem;
	text-align: left;
	position: relative;
	padding: 0 0 .8rem;
	margin: 0 auto 1.2rem;
	border-bottom: $color_border solid 1px;

	@media #{$md_devices} {
		font-size: 2rem;
		margin: 0 auto 1.6rem;
	}

//	&::before {
//		@include ps_icon(auto, 0, 0, 0);
//		width: 100%;
//		height: .1rem;
//		background:linear-gradient(to right, rgba($color_main, 1), rgba($color_main, 1) 66%, rgba($color_main, 0) 66%, rgba($color_main, 0) 100%) 0% 0%;
//  		background-size: .6rem .1rem;
//		z-index: 1;
//
//		@media #{$md_devices} {
//			font-size: 2.8rem;
//		}
//	}
}

// color setting
.headline--gray {
	color: $color_moss_gray;
}
.headline--green {
	color: $color_main;
}



// ----------------------------------------------------------------
// 装飾系の見出し
// ----------------------------------------------------------------

.headline-unique-01 {
	width: 100%;
	font-family: $font_title;
	text-align: center;
	margin-bottom: 2.8rem;

	@media #{$md_devices} {
		margin-bottom: 4rem;
	}

	& ~ & {
		margin-top: 5.6rem;

		@media #{$md_devices} {
			margin-top: 9.6rem;
		}
	}
}
.headline-unique-01__sub {
	margin-bottom: .4rem;
	text-transform: uppercase;

	@media #{$md_devices} {
		margin-bottom: .3rem;
	}

	span {
		font-size: 1.4rem;
		font-weight: 600;
		color: $color_alert;
		line-height: 1;
		letter-spacing: .32rem;
		display: inline-block;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			font-size: 2rem;
		}

		&::before,
		&::after {
			@include ps_icon(0, 0, 0, 0);
			width: 4rem;
			height: .1rem;
			background-color: $color_alert;
			position: absolute;
			z-index: 1;
		}
		&::before {
			left: -5rem;
			right: auto;
		}
		&::after {
			left: auto;
			right: -5rem;
		}
	}
}
.headline-unique-01__main {
	margin: 0;
	font-size: 2.1rem;
	font-weight: 600;
	color: $color_dark;
	line-height: 1.52;

	@media #{$md_devices} {
		font-size: 3.4rem;
		line-height: 1.76;
	}
}
.headline-unique-01__label {
	margin: .8rem 0 0 0;
	padding: .3rem 2.4rem;
	font-size: 1.2rem;
	font-weight: 600;
	color: #fff;
	line-height: 1.714;
	text-align: center;
	background-color: $color_btn_primary;
	border-radius: 2rem;
	display: inline-block;

	@media #{$md_devices} {
		padding: .4rem 2.8rem;
		font-size: 1.4rem;
	}
}

.headline-unique-02 {
	margin: 0;
	font-size: 1.9rem;
	font-weight: 600;
	font-family: $font_title;
	color: $color_main;
	text-align: center;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		font-size: 2.8rem;
	}

	&::before {
		@include ps_icon(auto, 0, -1.2rem, 0);
		width: 100%;
		height: .1rem;
		background:linear-gradient(to right, rgba($color_main, 1), rgba($color_main, 1) 66%, rgba($color_main, 0) 66%, rgba($color_main, 0) 100%) 0% 0%;
  		background-size: .6rem .1rem;
		z-index: 1;

		@media #{$md_devices} {
			font-size: 2.8rem;
			top: 0;
			bottom: 0;
		}
	}

	span {
		letter-spacing: 0;

		@media #{$md_devices} {
			padding: 0 2.4rem;
			background-color: #fff;
			letter-spacing: inherit;
			display: inline-block;
			position: relative;
			z-index: 2;
		}
	}
}

.headline-unique-03 {
	width: calc( 100% - 6.4rem );
	margin: 0 auto;
	font-family: $font_title;
	text-align: center;
	margin-bottom: 2.8rem;

	@media #{$md_devices} {
		width: calc( 100% - 16rem );
		max-width: 100rem;
		margin-bottom: 6rem;
	}
}
.headline-unique-03__sub {
	margin-bottom: 1.2rem;

	span {
		padding: .4rem 3rem;
		font-size: 1.6rem;
		font-weight: 600;
		color: #fff;
		text-align: center;
		line-height: 1.75;
		display: inline-block;
		background: #A99560;
		border-radius: 4px;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			padding: .6rem 4.3rem;
		}

		&::after {
			@include ps_icon(auto, 0, -.8rem, 0);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 1.6rem 1.2rem 0 1.2rem;
			border-color: #A99560 transparent transparent transparent;

			@media #{$md_devices} {
				bottom: -1.2rem;
			}
		}

	}
}
.headline-unique-03__main {
	margin: 0;
	font-size: 2.8rem;
	font-weight: 600;
	color: $color_dark;
	line-height: 1.52;

	@media #{$md_devices} {
		font-size: 4.2rem;
		line-height: 1.523;
	}
}
.headline-unique-03__note {
	margin: 2.4rem 0 0 0;
	font-size: 1.4rem;
	font-family: $font;
	color: $color_dark;
	text-align: left;

	@media #{$md_devices} {
		text-align: center;
	}
}