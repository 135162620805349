@charset "UTF-8";
// ================================================================================
// Separator
// 区切りブロックのスタイル
// ================================================================================

.wp-block-separator {
	&:last-child {
		margin-bottom: 0;
	}
}


