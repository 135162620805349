@charset "UTF-8";
// ================================================================================
// Box
// ボックスのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Box
// ----------------------------------------------------------------

.box {
	width: 100%;
	padding: 1.6rem 2.4rem 2.4rem;
	background: #fff;

	@media #{$md_devices} {
		padding: 1.6rem 3.2rem 2.4rem;
	}

	& + & {
		margin-top: 1.6rem;

		@media #{$md_devices} {
			margin-top: 2.4rem;
		}
	}
}
.box--lg {
	padding: 3.2rem 2.6rem;

	@media #{$md_devices} {
		padding: 4.6rem 3.8rem;
	}

	.headline-03 {
		margin-bottom: 2.3rem;

		@media #{$md_devices} {
			margin-bottom: 3.2rem;
		}
	}
}

.box--border {
	border: .2rem solid $color_border;
}
.box--radius {
	border-radius: 1.2rem;
}


// Title
// --------------------------------------------------------

.box-title {
	font-size: 1.6rem;
	font-family: $font_title;
	font-weight: bold;
	color: $color_main;
	text-align: center;
	line-height: 1.555;

	@media #{$md_devices} {
		font-size: 1.8rem;
	}
}
.box-title--border {
	padding-bottom: 1.5rem;
	border-bottom: .1rem dashed rgba(#aaa, .7);
}


// ----------------------------------------------------------------
// Cat Box
// ----------------------------------------------------------------

.cat-box {
	width: 100%;
	margin-top: 4.8rem;
	padding: 1.6rem 1.6rem 0;
	border-radius: 1.2rem;
	background-color: $color_bg_gray;

	@media #{$md_devices} {
		margin-top: 0;
		padding: 2.4rem;
	}
}
.cat-box-title {
	margin-bottom: .8rem;
	font-size: 1.2rem;
	color: $color_main;
	letter-spacing: .06rem;
	line-height: 1.5;

	@media #{$md_devices} {
		margin-bottom: 1.6rem;
		font-size: 2rem;
	}
}
.cat-list {
	@include reset;
	list-style: none;

	@media #{$sm_devices_only} {
		@include ib(left);
	}
}
.cat-list__item {
	@include reset;

	@media #{$sm_devices_only} {
		@include ib_child;
		margin: 0 1.6rem 1.6rem 0;
	}

	& + & {

		@media #{$md_devices} {
			margin: .8rem 0 0 0;
		}
	}

	a {
		font-size: 1.3rem;
		color: $color_default;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			padding-left: 2rem;
			font-size: 1.4rem;
			font-weight: 600;
			text-decoration: none;
			display: block;
		}

		&::before {

			@media #{$md_devices} {
				@include ps_icon(0, auto, 0, 0);
				width: 1.2rem;
				height: .2rem;
				background-color: $color_main;
				z-index: 1;
			}
		}
	}
}