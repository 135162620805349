@charset "UTF-8";
// ================================================================================
// Post Header
// 投稿ヘッダーのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Post Header
// ----------------------------------------------------------------

.post-header {
	margin-bottom: 2.4rem;
	padding-bottom: 2.4rem;
	border-bottom: .1rem solid $color_border;
}
.post-header__date {
	margin-bottom: .8rem;
	font-size: 1.6rem;
	font-weight: 600;
	color: $color_main;
	letter-spacing: .2rem;
	line-height: 1.67;

	@media #{$md_devices} {
		margin-bottom: 1.6rem;
		font-size: 1.8rem;
	}
}
.post-header__title {
	font-size: 2.1rem;
	font-family: $font_title;
	font-weight: 600;
	color: $color_dark;
	letter-spacing: .064rem;
	line-height: 1.714;

	@media #{$md_devices} {
		font-size: 3.4rem;
		line-height: 1.764;
	}
}