@charset "UTF-8";
// ================================================================================
// Page Header
// ページヘッダーのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Page Header
// ----------------------------------------------------------------

.page-header {
	@extend .inner;
	margin-bottom: 1.6rem;
	padding: 1.9rem 0;

	@media #{$md_devices} {
		margin-bottom: 0;
		padding: 3.2rem 0 6.4rem;
	}
}
.page-title {
	margin: 0;
	font-family: $font_title;
	text-align: center;
	line-height: 1;
}
.page-title__sub {
	margin-bottom: .4rem;
	display: block;
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: .192rem;
	color: $color_main;
	text-transform: uppercase;

	@media #{$md_devices} {
		margin-bottom: .8rem;
		font-size: 2rem;
		letter-spacing: .32rem;
	}
}
.page-title__main {
	font-size: 2.6rem;
	color: $color_default;
	line-height: 1.417;

	@media #{$md_devices} {
		font-size: 4.8rem;
	}

	span {
		margin-top: .8rem;
		font-size: 1.8rem;
		display: block;

		@media #{$md_devices} {
			margin-top: 0;
			font-size: 3.2rem;
		}
	}
}
.page-header__meta {

	.breadcrumb {
		margin-top: 1.2rem;

		@media #{$md_devices} {
			margin-top: 2.4rem;
		}
	}
	.breadcrumb-list {
		justify-content: center;
	}
}


// ----------------------------------------------------------------
// Page Rich Header
// ----------------------------------------------------------------

.page-rich-header {
	width: 100%;
}
.page-rich-header__img {
	height: 17.6rem;
	border-right: 3.2rem solid #fff;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		height: 54.4rem;
		border-top: 4rem solid #fff;
		border-right: 6.4rem solid #fff;
	}
}
.page-rich-eyecatch {
	width: 100%;
	height: 100%;
	border-radius: 0 3.2rem 3.2rem 0;
	position: relative;
	z-index: 1;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		@media #{$md_devices} {
			@include ps_icon(50%, auto, auto, 50%);
			content: none;
			width: auto;
			height: auto;
			min-width: 100%;
			min-height: 100%;
			max-width: inherit;
			transform: translate(-50%, -50%);
		}
	}
}
.page-rich-header__meta {
	width: 100%;
	padding: 1.6rem 3.2rem 0;

	@media #{$md_devices} {
		max-width: 80rem;
		margin-top: -16.8rem;
		padding: 4.8rem 7.2rem 0 8rem;
		background-color: #fff;
		border-radius: 0 3.2rem 0 0;
		position: relative;
		z-index: 2;
	}

	&::after {

		@media #{$md_devices} {
			@include ps_icon(13.6rem, -3.2rem, auto, auto);
			width: 3.2rem;
			height: 3.2rem;
			background: url('../images/common/bg_page_header_curve.svg') 50% 50% no-repeat;
			background-size: contain;
			z-index: 1;
		}
	}
}
.page-rich-title {
	margin: 0;
	font-family: $font_title;
	line-height: 1.47;

	@media #{$md_devices} {
		line-height: 1.619;
	}
}
.page-rich-title__sub {
	display: block;
	font-size: 1.4rem;
	line-height: 1.5;
	letter-spacing: .192rem;
	color: $color_main;
	text-transform: uppercase;

	@media #{$md_devices} {
		font-size: 2rem;
		letter-spacing: .32rem;
	}
}
.page-rich-title__main {
	font-size: 2.3rem;
	color: $color_dark;
	letter-spacing: .075rem;
	line-height: 1.47;

	@media #{$md_devices} {
		font-size: 4.2rem;
		line-height: 1.619;
	}

	span {
		font-size: .83em;

		@media #{$sm_devices_only} {
			display: block;
			letter-spacing: normal;
		}

		@media #{$md_devices} {
			font-size: .76em;
		}
	}
}