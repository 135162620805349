@charset "UTF-8";
// ================================================================================
// Page Mattress
// マットレス詳細ページのスタイル
// ================================================================================

.mattress {

	.breadcrumb {
		margin: 1.2rem 0;
		padding: 0 0 0 2rem;

		@media #{$md_devices} {
			margin: 1.6rem 0;
			padding: 0 2.4rem;
		}
	}
}

// ----------------------------------------------------------------
//	Header
// ----------------------------------------------------------------

.mattress-header {
	width: 100%;
	height: auto;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		height: 1px;
		min-height: 62rem;

		// setting
		.andfree & {
			min-height: 58.8rem;
		}
	}

	&::before,
	&::after {
		@include ps_icon();
		width: 28.8rem;
		height: 4.8rem;

		@media #{$md_devices} {
			width: 57.7rem;
			height: 9.6rem;
		}
	}

	&::before {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		right: auto;
		bottom: auto;

		// setting
		.airsx-3fold & {
			background-image: url('../images/mattress/airsx-3fold/bg_main_airsx-3fold_top.png');
		}
		.air01se & {
			background-image: url('../images/mattress/air01se/bg_main_air01se_top.png');
		}
		.airsi & {
			background-image: url('../images/mattress/airsi/bg_main_airsi_top.png');
		}
		.airsx & {
			background-image: url('../images/mattress/airsx/bg_main_airsx_top.png');
		}
		.air03 & {
			background-image: url('../images/mattress/air03/bg_main_air03_top.png');
		}
		.airportable & {
			background-image: url('../images/mattress/airportable/bg_main_airportable_top.png');
		}

	}
	&::after {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		top: auto;
		left: auto;

		// setting
		.airsx-3fold & {
			background-image: url('../images/mattress/airsx-3fold/bg_main_airsx-3fold_bottom.png');
		}
		.air01se & {
			background-image: url('../images/mattress/air01se/bg_main_air01se_bottom.png');
		}
		.airsi & {
			background-image: url('../images/mattress/airsi/bg_main_airsi_bottom.png');
		}
		.airsx & {
			background-image: url('../images/mattress/airsx/bg_main_airsx_bottom.png');
		}
		.air03 & {
			background-image: url('../images/mattress/air03/bg_main_air03_bottom.png');
		}
		.airportable & {
			background-image: url('../images/mattress/airportable/bg_main_airportable_bottom.png');
		}
	}
}
.mattress-header-bg {
	width: 100%;
	height: 0;
	padding-top: calc( 216 / 375 * 100% );
	margin: 0 auto;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: calc( 664 / 1600 * 100% );
		height: 100%;
		margin: 0 0 0 auto;
		padding-top: 0;
	}

	// setting
	.andfree & {
		background: url('../images/mattress/andfree/img_andfree_main.png') 50% 50% no-repeat;
		background-size: cover;
	}
}
.mattress-header-content {
	height: auto;
	padding: 5.6rem 0;

	@media #{$md_devices} {
		height: 100%;
		padding: 0;
	}

	// setting
	.andfree & {
		width: calc( 100% - 6.4rem);
		margin: 0 auto;
		padding: 3.2rem 0 4rem;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			width: calc( 100% - 16rem );
			max-width: 100rem;
			padding: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%,-50%);
		}
	}
}
.mattress-header-content__detail {
	width: 100%;

	@media #{$md_devices} {
		max-width: 62.4rem;
		position: relative;
		top: 50%;
		z-index: 3;
		transform: translateY(-50%);
	}

	// setting
	.andfree & {
		width: 100%;
		height: auto;

		@media #{$md_devices} {
			width: 63.8rem;
			height: 100%;
			padding-right: 2.4rem;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: static;
			transform: none;
		}
	}

	&.mattress-header-content__detail--no-image {

		@media #{$md_devices} {
			max-width: inherit;
			text-align: center;
		}
	}
}
.mattress-header-content__img {
	display: none;

	@media #{$md_devices} {
		width: 62%;
		display: block;
		position: absolute;
		top: 50%;
		right: -10%;
		z-index: 2;
		transform: translateY(-50%);

		// setting
		.airsi & {
			width: 53.3%;
			right: -12%;
		}
		.airsx & {
			width: 52%;
		}
		.air03 & {
			width: 57.1%;
			right: -6%;
		}
		.airportable & {
			width: 37%;
			right: -4.2%;
		}
	}
}
.mattress-logo-sub {
	width: 4.4rem;
	margin-bottom: .8rem;

	@media #{$md_devices} {
		width: 6.6rem;
		margin-bottom: 1.6rem;
	}

	.mattress-header-content__detail--no-image & {

		@media #{$md_devices} {
			margin: 0 auto 1.6rem;
		}
	}

	&.is-free {
		width: 6.4rem;
		@media #{$md_devices} {
			width: 10rem;
		}
	}
}
.mattress-logo-main {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	img {
		height: 2.9rem;
		margin: 0 1.2rem 0 0;

		@media #{$md_devices} {
			height: 4.2rem;
			margin: 0 1.2rem 0 0;
		}
	}

	.mattress-header-content__detail--no-image & {

		@media #{$md_devices} {
			justify-content: center;
		}
	}
}
.mattress-title {
	margin: 0;
	font-size: 1.3rem;
	font-family: $font_title;
	font-weight: 600;
	color: $color_dark;
	text-align: left;

	@media #{$md_devices} {
		font-size: 2rem;
		font-family: $font_title;
	}
}
.mattress-description {
	margin-top: 2rem;

	@media #{$md_devices} {
		margin-top: 3.2rem;
	}
}
.mattress-description__title {
	margin-bottom: 2rem;
	font-size: 2.3rem;
	font-family: $font_title;
	font-weight: 600;
	line-height: 1.52;

	@media #{$md_devices} {
		margin-bottom: 0;
		font-size: 4.2rem;
	}
}
.mattress-description__img {
	width: calc( 100% - 4rem );
	margin: 0 auto;

	@media #{$md_devices} {
		display: none;
	}
}
.mattress-description__text {
	width: 100%;
	margin-top: 2rem;

	@media #{$md_devices} {
		max-width: 46.8rem;
		margin-top: 3.2rem;
	}

	.mattress-header-content__detail--no-image & {

		@media #{$md_devices} {
			max-width: inherit;
		}
	}
}
.mattress-description-point {
	@include reset;
	list-style: none;
	display: flex;
}
.mattress-description-point__item {
	width: 8.4rem;
	height: 8.4rem;
	margin: 0;
	font-size: 1.4rem;
	font-weight: bold;
	color: $color_alert;
	text-align: center;
	line-height: 1.5;
	border: .2rem solid $color_alert;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media #{$md_devices} {
		width: 9.6rem;
		height: 9.6rem;
		font-size: 1.6rem;
	}

	& + & {
		margin-left: 2.4rem;
	}
}
.mattress-description__badge {
	margin-top: 2.4rem;

	@media #{$md_devices} {
		@include ps_icon(auto, auto, 2.4rem, 66.2rem);
		width: 18.6rem;
		height: 10.1rem;
		z-index: 2;
	}
}


// ----------------------------------------------------------------
//	Content
// ----------------------------------------------------------------

.mattress-content {
	width: 100%;
	padding: 5.6rem 0;

	@media #{$md_devices} {
		padding: 8.8rem 0 9.6rem;
	}
}
.mattress-content--gray {
	background-color: #F4F7F8;
}
.mattress-content--bottom {
	width: 100%;
	padding: 0;

	.mattress-content--gray + & {
		padding: 5.6rem 0 0;

		@media #{$md_devices} {
			padding: 8.8rem 0 0;
		}
	}
}


// Feature
// --------------------------------------------------------

.mattress-feature-box {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	@media #{$md_devices} {
		height: 100%;
		margin-top: 6rem;
		flex-direction: row;
	}

	& + & {
		margin-top: 4rem;

		@media #{$md_devices} {
			margin-top: 8rem;
		}
	}

	// 画像なし ver
	&.is-single {
		.mattress-feature-box__body {
			@media #{$md_devices} {
				width: 100%;
			}
			&:after {
				display: none;
			}
		}
	}
}
.mattress-feature-box__body {
	width: 100%;
	padding: 3.6rem 2.4rem 2.4rem;
	background-color: #fff;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		width: 56%;
		padding: 4.8rem 3.2rem 4rem 4rem;
	}

	&::after {
		@include ps_icon(auto, 0, -6.4rem, 0);
		width: 100%;
		height: 6.4rem;
		background-color: #fff;
		z-index: -1;

		@media #{$md_devices} {
			width: 2.6rem;
			height: 100%;
			background-color: #fff;
			top: 0;
			left: auto;
			right: -2.6rem;
			bottom: 0;
			z-index: -1;
		}
	}
}

.mattress-feature-label {
	padding: .8rem 1.2rem;
	font-size: 1.6rem;
	font-family: $font_title;
	font-weight: bold;
	color: #fff;
	line-height: 1.5;
	background-color: $color_alert;
	display: inline-block;
	border-radius: .2rem;
	position: absolute;
	top: 1.2rem;
	left: -.8rem;
	z-index: 2;

	@media #{$md_devices} {
		padding: 1.2rem 2rem;
		font-size: 2.1rem;
		line-height: 1.333;
		top: 2.4rem;
		left: -.4rem;
	}
}
.mattress-feature-title {
	margin: 0;
	font-size: 1.9rem;
	font-family: $font_title;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: .064rem;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 2.8rem;
	}

	// .mattress-feature-label + & {
	// 	padding-top: 3.6rem;

	// 	@media #{$md_devices} {
	// 		padding-top: 4.8rem;
	// 	}
	// }
 }
.mattress-feature-title-sub {
	font-size: 1.3rem;
	font-family: $font_title;
	font-weight: 600;
	display: block;

	@media #{$md_devices} {
		font-size: 1.6rem;
	}
}
.mattress-feature-text {
	margin: 0;
	font-size: 1.3rem;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 1.6rem;
	}

	.mattress-feature-title + & {
		margin-top: 2rem;
	}

	.mattress-feature-label + & {
		padding-top: 3.6rem;

		@media #{$md_devices} {
			padding-top: 4.8rem;
		}
	}
}
.mattress-feature-notes {
	font-size: 1.1rem;
	color: $color_default;

	@media #{$md_devices} {
		font-size: 1.3rem;
	}
}
.mattress-feature-box__img {
	@include reset;
	margin-top: 0;
	padding: 0 2.4rem;
	position: relative;
	z-index: 2;
	flex: 1;

	@media #{$md_devices} {
		margin-top: -1.6rem;
		padding: 0;
	}

	figcaption {
		margin-top: .8rem;
		font-size: 1.1rem;
		line-height: 1.636;

		@media #{$md_devices} {
			margin-top: 1.2rem;
			font-size: 1.3rem;
			text-align: center;
		}
	}
}

// single box
.mattress-feature-box-single {
	width: 100%;
	padding: 2.4rem;
	background-color: #fff;

	@media #{$md_devices} {
		padding: 4.8rem;
	}

	& + .mattress-feature-box {
		margin-top: 4rem;

		@media #{$md_devices} {
			margin-top: 8rem;
		}
	}

	& + & {
		margin-top: 4.8rem;
	}
}

.mattress-feature-box-single-title {
	margin-bottom: 2rem;
	font-size: 1.9rem;
	font-weight: 600;
	text-align: center;

	@media #{$md_devices} {
		margin-bottom: 2.4rem;
		font-size: 2.8rem;
	}
}
.mattress-feature-img--full {
	width: 100%;
	max-width: 60rem;
	height: auto;
	margin: 0 auto;
}
.mattress-feature-img--full__caption {
	margin-top: 1.6rem;
	font-size: 1.3rem;
	color: $color_dark;
	text-align: left;

	@media #{$md_devices} {
		font-size: 1.4rem;
		text-align: center;
	}
}

.mattress-feature-box-single__head {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		align-items: center;
	}
}
.mattress-feature-single-img {
	width: 100%;
	margin: 0 0 2rem 0;

	@media #{$md_devices} {
		width: 44rem;
		margin: 0 4rem 0 0;
	}
}
.mattress-feature-box-single-img--full {
	width: 100%;
}
.mattress-feature-single-description {

	@media #{$md_devices} {
		flex: 1;
	}
}
.mattress-feature-box-single__body {
	margin-top: 4rem;

	@media #{$md_devices} {
		margin-top: 4.8rem;
		display: flex;
	}
}
.mattress-feature-box-single__body--sm {
	margin-top: 3.2rem;
	display: block;

	@media #{$md_devices} {
		margin-top: 6.4rem;
		padding: 0 7.2rem;
	}
}
.mattress-feature-box-single-fig {
	@include reset;

	@media #{$md_devices} {
		width: calc( ( 100% - 4rem ) / 2 );

		// setting
		.airsx-3fold & {
			width: calc( 400 / 920 * 100% );

			&:last-child {
				width: calc( 480 / 920 * 100% );
			}
		}
	}

	figcaption {
		margin-top: .8rem;
		font-size: 1.3rem;
		line-height: 1.636;

		@media #{$md_devices} {
			margin-top: 1.6rem;
			font-size: 1.4rem;
		}
	}

	& + & {
		margin: 2rem 0 0 0;

		@media #{$md_devices} {
			margin: 0 0 0 4rem;
		}
	}
}
.mattress-feature-box-comparison-wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.mattress-feature-box-comparison {
	@include reset;
	width: calc( 338 / 760 * 100% );
}
.mattress-feature-box-comparison__head {
	font-size: 1.2rem;
	font-weight: 600;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 2rem;
	}
}
.mattress-feature-box-comparison__item {
	@include reset;
	margin-top: .5rem;
	font-size: .9rem;

	@media #{$md_devices} {
		margin-top: 1.2rem;
		font-size: 1.5rem;
	}
}
.mattress-feature-box-comparison-img {
	margin-top: 1.6rem;
}

.mattress-feature-box-structure {
	margin: 4rem auto 0;
	@media #{$md_devices} {
		margin-top: 6.4rem;
		display: flex;
		align-items: center;
	}
	.mattress-feature-box-structure__img {
		width: 20rem;
		margin: 0 auto 1.6rem;
		text-align: center;
		@media #{$md_devices} {
			margin-bottom: 0;
			width: 33.6rem;
			margin-right: 4rem;
		}
	}
	.mattress-feature-box-structure__body {
		@media #{$md_devices} {
			flex: 1;
		}
	}
}

.mattress-feature-box-single--limited-edition {
	width: 100%;
	padding: 3.2rem 2.4rem;
	color: #fff;
	background-color: #000;

	@media #{$md_devices} {
		padding: 3.2rem 4.8rem 4rem;
	}
}
.mattress-limited-edition-title {
	width: 20rem;
	margin: 0 auto 2.4rem;

	@media #{$md_devices} {
		margin: 0 0 2.4rem 0;
	}
}
.mattress-limited-edition-content {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		justify-content: space-between;
	}
}
.mattress-limited-edition-content__item {
	width: 100%;

	@media #{$md_devices} {
		width: calc( ( 100% - 4.8rem ) / 2 );
	}

	& + & {

		@media #{$sm_devices_only} {
			margin-top: 3.2rem;
		}
	}
}
.mattress-limited-edition-content-title {
	margin: 0 0 1.6rem 0;
	font-size: 1.6rem;
	font-weight: 600;
	color: #D7053D;
	text-align: center;

	@media #{$md_devices} {
		margin: 0 0 1.2rem 0;
		text-align: left;
	}
}
.mattress-limited-edition-box {

	@media #{$md_devices} {
		display: flex;
		align-items: flex-start;
	}
}
.mattress-limited-edition-box__img {
	width: 100%;
	border: .1rem solid #D3D3D4;

	@media #{$md_devices} {
		max-width: 20rem;
	}
}
.mattress-limited-edition-box__text {
	margin: 1.6rem 0 0 0;
	font-size: 1.3rem;

	@media #{$md_devices} {
		margin: 0 0 0 2rem;
		font-size: 1.4rem;
		flex: 1;
	}
}

.mattress-feature-gallery {
	padding-top: 4rem;
	border-top: .1rem solid $color_border;

	@media #{$md_devices} {
		padding-top: 4.8rem;
	}
}
.mattress-feature-gallery-title {
	margin: 0 0 2rem 0;
	font-size: 1.9rem;
	font-weight: 600;
	color: #005BAC;

	@media #{$md_devices} {
		margin: 0 0 2.4rem 0;
		font-size: 2.8rem;
	}

	span {
		margin-top: .4rem;
		font-size: 1.6rem;
		line-height: 1.68;
		display: block;

		@media #{$md_devices} {
			margin-left: .8rem;
			font-size: 2.1rem;
			display: inherit;
		}
	}

	& ~ & {
		margin-top: 4rem;

		@media #{$md_devices} {
			margin-top: 4.8rem;
		}
	}
}
.mattress-feature-gallery-box {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
	}
}
.mattress-feature-gallery-box__item {
	width: 100%;

	@media #{$md_devices} {
		width: 21.8rem;

		&:nth-child(2) {
			width: 44.8rem;
			margin: 0 1.2rem;
		}
	}

	& + & {

		@media #{$sm_devices_only} {
			margin-top: .8rem;
		}
	}
}
.mattress-feature-gallery-text {
	margin-top: 2rem;
	font-size: 1.3rem;
	color: $color_dark;

	@media #{$md_devices} {
		margin-top: 1.6rem;
		font-size: 1.4rem;
	}
}

// 追加 Air SI 用
.mattress-feature-pickup {
	margin: 0 auto 4rem;
	@media #{$md_devices} {
		margin-bottom: 8rem;
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
	}

	.mattress-feature-pickup__img {
		margin: 0;
		@media #{$md_devices} {
			width: 44rem;
		}
	}
	.mattress-feature-pickup__body {
		background: #fff;
		box-sizing: border-box;
		padding: 2rem;
		@media #{$md_devices} {
			padding: 0;
			background: none;
			margin: 0 3.2rem 0 0;
		}
	}
	.mattress-feature-pickup__title {
		text-align: center;
		font-family: $font_title;
		font-size: 1.8rem;
		line-height: 1.5;
		@media #{$md_devices} {
			text-align: left;
			font-size: 2.8rem;
		}
		span {
			display: block;
			font-size: 1.6rem;
			@media #{$md_devices} {
				font-size: 2.4rem;
			}
		}
	}
}


// Line Up
// --------------------------------------------------------

.mattress-lineup-box {
	width: 100%;
	display: block;

	@media #{$md_devices} {
		display: flex;
		justify-content: space-between;
	}
}
.mattress-lineup-box__item {
	width: 100%;
	padding: 1.6rem 2.4rem 2.4rem;
	background-color: #fff;

	@media #{$md_devices} {
		width: calc( ( 100% - 4rem ) / 2 );
		padding: 1.6rem 1.6rem 3.2rem;
	}

	& + & {
		@media #{$sm_devices_only} {
			margin-top: 3.2rem;
		}
	}
}
.mattress-lineup-label {
	width: 100%;
	padding: 1.4rem 1rem .8rem;
	border-radius: 2.8rem;
	color: $color_dark;
	text-align: center;
	line-height: 1;
	// text-transform: uppercase;
	background-color: $color_default;
}
.mattress-lineup-label--basic {

	// color setting
	.air03 & {
		color: #fff;
		background-color: #735d72;
	}
	.air01se & {
		color: $color_dark;
		background-color: #d3df48;
	}
}
.mattress-lineup-label--regular {

	// color setting
	.airsx-3fold & {
		color: #fff;
		background-color: #e1252b;
	}
	.airsi & {
		color: #fff;
		background-color: #d7053d;
	}
	.airsx & {
		color: #fff;
		background-color: #e1252b;
	}
}
.mattress-lineup-label--hard {

	// color setting
	.airsx-3fold & {
		color: #fff;
		background-color: #005bac;
	}
	.airsi & {
		color: #fff;
		background-color: #007cc1;
	}
	.airsx & {
		color: #fff;
		background-color: #005bac;
	}
	.air03 & {
		color: #fff;
		background-color: #434c79;
	}
	.air01se & {
		color: #fff;
		background-color: #3f77bc;
	}
}

.mattress-lineup-label__head {
	font-size: 2.2rem;
	font-family: $font_english_secondary;
	font-style: italic;

	@media #{$md_devices} {
		font-size: 2.6rem;
	}
}
.mattress-lineup-label__item {
	margin-left: .3rem;
	font-weight: 600;

	@media #{$md_devices} {
		margin-left: .6rem;
	}
}
.mattress-lineup-title {
	margin: 1.6rem 0 0;
	font-size: 1.9rem;
	font-weight: 600;
	line-height: 1.5;
	text-align: center;

	@media #{$md_devices} {
		font-size: 2.4rem;
	}
}
.mattress-lineup-img {
	width: 100%;
	text-align: center;

	@media #{$md_devices} {
		position: relative;
		z-index: 1;
	}
}
.mattress-lineup-img--full {
	width: 100%;
	max-width: 60rem;
	height: auto;
	margin: 0 auto;
}
.mattress-lineup-img--md {
	width: 100%;
	max-width: 64.8rem;
	height: auto;
	margin: 0 auto;
}
.mattress-lineup-img--sm {
	width: 100%;
	max-width: 52rem;
	height: auto;
	margin: 0 auto;
}
.mattress-lineup-img__item {
	width: 100%;
	margin: 1.6rem 0 0 0;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: 100%;
		height: 0;
		padding-top: ( 285 / 448 * 100% );
	}

	img {
		width: 100%;

		@media #{$md_devices} {
			width: auto;
			height: 100%;
			margin: auto;
			position: absolute;
			top: -100%;
			left: -100%;
			right: -100%;
			bottom: -100%;
			z-index: 1;
		}
	}
}
.mattress-lineup-img__caption {
	min-width: 16.8rem;
	margin-top: 1.6rem;
	padding: .8rem 1.1rem;
	font-size: 1.1rem;
	color: $color_default;
	letter-spacing: .052rem;
	text-align: center;
	line-height: 1.5;
	background: #fff;
	border: .2rem solid $color_default;
	border-radius: 2.8rem;
	display: inline-block;

	@media #{$md_devices} {
		// @include ps_icon(3.2rem, 2.4rem, auto, auto);
		margin-top: 1.6rem;
		font-size: 1.2rem;
		z-index: 5;
	}

	.mattress-lineup-box-single & {
		width: 100%;
		margin-top: 1.6rem;
		font-size: 1.4rem;
		font-weight: 600;
		position: static;

		@media #{$md_devices} {
			max-width: 29.4rem;
			margin-top: 2.4rem;
			font-size: 1.6rem;
		}

		// setting
		.airportable & {
			color: #19AAAE;
			// border-color: #19AAAE;
			border: none;
		}
	}
}
.mattress-lineup-img__caption--basic {

	// color setting
	.air03 & {
		color: #735d72;
		border-color: #735d72;
	}
	.air01se & {
		color: #d3df48;
		border-color: #d3df48;
	}
}
.mattress-lineup-img__caption--regular {

	// color setting
	.airsi & {
		color: #d7053d;
		border-color: #d7053d;
	}
	.airsx & {
		color: #e1252b;
		border-color: #e1252b;
	}
}
.mattress-lineup-img__caption--hard {

	// color setting
	.airsi & {
		color: #007cc1;
		border-color: #007cc1;
	}
	.airsx & {
		color: #005bac;
		border-color: #005bac;
	}
	.air03 & {
		color: #434c79;
		border-color: #434c79;
	}
	.air01se & {
		color: #3f77bc;
		border-color: #3f77bc;
	}
}
.mattress-lineup-list-wrap {
	margin-top: 2.4rem;
	padding: 0;

	@media #{$md_devices} {
		margin-top: 3.2rem;
		padding: 0 2.4rem;
	}

	.mattress-lineup-box-single-col02__item & {
		padding: 0;
	}
}
.mattress-lineup-list {
	@include reset;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& + & {
		margin-top: .4rem;

		@media #{$md_devices} {
			margin-top: .8rem;
		}
	}
}
.mattress-lineup-list__head {
	font-size: 1.3rem;
	padding-right: .8rem;
	flex: 1;

	@media #{$md_devices} {
		padding-right: 1.6rem;
		font-size: 1.6rem;
	}

	span {
		font-size: .8em;
	}
}
.mattress-lineup-list__item {
	@include reset;
	font-size: 1.3rem;

	@media #{$md_devices} {
		font-size: 1.6rem;
	}

	span {
		font-size: .8em;
	}
}
.mattress-lineup-list-text {
	margin-top: .4rem;
	font-size: 1.2rem;
	font-weight: 600;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 1.4rem;
	}
}
.mattress-lineup-notes {
	padding-left: 1em;
	text-indent: -1em;
	font-size: 1.2rem;
	color: $color_default;

	@media #{$md_devices} {
		font-size: 1.3rem;
	}
}

// single
.mattress-lineup-box-single {
	width: 100%;
	padding: 1.6rem 2.4rem;
	background-color: #fff;

	@media #{$md_devices} {
		padding: 4.8rem 4rem;
	}

	& + & {
		margin-top: 3.2rem;

		@media #{$md_devices} {
			margin-top: 4rem;
		}
	}
}
.mattress-lineup-box-single--sm {

	@media #{$md_devices} {
		width: 76rem;
		margin: 0 auto;
	}
}

.mattress-lineup-box-single__head {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		justify-content: center;
	}

	.mattress-lineup-img {
		width: 100%;

		@media #{$md_devices} {
			width: calc( ( 100% - 13.6rem ) / 2 );
		}


		& + .mattress-lineup-img {
			margin: 4rem 0 0 0;

			@media #{$md_devices} {
				margin: 0 0 0 5.6rem;
			}
		}
	}
}
.mattress-lineup-box-single__body {
	width: 100%;
	margin-top: 4rem;
	padding-top: 2.4rem;
	border-top: .1rem solid $color_border;

	.mattress-lineup-list-wrap {
		margin-top: 0;

		.mattress-lineup-list {
			display: block;

			@media #{$md_devices} {
				display: flex;
				justify-content: center;
			}
		}

		.mattress-lineup-list__head {
			padding-right: 0;
			flex: none;

			@media #{$md_devices} {
				padding-right: 1.6rem;
				flex: none;
			}
		}
	}

	&.mattress-lineup-box-single-col02 {
		.mattress-lineup-list {
			display: flex;

			@media #{$md_devices} {
				justify-content: space-between;
			}
		}
	}

	&.mattress-lineup-box-single__body--no-border {
		margin-top: 2.4rem;
		padding-top: 0;
		border-top: none;

		@media #{$md_devices} {
			margin-top: 4rem;
		}
	}
}
.mattress-lineup-box-single-col02 {
	width: 100%;
	margin-top: 4rem;
	padding-top: 0;
	border-top: none;

	@media #{$md_devices} {
		margin-top: 3.2rem;
		display: flex;
		justify-content: space-between;
	}
}
.mattress-lineup-box-single-col02__item {
	width: 100%;

	@media #{$md_devices} {
		width: 30.8rem;
		text-align: left;
	}
}
.mattress-lineup-box-single-content {
	width: 100%;
	text-align: left;

	@media #{$md_devices} {
		text-align: center;
	}
}

.mattress-lineup-box-single-title {
	margin-bottom: 3.2rem;
	font-size: 1.9rem;
	font-weight: 600;
	text-align: center;
	line-height: 1.5;

	@media #{$md_devices} {
		margin-bottom: 4rem;
		font-size: 2.4rem;
	}
}
.mattress-lineup-box-single-title--md {
	margin-bottom: 2rem;
	font-size: 1.9rem;
	font-weight: 600;
	text-align: center;

	@media #{$md_devices} {
		margin-bottom: 2.4rem;
		font-size: 2.8rem;
	}
}
.mattress-lineup-box-single-title--sm {
	margin-bottom: 1.6rem;
	font-size: 1.4rem;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 1.8rem;
		text-align: center;
	}

	span {
		font-size: 1.2rem;
		font-weight: 400;
		display: block;

		@media #{$md_devices} {
			font-size: 1.3rem;
		}
	}

	.mattress-lineup-box-single-col02__item & {
		text-align: left;
	}
}


// Bed Room
// --------------------------------------------------------

.mattress-gallery {
	width: 100%;
}

.mattress-maintenance {
	width: 100%;
	margin: 0 auto;

	@media #{$md_devices} {
		max-width: 76rem;
	}

	.mattress-gallery + & {
		margin: 4rem auto 0;

		@media #{$md_devices} {
			margin: 10rem auto 0;
		}
	}
}
.mattress-maintenance__text {
	margin-top: 1.8rem;
	font-size: 1.4rem;

	span {
		font-size: .9em;
	}
}


// Button
// --------------------------------------------------------

.mattress-button {
	text-align: center;
}