@charset "UTF-8";
// ================================================================================
// Heading
// 見出しブロックのスタイル
// ================================================================================

// ----------------------------------------------------------------
//	Style
// ----------------------------------------------------------------

h1:not([class]),
h1.rich-text,
h1[class^="mb-"],
h1[class^="pb-"] {
	margin: 9.6rem auto 3.2rem;
	font-size: 2.8rem;
	font-family: $font_title;
	font-weight: 600;
	color: $color_main;
	letter-spacing: -.05rem;
	line-height: 1.5;

	@media #{$md_devices} {
		margin: 16rem auto 4.8rem;
		font-size: 4.2rem;
		letter-spacing: -.075rem;
	}
}

h2:not([class]),
h2.rich-text,
h2[class^="mb-"],
h2[class^="pb-"] {
	margin: 6.4rem auto 3.2rem;
	font-size: 2.1rem;
	font-family: $font_title;
	font-weight: 600;
	color: $color_default;
	line-height: 1.714;

	@media #{$md_devices} {
		margin: 11.2rem auto 4.8rem;
		font-size: 3.4rem;
		line-height: 1.764;
	}
}

h3:not([class]),
h3.rich-text,
h3[class^="mb-"],
h3[class^="pb-"] {
	margin: 3.2rem auto 2rem;
	font-size: 1.9rem;
	font-family: $font_title;
	font-weight: 600;
	color: $color_default;
	letter-spacing: -.063rem;
	line-height: 1.526;

	@media #{$md_devices} {
		margin: 5.6rem auto 3rem;
		font-size: 2.8rem;
		letter-spacing: -.084rem;
		line-height: 1.5;
	}
}



// ----------------------------------------------------------------
//	Default
// ----------------------------------------------------------------

h1, h2 {

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	+ h1,
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6 {
		margin-top: 0;
	}
}



h3 {

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	+ h1,
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6 {
		margin-top: 0;
	}
}



h4 {

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	span {
		font-size: 62%;
		font-weight: 400;
	}

	+ h1,
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6 {
		margin-top: 0;
	}
}