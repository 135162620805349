@charset "UTF-8";
// ================================================================================
// Table Of Contents
// 目次のスタイル
// ================================================================================

// ----------------------------------------------------------------
// General
// ----------------------------------------------------------------

.toc {
	width: 100%;
	margin: 4.8rem auto 3.2rem;
	padding: 2rem;
	border-radius: .6rem;
	background-color: #F6F6F6;

	@media #{$md_devices} {
		margin-bottom: 4.8rem;
		padding: 3.2rem;
	}
}
.toc-list {
	@include reset;
	list-style: none;
}
.toc-list__item {
	@include reset;
	padding-left: 2.6rem;
	font-size: 1.3rem;
	line-height: 1.857;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		padding-left: 4rem;
		font-size: 1.4rem;
	}

	&::before {
		@include ps_icon(-.3rem, auto, auto, 0);
		content: 'Q';
		font-size: 2rem;
		font-weight: 600;
		line-height: 1.333;
		color: $color_main;

		@media #{$md_devices} {
			font-size: 2.4rem;
		}
	}

	a {
		color: $color_main;
	}

	& + & {
		margin-top: 1.6rem;
	}
}