@charset "UTF-8";
// ================================================================================
// Columns
// カラムブロックのスタイル
// ================================================================================

.wp-block-columns {
	margin: 3.2rem auto;
	@media #{$md_devices} {
		margin: 6.4rem auto;
	}

	.wp-block-column {
		margin-bottom: 3.2rem;
		&:last-child {
			margin-bottom: 0;
		}
		@media #{$md_devices} {
			margin-bottom: 0;
		}
		> *:first-child {
			margin-top: 0;
			margin-bottom: 0;
		}

		& > .wp-block-image {
			figure {
				margin-top: 0;
			}
		}
	}

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }
}



h1, h2, h3, h4, h5, h6 {
	+ .wp-block-columns {
		margin-top: 0;
	}
}

