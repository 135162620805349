@charset "UTF-8";
// ================================================================================
// List
// リストブロックのスタイル
// ================================================================================

ul {
	li {
		margin-bottom: 1rem;
		@media #{$md_devices} {
			margin-bottom: 1.6rem;
		}
	}

	&:first-child {
		margin-top: 0;
	}
}

