@charset "UTF-8";
// ================================================================================
// Quote
// 引用ブロックのスタイル
// ================================================================================

.wp-block-quote {

	cite {
		a {
		}
	}

	&:first-child {
		margin-top: 0;
	}
}

h1, h2, h3, h4, h5, h6 {
	+ .wp-block-quote {
		margin-top: 0;
	}
}

