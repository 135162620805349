@charset "UTF-8";
// ================================================================================
// Grid
// グリッドのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Grid
// ----------------------------------------------------------------

.grid {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.grid__item {
		margin: 4rem 0 0;
		text-align: left;

		&:first-child {
			margin-top: 0;
		}

		@media #{$md_devices} {
			width: calc( ( 100% - 3.2rem ) / 2 );

			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}
	}

	&.grid--02 {
		.grid__item {
			width: 100%;

			@media #{$md_devices} {
				width: calc( ( 100% - 3.2rem ) / 2 );

				&:nth-child(-n+2) {
					margin-top: 0;
				}
			}
		}
	}

	&.grid--reverse {
		flex-direction: row-reverse;

		.grid__item {

			@media #{$sm_devices_only} {
				margin-top: 2.4rem;
			}
		}
	}
}


// Flexible
// --------------------------------------------------------

.grid--flexible {

	@media #{$md_devices} {
		flex-wrap: nowrap;
	}

	.grid__item {
		width: auto;
		margin: 0;

		&.grid__item--grow {

			@media #{$md_devices} {
				margin-right: 5.4rem;
				flex: 1;
			}
		}

		& + .grid__item {

			@media #{$sm_devices_only} {
				margin-top: 4rem;
			}
		}
	}
}