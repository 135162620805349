@charset "UTF-8";
// ================================================================================
// Link
// リンクのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Link
// ----------------------------------------------------------------

.link-text {}


// arrow
// --------------------------------------------------------

.link-text--arrow {
	padding-left: 3.45rem;
	font-size: 1.6rem;
	font-weight: 600;
	display: inline-block;
	position: relative;
	z-index: 1;

	&::before {
		@include ps_icon(1.3rem, auto, auto, 0);
		width: 2.4rem;
		height: .2rem;
		background-color: $color_link;
		transform: translateX(0);
		transition: transform .5s ease-out;
	}
	&::after {
		@include ps_icon(.9rem, auto, auto, 1.45rem);
		width: 1rem;
		height: 1rem;
		border: .2rem solid $color_link;
		border-left: 0;
		border-bottom: 0;
		transform: translateX(0) rotate(45deg);
		transition: transform .5s ease-out;
	}

	&:hover {
		&::before {
			transform: translateX(.8rem);
		}
		&::after {
			transform: translateX(.8rem) rotate(45deg);
		}
	}

	&.link--primary {

		&::before {
			background-color: $color_btn_primary;
		}
		&::after {
			border-color: $color_btn_primary;
		}
	}
}


// pdf
// --------------------------------------------------------

.link-text--pdf {
	padding-left: 5.4rem;
	font-weight: 600;
	display: inline-block;
	position: relative;
	z-index: 1;

	&::before {
		@include ps_icon(.2rem, auto, auto, 0);
		width: 4.4rem;
		height: 2.2rem;
		background: url('../images/common/icon_pdf.svg') 50% 50% no-repeat;
		background-size: cover;
		z-index: 2;
	}
}