@charset "UTF-8";
// ================================================================================
// Utility
// モジュールの設定
// ================================================================================

// ----------------------------------------------------------------
// hidden, not
// ----------------------------------------------------------------

.hidden {
	display: none !important;
}

.sm\:hidden {
	@media #{$sm_devices_only} {
		display: none !important;
	}
}

.md\:hidden {
	@media #{$md_devices} {
		display: none !important;
	}
}

.bg-none {
	background: none;
}



// ----------------------------------------------------------------
// align
// ----------------------------------------------------------------

.left {
	text-align: left !important;
}
.md\:left {
	@media #{$md_devices} {
		text-align: left !important;
	}
}
.sm\:left {
	@media #{$sm_devices_only} {
		text-align: left !important;
	}
}

.center {
	text-align: center !important;
}
.md\:center {
	@media #{$md_devices} {
		text-align: center !important;
	}
}
.sm\:center {
	@media #{$sm_devices_only} {
		text-align: center !important;
	}
}

.right {
	text-align: right !important;
}
.md\:right {
	@media #{$md_devices} {
		text-align: right !important;
	}
}
.sm\:right {
	@media #{$sm_devices_only} {
		text-align: right !important;
	}
}



// ----------------------------------------------------------------
// border
// ----------------------------------------------------------------

.has-border {
	border: $color_border solid 1px;
}

.bdr-0 {
	border: none;
}

.sm\:bdr-0 {
	@media #{$sm_devices_only} {
		border: none !important;
	}
}

.sm\:bdb-1 {
	@media #{$sm_devices_only} {
		border-bottom: $color_border solid 1px;
	}
}



// ----------------------------------------------------------------
// space
// ----------------------------------------------------------------

// スペースの配列化
$array_space: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 25, 28, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

// margin
.mx-0 {
	margin: 0 !important;
}

.mx-auto {
	margin: auto !important;
}

@each $key in $array_space{
	.mb-#{$key} {
		margin-bottom: #{$key}px !important;
	}
	.mt-#{$key} {
		margin-top: #{$key}px !important;
	}
	.ml-#{$key} {
		margin-left: #{$key}px !important;
	}
	.mr-#{$key} {
		margin-right: #{$key}px !important;
	}
	@media #{$sm_devices_only} {
		.sm\:mb-#{$key} {
			margin-bottom: #{$key}px !important;
		}
		.sm\:mt-#{$key} {
			margin-top: #{$key}px !important;
		}
		.sm\:ml-#{$key} {
			margin-left: #{$key}px !important;
		}
		.sm\:mr-#{$key} {
			margin-right: #{$key}px !important;
		}
	}
	@media #{$md_devices} {
		.md\:mb-#{$key} {
			margin-bottom: #{$key}px !important;
		}
		.md\:mt-#{$key} {
			margin-top: #{$key}px !important;
		}
		.md\:ml-#{$key} {
			margin-left: #{$key}px !important;
		}
		.md\:mr-#{$key} {
			margin-right: #{$key}px !important;
		}
	}
}

// padding
.px-0 {
	padding: 0 !important;
}

@each $key in $array_space{
	.pb-#{$key} {
		padding-bottom: #{$key}px !important;
	}
	.pt-#{$key} {
		padding-top: #{$key}px !important;
	}
	.pl-#{$key} {
		padding-left: #{$key}px !important;
	}
	.pr-#{$key} {
		padding-right: #{$key}px !important;
	}
	@media #{$sm_devices_only} {
		.sm\:pb-#{$key} {
			padding-bottom: #{$key}px !important;
		}
		.sm\:pt-#{$key} {
			padding-top: #{$key}px !important;
		}
		.sm\:pl-#{$key} {
			padding-left: #{$key}px !important;
		}
		.sm\:pr-#{$key} {
			padding-right: #{$key}px !important;
		}
	}
	@media #{$md_devices} {
		.md\:pb-#{$key} {
			padding-bottom: #{$key}px !important;
		}
		.md\:pt-#{$key} {
			padding-top: #{$key}px !important;
		}
		.md\:pl-#{$key} {
			padding-left: #{$key}px !important;
		}
		.md\:pr-#{$key} {
			padding-right: #{$key}px !important;
		}
	}
}



// ----------------------------------------------------------------
// width
// ----------------------------------------------------------------

// 配列化
$array_width: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $key in $array_width {
	.w-#{$key} {
		width: #{$key}% !important;
	}
	@media #{$sm_devices_only} {
		.sm\:w-#{$key} {
			width: #{$key}% !important;
		}
	}
	@media #{$md_devices} {
		.md\:w-#{$key} {
			width: #{$key}% !important;
		}
	}
}

@each $key in $array_width {
	.maw-#{$key} {
		max-width: #{$key}% !important;
	}
	@media #{$sm_devices_only} {
		.sm\:maw-#{$key} {
			max-width: #{$key}% !important;
		}
	}
	@media #{$md_devices} {
		.md\:maw-#{$key} {
			max-width: #{$key}% !important;
		}
	}
}



// ----------------------------------------------------------------
// other
// ----------------------------------------------------------------

.clearfix:after {
	content:" ";
	display:block;
	clear:both;
}
