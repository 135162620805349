@charset "UTF-8";
// ================================================================================
// Paragraph
// 段落ブロックのスタイル
// ================================================================================

p {
	margin: $paragraph_space 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

h1, h2, h3, h4, h5, h6 {
	& + p {
		margin-top: 0;
	}
}

// for wp-admin
.editor-styles-wrapper {
	p {
		margin: $paragraph_space 0;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		& + p {
			margin-top: 0;
		}
	}
}

