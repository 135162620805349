// ================================================================================
// Block Editor
// ブロックエディタのスタイル - フロント側
// ================================================================================

.wp-content > *:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-group):not(.content-max-width):not(.inner--sm):not(.inner--lg),
*[class*="inner-container"] > *:not(.entry-content):not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-group):not(.inner--sm):not(.inner--lg) {
	box-sizing: border-box;
	max-width: calc( 100vw - 8.53% * 2 );
	margin-left: auto;
	margin-right: auto;
	@media #{$md_devices} {
		width: calc( 100vw - 2rem * 2 );
		max-width: 100rem;
	}
}

.wp-content > .alignwide,
*[class*="inner-container"] > .alignwide {
}

.wp-content > .alignfull,
*[class*="inner-container"] > .alignfull {
}

.wp-content > .wp-block-group,
*[class*="inner-container"] > .wp-block-group {
}

