@charset "UTF-8";
// ================================================================================
// Pager
// ページャーのスタイル
// ================================================================================

.pager {
	margin-top: 4.8rem;

	@media #{$md_devices} {
		margin-top: 6.4rem;
	}
}
.pager-list {
	@include reset;
	width: 100%;
	display: flex;
	justify-content: space-between;
	list-style: none;

	@media #{$md_devices} {
		justify-content: center;
	}
}
.pager-list__item {
	@include reset;

	@media #{$sm_devices_only} {
		flex: 1;
	}

	@media #{$md_devices} {
		margin: 0 .6rem;
	}
}
.page-numbers {
	font-size: 1.6rem;
	font-weight: 600;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;

	@media #{$md_devices} {
		width: 4rem;
		height: 4rem;
		border-radius: .8rem;
		border: .1rem solid $color_border;
	}

	&.prev {
		width: auto;
		padding-left: 3.3rem;
		margin-right: 1.8rem;
		border: none;
		text-decoration: underline;
		position: relative;
		z-index: 1;

		@media #{$sm_devices_only} {
			justify-content: flex-start;
		}

		&::before {
			@include ps_icon(0, auto, 0, 0);
			width: 2.4rem;
			height: .2rem;
			background-color: $color_link;
			transform: translateX(0);
			transition: transform .5s ease-out;
		}
		&::after {
			@include ps_icon(0, auto, 0, 0);
			width: 1rem;
			height: 1rem;
			border: .2rem solid $color_link;
			border-top: 0;
			border-right: 0;
			transform: translateX(0) rotate(45deg);
			transition: transform .5s ease-out;
		}

		&:hover {
			&::before {
				transform: translateX(-.5rem);
			}
			&::after {
				transform: translateX(-.5rem) rotate(45deg);
			}
		}
	}
	&.next {
		width: auto;
		margin-left: 1.8rem;
		padding-right: 3.3rem;
		border: none;
		text-decoration: underline;
		position: relative;
		z-index: 1;

		@media #{$sm_devices_only} {
			justify-content: flex-end;
		}

		&::before {
			@include ps_icon(0, 0, 0, auto);
			width: 2.4rem;
			height: .2rem;
			background-color: $color_link;
			transform: translateX(0);
			transition: transform .5s ease-out;
		}
		&::after {
			@include ps_icon(0, 0, 0, auto);
			width: 1rem;
			height: 1rem;
			border: .2rem solid $color_link;
			border-left: 0;
			border-bottom: 0;
			transform: translateX(0) rotate(45deg);
			transition: transform .5s ease-out;
		}

		&:hover {
			&::before {
				transform: translateX(.5rem);
			}
			&::after {
				transform: translateX(.5rem) rotate(45deg);
			}
		}
	}
	&.current {
		border: none;
		background-color: #F6F6F6;
	}
	&.dots {
		width: auto;
		height: 3rem;
		font-weight: normal;
		border-radius: inherit;
		border: none;
		letter-spacing: .181rem;
	}
	&.total {
		width: auto;
		border: none;
	}
}