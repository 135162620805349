@charset "UTF-8";
// ================================================================================
// Navigation
// ナビゲーションのスタイル（グローバル以外）
// ================================================================================

// ----------------------------------------------------------------
//  Local Navigation
// ----------------------------------------------------------------

.local-nav {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.local-nav__item {
	width: 100%;

	@media #{$md_devices} {
		width: calc( ( 100% - 4rem ) / 3 );
		margin: 2.4rem 2rem 0 0;

		&:nth-child(3n) {
			margin-right: 0;
		}

		&:nth-child(-n+3) {
			margin-top: 0;
		}
	}

	@media #{$sm_devices_only} {

		& + & {
			margin-top: 1rem;
		}
	}

	.button {
		width: 100%;
		padding: 1.4rem 2.4rem;
		font-size: 1.5rem;
		color: $color_dark;
		text-align: left;
		text-decoration: none;

		@media #{$md_devices} {
			padding: 1.8rem 2.6rem;
		}
	}
	.button--arrow {

		&::before {
			background-color: $color_main;
		}
		&::after {
			border-color: $color_main;
		}
	}
}


// ----------------------------------------------------------------
// Page Navigation
// ----------------------------------------------------------------

.page-nav {
	width: 100%;
	margin-top: 6.4rem;
	display: block;

	@media #{$md_devices} {
		margin-top: 12.8rem;
		display: flex;
		justify-content: center;
	}
}
.page-nav__link {
	width: 100%;
	display: block;

	& + & {
		margin: 2.4rem 0 0 0;

		@media #{$md_devices} {
			margin: 0 0 0 2.4rem;
		}
	}

	@media #{$md_devices} {
		flex: 1;

		&:hover {
			opacity: 1;
		}
	}
}
.page-nav-box {
	width: 100%;
	height: 0;
	padding-top: calc( 296 / 488 * 100% );
	border-radius: 1rem;
	display: block;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		border-radius: 2rem;
	}

	&::before {
		@include ps_icon(0, 0, 0, 0);
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.3);
		z-index: 2;
		transition: background-color .5s;

		@media #{$md_devices} {
			.page-nav__link:hover & {
				background-color: rgba(0,0,0,.6);
			}
		}
	}
	&::after {
		@include ps_icon(auto, 2rem, 2rem, auto);
		width: 2.6rem;
		height: 1.6rem;
		background: url('../images/common/icon_arrow_r.svg') 50% 50% no-repeat;
		background-size: contain;
		z-index: 5;
		transform: translateX(0);
		transition: transform .5s;

		@media #{$md_devices} {
			.page-nav__link:hover & {
				transform: translateX(1rem);
			}
		}
	}
}
.page-nav-box__item {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 3;
	transform: translate(-50%,-50%);
}
.page-nav-box__img {
	@include ps_icon(-100%, -100%, -100%, -100%);
	width: 100%;
	height: 100%;
	transform: scale(1);
	transition: transform .5s;

	@media #{$md_devices} {
		.page-nav__link:hover & {
			transform: scale(1.08);
		}
	}
}
.page-nav-box-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	color: #fff;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.page-nav-box-title {
	margin-bottom: 0;
	font-size: 2.3rem;
	font-family: $font_title;
	line-height: 1;
	text-shadow: 0 .1rem .2rem rgba(0, 0, 0, .32);

	@media #{$md_devices} {
		font-size: 2.8rem;
	}
}
.page-nav-box-text {
	margin-top: 2.4rem;
	font-size: 1.4rem;
	text-shadow: 0 .1rem .2rem rgba(0, 0, 0, .32);

	@media #{$md_devices} {
		margin-top: 3rem;
		font-size: 1.6rem;
	}
}


// ----------------------------------------------------------------
// Post Navigation
// ----------------------------------------------------------------

.post-navigation {
	width: 100%;
	margin-top: 4.8rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	@media #{$md_devices} {
		margin-top: 7.7rem;
		flex-wrap: nowrap;
		align-items: center;
	}
}
.post-navigation__item {
	display: flex;

	@media #{$sm_devices_only} {
		width: 50%;

		&:first-child {
			width: 100%;
			margin-bottom: 2.4rem;
		}
		&:last-child {
			justify-content: flex-end;
		}
	}

	@media #{$md_devices} {
		flex: 1;
	}
}
.nav-link {
	font-weight: 600;
	display: block;
	position: relative;
	z-index: 2;

	&.is-home,
	&.is-shop {
		@extend .button;

		@media #{$md_devices} {
			width: 28rem;
			margin: 0 auto;
		}
	}
	&.is-shop {

		@media #{$md_devices} {
			padding: 1.3rem 2.4rem;
		}

		span {
			padding-left: 3.7rem;
			position: relative;
			z-index: 1;

			&::before {
				@include ps_icon(0, auto, 0, 0);
				width: 2.5rem;
				height: 2.4rem;
				background: url('../images/common/icon_shop.svg') 50% 50% no-repeat;
				background-size: cover;
				z-index: 2;
			}
		}
	}
	&.is-prev {
		padding: 0 1rem 0 3.2rem;

		@media #{$md_devices} {
			max-width: 26.2rem;
			padding: 0 1rem 0 3.3rem;
		}

		&::before {
			@include ps_icon(1.1rem, auto, auto, 0);
			width: 2.4rem;
			height: .2rem;
			background-color: $color_link;
			transform: translateX(0);
			transition: transform .5s ease-out;

			@media #{$md_devices} {
				top: 0;
				left: 0;
				right: auto;
				bottom: 0;
			}
		}
		&::after {
			@include ps_icon(.7rem, auto, auto, 0);
			width: 1rem;
			height: 1rem;
			border: .2rem solid $color_link;
			border-top: 0;
			border-right: 0;
			transform: translateX(0) rotate(45deg);
			transition: transform .5s ease-out;

			@media #{$md_devices} {
				top: 0;
				left: 0;
				right: auto;
				bottom: 0;
			}
		}

		&:hover {
			&::before {
				transform: translateX(-.5rem);
			}
			&::after {
				transform: translateX(-.5rem) rotate(45deg);
			}
		}
	}
	&.is-next {
		padding: 0 3.2rem 0 1rem;

		@media #{$md_devices} {
			max-width: 26.2rem;
			margin: 0 0 0 auto;
			padding: 0 3.3rem 0 1rem;
		}

		&::before {
			@include ps_icon(1.1rem, 0, auto, auto);
			width: 2.4rem;
			height: .2rem;
			background-color: $color_link;
			transform: translateX(0);
			transition: transform .5s ease-out;

			@media #{$md_devices} {
				top: 0;
				left: auto;
				right: 0;
				bottom: 0;
			}
		}
		&::after {
			@include ps_icon(.7rem, 0, auto, auto);
			width: 1rem;
			height: 1rem;
			border: .2rem solid $color_link;
			border-left: 0;
			border-bottom: 0;
			transform: translateX(0) rotate(45deg);
			transition: transform .5s ease-out;

			@media #{$md_devices} {
				top: 0;
				left: auto;
				right: 0;
				bottom: 0;
			}
		}

		&:hover {
			&::before {
				transform: translateX(.5rem);
			}
			&::after {
				transform: translateX(.5rem) rotate(45deg);
			}
		}
	}
}
