@charset "UTF-8";
// ================================================================================
// Image
// 画像ブロックのスタイル
// ================================================================================

.wp-block-image {
	figcaption {
	}
	&:first-child {
		margin-top: 0;
	}
}

h1, h2, h3, h4, h5, h6 {
	+ .wp-block-image {
		margin-top: 0;
	}
}
