@charset "UTF-8";
// ================================================================================
// Pick Up
// ピックアップブロックのスタイル
// ================================================================================

.wp-pickup {
	margin: 4rem auto;
	@media #{$md_devices} {
		margin: 8rem auto;
	}
	.inner {
	}
	.wp-pickup-title {
		margin: 0 auto 1.6rem;
		span {
			display: inline-block;
			line-height: 1;
			border: 2px solid $color_alert;
			color: $color_alert;
			font-weight: 700;
			font-family: $font_english_secondary;
			padding: 1rem 1.6rem .8rem;
			text-align: center;
			border-radius: 6rem;
			min-width: 12.8rem;

		}
	}
	.wp-pickup-posts {
	}
	.wp-pickup-post {
		font-weight: 700;
		a {
			color: $color_default;
		}

		& + .wp-pickup-post {
			border-top: 1px solid $color-border;
			box-sizing: border-box;
			padding: 1.6rem 0 0;
			margin: 1.6rem 0 0;
		}
	}
}

