@charset "UTF-8";
// ================================================================================
// Frame
// フレーム（iframe）のスタイル
// ================================================================================

// ----------------------------------------------------------------
// Frame
// ----------------------------------------------------------------

// 16:9
.frame {
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	position: relative;
	z-index: 1;
	overflow: hidden;

	video,
	iframe {
		width: 100%;
		height: 100%;
		position:absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&.frame--md {
		padding-top: 63.29%;
	}
}