@charset "UTF-8";
// ================================================================================
// Recruit
// 求人情報のスタイル
// ================================================================================

.recruit-intro {
	margin: 1.6rem auto 0;
	@media #{$md_devices} {
		margin-top: 2.4rem;
		display: flex;
	}

	.recruit-intro__image {
		text-align: center;
		width: 12.8rem;
		margin: 0 auto 2.4rem;
		@media #{$md_devices} {
			margin-right: 5.6rem;
			margin-left: 0;
			margin-bottom: 0;
			text-align: right;
			width: 28rem;
		}
		img {
		}
	}

	.recruit-intro__container {
		text-align: left;
		@media #{$md_devices} {
			flex: 1;
		}
	}
}


.recruit-detail {
	background: $color_bg_primary;
	box-sizing: border-box;
	padding: 4rem;
	margin: 4.8rem auto;
	@media #{$md_devices} {
		padding: 6.4rem 0 9.6rem;
		margin: 7.2rem auto;
	}
}


.recruit-flow {
	counter-reset: number 0;
	margin: 2rem auto 2.4rem;
	text-align: left;
	@media #{$md_devices} {
		margin-top: 3.2rem;
		display: flex;
		flex-direction: row-reverse;
	}

	&.inner {
		@media #{$sm_devices_only} {
			width: 100%;
		}
	}

	.recruit-flow__image {
		text-align: center;
		max-width: 56rem;
		margin-left: auto;
		margin-right: auto;
		@media #{$md_devices} {
			text-align: left;
			width: 44rem;
			margin: 0 0 0 4rem;
		}
	}
	.recruit-flow__container {
		@media #{$md_devices} {
			flex: 1;
		}
	}

	.recruit-flow-item {
		&:not(:first-child) {
			margin-top: 2.4rem;
		}
	}
	.recruit-flow-item__title {
		margin: 0 0 1.6rem;
		padding-left: 3.6rem;
		font-size: 1.6rem;
		text-align: left;
		line-height: 1.75;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			padding-left: 4.8rem;
			font-size: 1.8rem;
			line-height: inherit;
		}


		&:before {
			@include ps_icon(0, auto, 0, 0);
			background: $color_main;
			counter-increment: number 1;
			content: counter(number) " ";
			font-family: $font_english_secondary;
			color: #fff;
			text-align: center;
			font-size: 1.8rem;
			line-height: 30px;
			width: 28px;
			height: 28px;
			border-radius: 28px;
			@media #{$md_devices} {
				font-size: 2rem;
				line-height: 35px;
				width: 32px;
				height: 32px;
				border-radius: 32px;
			}
		}
	}
	ul {
		margin: 0;
		padding-left: 1em;
		li {
			margin-bottom: 0;
		}
	}
}
.recruit-flow-button {
	margin-top: 2.4rem;
	margin-bottom: 6.4rem;
	@media #{$md_devices} {
		margin-top: 3.2rem;
		margin-bottom: 8.8rem;
	}
}



.recruit-feature {
	counter-reset: number 0;
	margin: 2rem auto 0;
	text-align: left;
	@media #{$md_devices} {
		margin-top: 3.2rem;
		display: flex;
		flex-direction: row-reverse;
	}

	&.inner {
		@media #{$sm_devices_only} {
			width: 100%;
		}
	}

	.recruit-feature__image {
		text-align: center;
		max-width: 56rem;
		margin-left: auto;
		margin-right: auto;
		@media #{$md_devices} {
			text-align: left;
			width: 44rem;
			margin: 0 0 0 4rem;
		}
	}
	.recruit-feature__container {
		@media #{$md_devices} {
			flex: 1;
		}
	}

	.recruit-feature-item {
		&:not(:first-child) {
			margin-top: 2.4rem;
		}
	}
	.recruit-feature-item__title {
		margin: 0 0 1.6rem;
		padding-left: 3.6rem;
		font-size: 1.6rem;
		text-align: left;
		line-height: 1.75;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			padding-left: 4.8rem;
			font-size: 1.8rem;
			line-height: inherit;
		}


		&:before {
			@include ps_icon(0, auto, 0, 0);
			background: $color_alert;
			counter-increment: number 1;
			content: counter(number) " ";
			font-family: $font_english_secondary;
			color: #fff;
			text-align: center;
			font-size: 1.8rem;
			line-height: 30px;
			width: 28px;
			height: 28px;
			border-radius: 28px;
			@media #{$md_devices} {
				font-size: 2rem;
				line-height: 35px;
				width: 32px;
				height: 32px;
				border-radius: 32px;
			}
		}
	}
	ul {
		margin: 0;
		padding-left: 1em;
		li {
			margin-bottom: 0;
		}
	}
}





.recruit-sotre {
}
.recruit-sotre-item {
	margin: 0 auto 4rem;
	@media #{$md_devices} {
		margin-bottom: 5.6rem;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.recruit-sotre-information {
	text-align: left;
	margin: 0;
	@media #{$md_devices} {
		display: flex;
		//align-items: center;
	}
	dt {
		font-weight: 700;
		margin: 0;
		@media #{$md_devices} {
			margin-right: 3.2rem;
		}
	}
	dd {
		margin: 0;
		@media #{$md_devices} {
			flex: 1;
		}
		p {
			margin: 0;
		}
	}
}

