@charset "UTF-8";
// ================================================================================
// Formのコンポーネント
// ================================================================================

.form-box {
	input,
	textarea,
	select {
		border: none;
		outline: none;
		box-shadow: none;
		background-color: transparent;
		border-radius: 0;
		appearance: none;
	}
	select::-ms-expand {
		display: none;
	}
	[type=text],
	[type=password],
	[type=number],
	[type=email],
	[type=tel] {
		width: 100%;
		padding: 1.1rem 1.6rem;
		font-size: 1.6rem;
		color: $color_default;
		line-height: 1.875;
		border: .2rem solid $color_border;
		background-color: #fff;
		@include placeholder_color(#CCCCCC);

		@media #{$md_devices} {
			padding: 1.1rem 2.4rem;
		}

		&.wpcf7-not-valid {
			border: .2rem solid $color_alert;
		}
	}
	textarea {
		width: 100%;
		height: 20rem;
		padding: 1.1rem 1.6rem;
		font-size: 1.6rem;
		line-height: 1.875;
		border: .2rem solid $color_border;
		background-color: #fff;
		@include placeholder_color(#CCCCCC);

		@media #{$md_devices} {
			padding: 1.1rem 2.4rem;
		}

		&.wpcf7-not-valid {
			border: .2rem solid $color_alert;
		}

	}
	[type=radio] {
		display: none;
		& + span {
			padding-left: 3.6rem;
			position: relative;
			display: inline-block;
			line-height: 2.6rem;

			&::before,
			&::after {
				@include ps_icon(0, auto, 0, 0);
				z-index: 1;
				border-radius: 100%;
			}
			&::before {
				width: 2.6rem;
				height: 2.6rem;
				left: 0;
				background-color: #fff;
				border: .2rem solid $color_border;
			}
			&::after {
				width: 1.4rem;
				height: 1.4rem;
				left: .6rem;
				background-color: $color_main;
				opacity: 0;
			}
			&:hover {
				cursor: pointer;
			}
		}

		&:checked {
			& + span {
				&::after {
					opacity: 1;
				}
			}
		}
	}
	[type=checkbox] {
		display: none;
		& + span {
			padding-left: 3.6rem;
			position: relative;
			display: inline-block;
			line-height: 2.6rem;
			&::before,
			&::after {
				@include ps_icon(0, auto, 0, 0);
				z-index: 1;
			}
			&::before {
				width: 2.6rem;
				height: 2.6rem;
				background-color: #fff;
				border: .2rem solid $color_border;
			}
			&::after {
				width: 1rem;
				height: 1.4rem;
				border: .4rem solid $color_main;
				border-top: none;
				border-left: none;
				top: -.4rem;
				left: .8rem;
				transform: rotate(45deg);
				opacity: 0;
			}
			&:hover {
				cursor: pointer;
			}
		}

		&:checked {
			& + span {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	[type=submit] {
		width: 100%;
		padding: 1.9rem 1rem;
		font-size: 1.6rem;
		font-weight: 600;
		color: #fff;
		line-height: 1.67;
		letter-spacing: .06rem;
		text-align: center;
		background-color: $color_alert;
		border-radius: 3.6rem;
		border: none;
		cursor: pointer;

		@media #{$md_devices} {
			max-width: 38rem;
			padding: 2.1rem 2rem;
			font-size: 1.8rem;
			@include anime_alpha;

			&:hover {
				@include anime_alpha_hover(70);
			}
		}
	}
	[type=submit]:disabled {}
}

.form-box {
	width: 100%;

	& + & {
		margin-top: 2.8rem;

		@media #{$md_devices} {
			margin-top: 3.2rem;
		}
	}
}
.form-box__label {
	width: 100%;
	margin-bottom: .8rem;
	font-size: 1.6rem;
	display: flex;
	align-items: flex-end;
}
.form-label {
	margin: 0;
	font-weight: 600;
}
.form-required {
	margin-left: .8rem;
	font-size: 1.4rem;
	font-weight: bold;
	color: $color_alert;
}
.form-optional {
	margin-left: .8rem;
	font-size: 1.4rem;
	color: #999999;
}
.form-box__input {
	width: 100%;
	display: block;
}
.form-box--privacy,
.form-box--submit {
	text-align: center;
}

.form-radio,
.form-check {
	line-height: 1;
	padding-top: 1.6rem;

	.wpcf7-list-item {
		display: block;

		& + .wpcf7-list-item {
			margin-top: 1.6rem;
		}
	}
}

.form-select {
	width: 100%;
	position: relative;
	z-index: 1;

	&::after {
		@include ps_icon(0, 2rem, 0, auto)
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: .8rem .45rem 0 .45rem;
		border-color: $color_default transparent transparent transparent;
		z-index: 10;
	}
	select {
		width: 100%;
		padding: 1.1rem 1.6rem;
		line-height: 1.875;
		border: .2rem solid $color_border;
		background-color: #fff;
		position: relative;
		z-index: 1;
		cursor: pointer;
		outline: none;

		@media #{$md_devices} {
			padding: 1.1rem 2.4rem;
		}
	}
	select::-ms-expand {
		display: none;
	}
}

.wpcf7-not-valid-tip {
	color: $color_alert;
	font-size: 1.4rem;
	line-height: 1.5;
	font-weight: normal;
	display: block;
	margin-top: .8rem;
}
div.wpcf7 .ajax-loader {
	display: block;
	margin: 1rem auto 0;
}
.wpcf7 form .wpcf7-response-output {
	text-align: center;
	padding: 2rem;
	margin: 1.6rem 0 0;
	border: none;
	background-color: $color_border_thin;
}
.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output,
.wpcf7 form.spam .wpcf7-response-output,
.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
	color: $color_alert;
	font-weight: bold;
}

.wpcf7 form.sent .wpcf7-response-output {
	color: $color_link;
	background-color: none;
}

.wpcf7 .wpcf7-spinner {
	display: block;
	margin-top: 1.6rem;
}
