@charset "UTF-8";
// ================================================================================
// Loop
// ループ（カセット）のスタイル
// ================================================================================

// ----------------------------------------------------------------
// Post（blog）
// ----------------------------------------------------------------

// Front
// --------------------------------------------------------

.blog-post {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
	}
}
.blog-post__date {
	margin: 0 0 .8rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.5;
	color: $color_main;

	@media #{$md_devices} {
		margin: 0 5.2rem 0 0;
		font-size: 1.6rem;
	}
}
.blog-post__title {
	margin: 0;
	font-size: 1.4rem;
	font-weight: 400;

	@media #{$md_devices} {
		font-size: 1.6rem;
	}
}
.blog-post-link {
	color: $color_default;
	text-decoration: none;

	@media #{$md_devices} {

		&:hover {
			text-decoration: underline;
		}
	}
}


// Archive (home)
// --------------------------------------------------------

.news-list {
	@include reset;
	list-style: none;
}
.news-list__item {
	padding: 1.6rem 0;
	border-top: .1rem solid $color-border;

	&:last-child {
		border-bottom: .1rem solid $color-border;
	}

	.blog-post {
		display: block;
	}
	.blog-post__date {
		margin: 0 0 .4rem 0;
		font-size: 1.4rem;
	}
}