@charset "UTF-8";
// ================================================================================
// Group
// グループブロックのスタイル
// ================================================================================

.wp-block-group {
	box-sizing: border-box;
	margin: 2.4rem auto;
	@media #{$md_devices} {
		margin: 4rem auto;
	}

	&.has-background {
		padding: 2rem;
		@media #{$md_devices} {
			padding: 4rem;
		}
	}

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	+ h1,
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6 {
		//margin-top: 0;
	}

	.wp-block-group__inner-container {
		.wp-block-media-text {
			margin-top: 0;
			margin-bottom: 2.4rem;
			@media #{$md_devices} {
				margin-bottom: 4rem;
			}
		}

		& > *:last-child {
			margin-bottom: 0;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	+ .wp-block-group {
		margin-top: 0;
	}
}

