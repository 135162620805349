@charset "UTF-8";
// ================================================================================
// Button
// ボタンのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Button
// ----------------------------------------------------------------

.button {
	width: 100%;
	padding: 1.5rem .5rem 1.6rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	border-radius: 3.2rem;
	background-color: $color_btn_default;
	display: inline-block;

	@media #{$md_devices} {
		max-width: 44.7rem;
		padding: 1.3rem 4.8rem;
		font-size: 1.6rem;
	}
}
.button--primary {
	width: 100%;
	padding: 1.5rem 4rem;
	font-size: 1.4rem;
	font-weight: 600;
	color: $color_btn_primary;
	text-align: center;
	text-decoration: none;
	border-radius: 3.6rem;
	background-color: #fff;
	border: .2rem solid $color_btn_primary;
	display: inline-block;

	@media #{$md_devices} {
		max-width: 30.4rem;
		padding: 1.7rem 5.4rem;
		font-size: 1.6rem;
	}

	&.is-expand {
		@media #{$md_devices} {
			max-width: 36.4rem;
		}
	}
}
.button--secondary {
	width: 100%;
	padding: 1.5rem 4rem;
	font-size: 1.4rem;
	font-weight: 600;
	color: $color_btn_secondary;
	text-align: center;
	text-decoration: none;
	border-radius: 3.6rem;
	background-color: #fff;
	border: .2rem solid $color_btn_secondary;
	display: inline-block;

	@media #{$md_devices} {
		max-width: 30.4rem;
		padding: 1.7rem 5.4rem;
		font-size: 1.6rem;
	}
}
.button--tertiary {
	width: 100%;
	padding: 1.3rem 4rem;
	font-size: 1.4rem;
	font-weight: 600;
	color: #fff;
	text-align: center;
	text-decoration: none;
	border-radius: 3.6rem;
	background-color: $color_btn_primary;
	border: .2rem solid $color_btn_primary;
	display: inline-block;

	@media #{$md_devices} {
		max-width: 30.4rem;
		padding: 1.9rem 5.4rem;
		font-size: 1.6rem;
	}
}


// Size
// --------------------------------------------------------

.button--md {
	width: 100%;

	@media #{$md_devices} {
		max-width: 48.4rem;
	}
}
.button--lg {
	width: 100%;

	@media #{$md_devices} {
		max-width: 48.4rem;
	}
}

.button--block {
	width: 100%;
	display: block;
}
.button--free {
	width: 100%;

	@media #{$md_devices} {
		width: auto;
		max-width: inherit;
	}
}



// Arrow
// --------------------------------------------------------

.button--arrow {
	position: relative;
	z-index: 1;

	&::before {
		@include ps_icon(0, 1.6rem, 0, auto);
		width: 2.4rem;
		height: .2rem;
		background-color: $color_default;
		transform: translateX(0);
		transition: transform .5s ease-out;

		@media #{$md_devices} {
			right: 2.6rem;
		}
	}
	&::after {
		@include ps_icon(0, 1.7rem, 0, auto);
		width: 1rem;
		height: 1rem;
		border: .2rem solid $color_default;
		border-left: 0;
		border-bottom: 0;
		transform: translateX(0) rotate(45deg);
		transition: transform .5s ease-out;

		@media #{$md_devices} {
			right: 2.7rem;
		}
	}

	@media #{$md_devices} {
		&:hover {
			&::before {
				transform: translateX(.8rem);
			}
			&::after {
				transform: translateX(.8rem) rotate(45deg);
			}
		}
	}

	// setting
	&.button--primary {

		&::before {
			background-color: $color_btn_primary;
		}
		&::after {
			border-color: $color_btn_primary;
		}
	}
	&.button--secondary {

		&::before {
			background-color: $color_btn_secondary;
		}
		&::after {
			border-color: $color_btn_secondary;
		}
	}

	&.button--tertiary {

		&::before {
			background-color: $color_white;
		}
		&::after {
			border-color: $color_white;
		}
	}
}


// Anchor
// --------------------------------------------------------

.button--anchor {
	margin-bottom: .7rem;
	padding: 1.2rem 1rem 1.6rem;
	font-size: 1.4rem;
	color: $color_dark;
	line-height: 1.5;
	text-decoration: none;
	border-radius: 2.8rem;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		max-width: 48.4rem;
		margin-bottom: .9rem;
		padding: 1.8rem 2rem;
		font-size: 1.5rem;
		line-height: inherit;
	}

	&::after {
		@include ps_icon(auto, 0, -.5rem, 0);
		width: 1.6rem;
		height: 1.6rem;
		border: .3rem solid $color_main;
		border-top: none;
		border-left: none;
		transform: rotate(45deg);
		z-index: 2;
	}
}


// Contact
// --------------------------------------------------------

.button--contact {

	@media #{$md_devices} {
		max-width: 34.4rem;
		padding: 1.7rem .8rem;
	}
	span {
		padding-left: 3.2rem;
		display: inline-block;
		position: relative;
		z-index: 1;

		&::before {
			@include ps_icon(0, auto, 0, 0);
			width: 2.4rem;
			height: 1.96rem;
			background: url('../images/common/icon_contact.svg') 50% 50% no-repeat;
			background-size: contain;
			z-index: 1;

			@media #{$md_devices} {

			}
		}
	}
}