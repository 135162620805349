@charset "UTF-8";
// ================================================================================
// Floating
// フローティングのスタイル
// ================================================================================

.floating {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	display: none;
}
.floating-container {
	margin: 0;
	background: rgba(255, 255, 255, .3);
	padding: 1rem 1.6rem;
	box-sizing: border-box;
	text-align: center;
	display: flex;
	gap: 0 2%;
	justify-content: center;
	@media #{$md_devices} {
		gap: 0 1.6rem;
	}
}
.floating__button {
	margin: 0;
	width: 49%;
	max-width: 44rem;
	.button--tertiary {
		@media #{$sm_devices_only} {
			font-size: 1.2rem;
			line-height: 1.5;
			padding: 1.1rem 1.6rem;
		}
		@media #{$md_devices} {
			padding-top: 1.4rem;
			padding-bottom: 1.4rem;
			max-width: 44rem;
		}
	}
}

