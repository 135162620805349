@charset "UTF-8";
// ================================================================================
// Embed
// 埋め込みブロックのスタイル
// ================================================================================

.wp-block-embed {
	margin: 3.6rem auto;
	@media #{$md_devices} {
		margin: 8rem auto;
	}

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	+ h1,
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6 {
		margin-top: 0;
	}

	figcaption {
	}
}

h1, h2, h3, h4, h5, h6 {
	+ .wp-block-embed {
		margin-top: 0;
	}
}


