@charset "UTF-8";
// ================================================================================
// Balloon
// 吹き出しのスタイル
// ================================================================================

// ----------------------------------------------------------------
//	Babble
// ----------------------------------------------------------------

.babble {
	margin: 0;
	color: #fff;
	background-color: $color_moss_gray;
	display: inline-block;
	border-radius: 2.4rem;
	position: relative;
	z-index: 3;
	padding: 1rem .6rem 1.2rem;
	font-size: 1.1rem;
	line-height: 1.35;
	z-index: 3;
	font-weight: 700;

	@media #{$md_devices} {
		border-radius: 4rem;
		padding: 1.2rem 3.6rem;
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 400;
	}

	&::before {
		@include ps_icon(auto, auto, -.8rem, 4.3rem);
		width: 1.8rem;
		height: 1.8rem;
		background-color: $color_moss_gray;
		border-radius: 100%;
		z-index: 2;
	}
	&::after {
		@include ps_icon(auto, auto, -1.9rem, 5.7rem);
		width: 1rem;
		height: 1rem;
		background-color: $color_moss_gray;
		border-radius: 100%;
		z-index: 1;
	}
}


// ----------------------------------------------------------------
//	Balloon
// ----------------------------------------------------------------

.balloon {
	margin: 0;
	padding: 1.2rem 3.2rem;
	font-size: 1.6rem;
	line-height: 1.5;
	color: #fff;
	background-color: $color_main;
	display: inline-block;
	border-radius: 4rem;
	position: relative;
	z-index: 3;

	@media #{$md_devices} {
		padding: 1.2rem 3.6rem;
		font-size: 1.8rem;
	}

	&::before {
		@include ps_icon(auto, auto, -.9rem, 2.6rem);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 1.2rem 1.6rem 0 0;
		border-color: $color_main transparent transparent transparent;
		z-index: 2;

		@media #{$md_devices} {
			border-width: 2.5rem 3.2rem 0 0;
			left: 5.6rem;
			bottom: -1.7rem;
		}
	}
}