@charset "UTF-8";
// ================================================================================
// Page Top
// Page Topのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Page Top
// ----------------------------------------------------------------

.page-top {
	position: absolute;
	right: 1.4rem;
	z-index: 1;
	top: 4.8rem;

	@media #{$md_devices} {
		top: -9rem;
		right: 0.55rem;
	}

	&.page-top--fixed {
		position: fixed;
		top: auto;
		bottom: 1.6rem;
		opacity: 0;
		transform: translateY(10rem);
		transition: opacity .4s ease-in-out .1s, transform .5s ease-in-out;

		&.page-top--active {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
.page-top__link {
	padding: 1rem;
	display: block;
	text-decoration: none;

	span {
		font-size: 1.1rem;
		font-weight: 600;
		color: $color_dark_thin;
		line-height: 1;
		letter-spacing: .022rem;
		text-align: center;
		writing-mode: vertical-rl;
		position: relative;
		z-index: 1;

		&::before {
			@include ps_icon(-3.2rem, 0, auto, 0);
			width: 1.7rem;
			height: 2.8rem;
			background: url(../images/common/icon_page-top.svg) 50% 50% no-repeat;
			background-size: contain;
			z-index: 2;
			transition: top .2s ease-in-out;

			@media #{$md_devices} {
				top: -3.6rem;

				.page-top:hover & {
					top: -4.4rem;
				}
			}
		}
	}
}