@charset "UTF-8";
// ================================================================================
// Breadcrumb
// パンくずのスタイル
// ================================================================================

.breadcrumb {
	width: 100%;
	margin-top: 1.2rem;
	padding: 0;
	box-sizing: border-box;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-webkit-overflow-scrolling: touch;

	@media #{$md_devices} {
		margin-top: 1.6rem;
		padding: 0 0 0 .4rem;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}
.breadcrumb-list {
	@include reset;
	list-style: none;
	display: flex;
	align-items: center;

	li {
		font-size: 1rem;
		white-space: nowrap;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			font-size: 1.2rem;
		}

		&:not(:first-child) {
			padding-left: 2rem;

			@media #{$md_devices} {
				padding-left: 2.8rem;
			}

			&::before {
				@include ps_icon(0, auto, 0, .6rem);
				width: .8rem;
				height: .1rem;
				background-color: #B2B2B2;
				z-index: 1;

				@media #{$md_devices} {
					left: 1rem;
				}
			}
		}

		br {
			display: none;
		}

		&.current-crumb {
			a {
				color: $color_default;
				text-decoration: none;
				pointer-events: none;
			}
			strong {
				font-weight: 400;
			}
		}
	}
}
// .breadcrumb-list {
// 	list-style: none;
// 	padding: 0;
// 	margin: 0;
// 	display: flex;

// 	li {
// 		white-space: nowrap;
// 		padding: 0 4rem 0 0;
// 		font-weight: 400;
// 		font-size: 1.1rem;
// 		line-height: 1.82;
// 		position: relative;
// 		@media #{$md_device} {
// 			flex-wrap: wrap;
// 			padding: 0 4 rem 0 0;
// 			font-size: 13px;
// 			line-height: 1.46;
// 		}
// 		&:last-child {
// 			@media #{$md_device} {
// 				padding-right: 0;
// 			}
// 		}
// 		&:not(:last-child) {
// 			&:after {
// 				@include ps_icon(0, 2rem, 0, auto);
// 				width: .1rem;
// 				height: 1.5rem;
// 				background: #ddd;
// 			}
// 		}
// 		br {
// 			display: none;
// 		}
// 	}

// 	a {
// 		color: $color_default;
// 		text-decoration: none;
// 		display: inline-block;
// 		//padding: .4rem 0;
// 		padding: 0;
// 		&:hover {
// 			text-decoration: underline;
// 		}
// 	}

// 	strong {
// 		font-weight: 400;
// 		color: #7f7f7f;
// 	}

// 	.breadcrumb__item--home {
// 		a {
// 			background: url(../images/icon_home.svg) no-repeat scroll 50% 50%;
// 			background-size: 1.6rem 1.5rem;
// 			display: block;
// 			width: 1.6rem;
// 			height: 1.5rem;
// 			span {
// 				font-size: 0;
// 				line-height: 0;
// 			}
// 		}
// 	}
// }

