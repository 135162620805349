@charset "UTF-8";
// ================================================================================
// List
// リストのスタイル
// ================================================================================

// ----------------------------------------------------------------
// List
// ----------------------------------------------------------------


// Check
// --------------------------------------------------------

.list-check {
	@include reset;
	list-style: none;
}
.list-check__item {
	padding-left: 4.4rem;
	font-size: 1.6rem;
	text-align: left;
	line-height: 1.75;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		padding-left: 4.8rem;
		font-size: 1.8rem;
		line-height: inherit;
	}

	&::before {
		@include ps_icon(-.1rem, auto, auto, 0);
		width: 3.2rem;
		height: 3.2rem;
		border-radius: 100%;
		background: #D71A26 url('../images/common/icon_check.svg') 50% 50% no-repeat;
		background-size: 2rem 1.6rem;
		z-index: 2;
	}

	& + & {
		margin-top: 2rem;

		@media #{$md_devices} {
			margin-top: 3.2rem;
		}
	}

	span {
		font-size: .778em;

		@media #{$sm_devices_only} {
			display: block;
		}
	}
}


// Num
// --------------------------------------------------------

// guideページ基準（デフォルトを設定する場合guideページのcss調整必須）
.list-num {
	@include reset;
	list-style: none;
	counter-reset: item;
}
.list-num__item {
	padding-left: 2.7rem;
	color: $color_dark;
	position: relative;
	z-index: 1;

	&::before {
		@include ps_icon(.2rem, auto, auto, 0);
		counter-increment: item;
 		content: counter(item);
		width: 2rem;
		height: 2rem;
		font-size: 1.3rem;
		font-weight: 600;
		color: $color_default;
		text-align: center;
		line-height: 2rem;
		background-color: $color_dark_thin;
		border-radius: 100%;
	}

	& + & {
		margin-top: 1.6rem;
	}
}