@charset "UTF-8";
// ================================================================================
// Button
// ボタンブロックのスタイル
// ================================================================================

.wp-block-buttons {

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	.wp-block-button {

		.wp-block-button__link {
		}

		// Style Border
		&.is-style-outline {
			.wp-block-button__link {
			}
		}


	}
}

h1, h2, h3, h4, h5, h6 {
	+ .wp-block-buttons {
		margin-top: 0;
	}
}


// for wp-admin
.editor-styles-wrapper {
	.wp-block-buttons.wp-block {

	}
}

