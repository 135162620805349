@charset "UTF-8";
// ================================================================================
// Image
// 画像のスタイル
// ================================================================================

// ----------------------------------------------------------------
// Image
// ----------------------------------------------------------------

.img-box {
	margin: 3.2rem auto;

	@media #{$md_devices} {
		margin: 4.8rem auto;
	}

	& + & {
		margin: 1.6rem auto;

		@media #{$md_devices} {
			margin: 2.4rem auto;
		}
	}
}
.img-box--radius {
	border-radius: 1.2rem;
	overflow: hidden;
}