@charset "UTF-8";
// ================================================================================
// Table
// テーブルブロックのスタイル
// ================================================================================

.wp-block-table {
	margin-right: 0;
	margin-left: 0;

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	&.is-table-many-layers {
		table {
			width: 100%;
			border-spacing: 0;
			border: .1rem solid #CECECE;
			border-radius: .8rem;
			border-collapse: collapse;
			@media #{$md_devices} {
				table-layout: fixed;
			}

			tr:first-child {

				td {
					padding: .8rem;
					font-size: 1.2rem;
					font-weight: 600;
					color: #fff;
					text-align: center;
					background-color: $color_main;
					border-bottom: .1rem solid #CECECE;
					white-space: nowrap;
					@media #{$md_devices} {
						white-space: normal;
					}

					& + th {
						border-left: .1rem solid #CECECE;
					}
				}
			}
			td {
				padding: .8rem;
				font-size: 1.2rem;
				line-height: 1.5;
				border: .1rem solid #CECECE;

				&:first-child {
					font-weight: 700;
					white-space: nowrap;
					@media #{$md_devices} {
						white-space: normal;
					}
				}
			}
		}
	}

}
