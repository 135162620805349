@charset "UTF-8";
// ================================================================================
// Shop
// 店舗のスタイル
// ================================================================================

// ----------------------------------------------------------------
//	General
// ----------------------------------------------------------------


// ----------------------------------------------------------------
//	Archive
// ----------------------------------------------------------------

.shop-archive-map {
	width: 100;
	height: 28rem;
	margin-bottom: 3.2rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: calc( 100% - 12.8rem );
		height: 48rem;
		margin: 0 auto 6.4rem;
		border-radius: 2rem;
	}

	iframe {
		@include ps_icon(-100%, -100%, -100%, -100%);
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}
.shop-archive-cat {
	width: 100%;
	margin-bottom: 4rem;
	display: flex;
	flex-wrap: wrap;
	align-content: center;

	@media #{$md_devices} {
		margin-bottom: 4rem;
	}
}
.shop-archive-cat__item {
	width: calc( ( 100% - 2.2rem ) / 3 );
	margin: 1.4rem 1.1rem 0 0;

	@media #{$sm_devices_only} {
		&:nth-child(3n) {
			margin-right: 0;
		}

		&:nth-child(-n+3) {
			margin-top: 0;
		}

		.button--anchor {
			font-size: 1.3rem;
		}
	}

	@media #{$md_devices} {
		width: calc( ( 100% - 8rem ) / 6 );
		margin: 1.6rem 1.6rem 0 0;

		&:nth-child(6n) {
			margin-right: 0;
		}

		&:nth-child(-n+6) {
			margin-top: 0;
		}
	}
}

.shop-media-link {
	@media #{$md_devices} {
		margin-bottom: 6.4rem;
	}
	.link-text {
		@media #{$sm_devices_only} {
			font-size: 1.5rem;
		}
	}
}

.shop-archive-list {
	width: 100%;
	margin-top: 1.2rem;
	padding: 0 2.4rem;
	border: .2rem solid $color_border;
	border-radius: 1.2rem;

	@media #{$md_devices} {
		margin-top: 1.6rem;
		padding: 0 4rem;
	}
}
.shop-archive-list__item {
	width: 100%;
	padding: 1.2rem 0;

	@media #{$md_devices} {
		padding: 1.7rem 0;
	}

	& + & {
		border-top: .1rem solid $color_border;
	}
}
.shop-archive-list-title {
	width: 100%;
	margin: 0;
	padding-left: 4rem;
	font-size: 1.6rem;
	font-weight: 600;
	font-family: $font_title;
	color: $color_dark;
	line-height: 1.56;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		@include anime_alpha;
		padding-left: 4.8rem;
		font-size: 1.8rem;
		cursor: pointer;

		&:hover {
			@include anime_alpha_hover(70);
		}
	}

	span {
		@include ps_icon(-.2rem, auto, auto, 0);
		width: 2.8rem;
		height: 2.8rem;
		background-color: $color_main;
		border-radius: 100%;
		display: block;
		z-index: 2;

		@media #{$md_devices} {
			width: 3.2rem;
			height: 3.2rem;
		}

		&::before,
		&::after {
			@include ps_icon(0, 0, 0, 0);
			width: 1.4rem;
			height: .2rem;
			background-color: #fff;
			z-index: 1;

			@media #{$md_devices} {
				width: 1.6rem;
			}
		}
		&::after {
			transform: rotate(-90deg);
			opacity: 1;
			@include anime_all;
		}
	}

	&.shop-archive-list-title--open {
		span {
			&::after {
				transform: rotate(0);
				opacity: 0;
			}
		}
	}
}
.shop-archive-list-content {
	width: 100%;
	margin: 2.6rem 0 2rem;

	@media #{$md_devices} {
		margin: 1.9rem 0 0;
		padding-left: 4rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.shop-archive-info {
	padding-right: 0;

	@media #{$md_devices} {
		padding-right: 2.4rem;
		flex: 1;
	}
}
.shop-archive-meta {
	@include reset;
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		align-items: flex-start;
	}

	& + & {
		margin-top: 1.6rem;

		@media #{$md_devices} {
			margin-top: 1rem;
		}
	}
}
.shop-archive-meta__head {
	min-width: 6.4rem;
	padding: .4rem .1rem .5rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: $color_dark;
	text-align: center;
	line-height: 1.454;
	letter-spacing: .053rem;
	background-color: #F0F0F0;
	border-radius: .4rem;
	display: inline-block;
}
.shop-archive-meta__data {
	@include reset;
	margin: .4rem 0 0 0;
	font-size: 1.3rem;
	line-height: 1.857;

	@media #{$md_devices} {
		margin: 0 0 0 1.6rem;
		font-size: 1.4rem;
	}
}

.shop-archive-sub {
	width: 100%;
	margin-top: 2.8rem;
	padding: 0;
	@media #{$md_devices} {
		width: 38.4rem;
		margin-top: 0;
	}
}
.shop-archive-link {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		justify-content: flex-end;
	}
}

.shop-archive-privacy {
	color: $color_moss_gray;
	font-size: 1.1rem;
	line-height: 1.67;
	margin: 1.6rem auto 0;
	@media #{$md_devices} {
		//padding-left: 2.8rem;
	}
}

.shop-archive-link__item {
	width: 100%;

	@media #{$md_devices} {
		width: 18.4rem;
	}

	& + & {
		margin: 2rem 0 0 0;

		@media #{$md_devices} {
			margin: 0 0 0 1.6rem;
		}
	}

	&.is-expand {
		@media #{$md_devices} {
			width: 22.4rem;
		}
	}


	.button--secondary,
	.button--tertiary {
		padding: 1.2rem 2rem;
		font-size: 1.5rem;
		line-height: 1.6;
		letter-spacing: .06rem;
		border-radius: 2.8rem;
	}
}


// ----------------------------------------------------------------
//	Single
// ----------------------------------------------------------------

.shop-single-eyecatch {
	width: 100%;
	margin-bottom: 3.2rem;
	border-radius: 2rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
//	height: 0;
//	padding-top: calc( 976 / 1520 * 100% );

	@media #{$md_devices} {
		margin-bottom: 4rem;
//		padding-top: 0;
//		height: 48.8rem;
	}

	img {
//		@include ps_icon(-100%, -100%, -100%, -100%);
//		height: 100%;
		width: 100%;
		object-fit: cover;
		z-index: 1;

		@media #{$md_devices} {
//			width: auto;
//			height: auto;
//			content: none;
//			max-width: inherit;
//			max-height: 100%;
		}
	}
}

.shop-single-reservation {
	width: 100%;
	margin-bottom: 4.8rem;

	@media #{$md_devices} {
		margin-bottom: 6.4rem;
	}

	.button--tertiary {
		max-width: inherit;
		display: block;
		padding-left: 1.6rem;
		padding-right: 1.6rem;

		@media #{$md_devices} {
			font-size: 1.8rem;
		}
	}

	.button--arrow {

		@media #{$sm_devices_only} {
			&::before,
			&::after {
				content: none;
			}
		}
	}
}

.shop-single-privacy {
	color: $color_moss_gray;
	font-size: 1.2rem;
	line-height: 1.67;
	@media #{$md_devices} {
		font-size: 1.4rem;
	}
}

.shop-single-info {
	width: 100%;

	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;

		tr {
			display: block;
			border-top: .1rem solid $color_border;

			@media #{$md_devices} {
				display: table-row;
				border-bottom: .1rem solid $color_border;
			}

			&:last-child {
				border-bottom: .1rem solid $color_border;
			}

			th {
				padding: 2.4rem 0 0;
				font-size: 1.6rem;
				text-align: left;
				vertical-align: top;
				display: block;

				@media #{$md_devices} {
					width: 12.8rem;
					padding: 2.4rem 1.6rem;
					display: table-cell;
				}
			}
			td {
				margin-top: .8rem;
				padding: 0 0 2.4rem;
				text-align: left;
				vertical-align: top;
				display: block;

				@media #{$md_devices} {
					margin-top: 0;
					padding: 2.4rem 0 2.4rem 1.6rem;
					display: table-cell;
				}

				p {
					margin-bottom: 2rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.shop-single-sns-link {
	width: 100%;
	display: flex;
	align-items: center;
}
.shop-single-sns-link__icon {
	width: 3.54rem;
	height: 3rem;

	img {
		width: 100%;
	}
}
.shop-single-sns-link__text {
	margin-left: 1rem;
}

.shop-single-contact {
	margin-top: 4rem;
	text-align: center;

	.button--primary {}
}