@charset "UTF-8";
// ================================================================================
// Text
// テキストのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Text
// ----------------------------------------------------------------

.text {}
.text--headline {
	margin: 0;
	font-size: 1.6rem;
	font-weight: 600;
	font-family: $font_title;
	color: $color_dark;
	line-height: 2;

	@media #{$md_devices} {
		font-size: 2rem;
	}
}
.text--emphasis {
	margin: 0;
	font-size: 1.4rem;
	font-weight: 600;
	color: $color_dark;
	line-height: 1.5;

	@media #{$md_devices} {
		font-size: 2rem;
	}

	strong {
		margin-top: 1.2rem;
		font-size: 2rem;
		display: block;

		@media #{$md_devices} {
			margin-top: .8rem;
			font-size: 2.2rem;
		}
	}
}
.text--note {
	padding-left: .8em;
	font-size: 1.3rem;
	color: $color_dark;
	display: block;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		font-size: 1.4rem;
	}

	&::before {
		@include icon(-.14em, auto, auto, 0);
		content: '※';
		width: 1em;
		z-index: 1;
	}
}

.text--small {
	font-size: 1.1rem;
	@media #{$md_devices} {
		font-size: 1.3rem;
	}
}


// color
// --------------------------------------------------------

.text--green {
	color: $color_main;
}
.text--dark {
	color: $color_dark;
}
.text--red {
	color: $color_alert;
}