@charset "UTF-8";
// ================================================================================
// Salon
// サロンのスタイル
// ================================================================================

// ----------------------------------------------------------------
//	Common
// ----------------------------------------------------------------

// コンテナ
.salon-container {
	position: relative;
	z-index: 4;
	box-sizing: border-box;
	padding: 2rem 0 0;
	@media #{$md_devices} {
		background-color: $color_bg_primary;
		padding-top: 6rem;
	}
	&::before {
		@include ps_icon(0, 0, auto, 0);
		width: 100%;
		height: 2rem;
		background: url('../images/common/bg_wave_bottom.png') 50% 50% repeat-x;
		background-size: cover;

		@media #{$md_devices} {
			height: 6rem;
		}
	}
}

// CTA ボタン
.salon-cta {
	text-align: center;
}
.salon-cta__title {
	color: $color_default;
	text-align: center;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.875;
	margin: 0 auto 2.4rem;
	display: inline-block;
	position: relative;
	&:before {
		@include ps_icon(.2rem, auto, auto, -1.8rem);
		width: 2px;
		height: 3.2rem;
		border-radius: 2px;
		background: $color_default;
		transform-origin: 50% 50%;
		transform: rotate(-30deg);
	}
	&:after {
		@include ps_icon(.2rem, -1.8rem, auto, auto);
		width: 2px;
		height: 3.2rem;
		border-radius: 2px;
		background: $color_default;
		transform-origin: 50% 50%;
		transform: rotate(30deg);
	}
}
.salon-cta__button {
	text-align: center;
}
.salon-action-button {
	margin: 0 auto;
	background-color: $color_btn_primary;
	box-sizing: border-box;
	padding: 2rem 1.6rem;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.20);
	border-radius: 7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	text-decoration: none;
	font-size: 1.8rem;
	line-height: 1.6666666666666667;
	font-weight: 700;
	width: 100%;
	max-width: 32rem;
	gap: 1rem;
	span {
		background: #fff;
		color: $color_btn_primary;
		font-size: 1.4rem;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: block;
		text-align: center;
		box-sizing: border-box;
		padding: .2rem .6rem;
		border-radius: 4px;
	}
}


// ----------------------------------------------------------------
//	Salon
// ----------------------------------------------------------------


// main
// --------------------------------------------------------------- //

.salon-main {
	max-width: 100% !important;
	width: 100%;
	margin-bottom: 8.2rem;
	@media #{$md_devices} {
		margin-right: 0 !important;
		margin-left: auto !important;
		padding: 0 !important;
		position: relative;
	}
	.salon-main__image {
		position: relative;
		@media #{$md_devices} {
			text-align: right;
		}
		img:not(.salon-main__balloon) {
			position: relative;
			z-index: 1;
			@media #{$md_devices} {
				width: calc(10.4rem + 50%);
				height: 68rem;
				object-fit: cover;
				object-position: left 50%;
			}
		}

		.salon-main__balloon {
			position: absolute;
			z-index: 2;
			top: 1rem;
			left: 13.600000000000001%;
			@media #{$md_devices} {
				left: auto;
				width: 16.8rem;
				top: 5%;
				margin-left: 12%;
			}
		}
	}

	.salon-main_container {
		max-width: 87.2%;
		width: 100%;
		position: relative;
		z-index: 5;
		margin: -8.1rem auto;
		padding: 0 0 8.1rem;
		@media #{$md_devices} {
			margin: 0 auto;
			padding: 0;
			max-width: 100rem;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
	.salon-main__catch {
		margin: 0 0 1.4rem;
		@media #{$md_devices} {
			max-width: 26.8rem;
			margin-bottom: 3.2rem;
		}
		img {
			@media #{$md_devices} {
				width: 100%;
			}
		}
	}
	.salon-main__title {
		@media #{$md_devices} {
			max-width: 48rem;
		}
		img {
			@media #{$md_devices} {
				width: 100%;
			}
		}
	}
	.salon-main__voice-1 {
		position: absolute;
		top: 6.2rem;
		left: 20rem;
		@media #{$md_devices} {
			width: 18.4rem;
			top: 17.2rem;
			left: 24rem;
		}
		img {
			@media #{$md_devices} {
				width: 100%;
			}
		}
	}
	.salon-main__voice-2 {
		position: absolute;
		top: 9.6rem;
		left: 19.7rem;
		@media #{$md_devices} {
			width: 26rem;
			top: 23.6rem;
			left: 30.8rem;
		}
		img {
			@media #{$md_devices} {
				width: 100%;
			}
		}
	}
	.salon-main__description {
		margin: 2rem 0 0;
		@media #{$md_devices} {
			margin-top: 4rem;
			max-width: 62.8rem;
		}
	}

}



// problems
// --------------------------------------------------------------- //

.salon-problems {
	background: $color_bg_light_green;
	box-sizing: border-box;
	padding: 2.6rem 6.4% 8rem;
	position: relative;
	margin: 2.6rem auto 0;
	box-sizing: border-box;
	z-index: 3;
	@media #{$md_devices} {
		padding: 5rem 0 8rem;
		margin: 6rem 0 0;
	}

	&::before {
		@include ps_icon(-2rem, 0, auto, 0);
		width: 100%;
		height: 2rem;
		background: url('../images/common/bg_wave_top.png') 50% 50% repeat-x;
		background-size: cover;

		@media #{$md_devices} {
			height: 6rem;
			top: -6rem;
		}
	}

	.salon-problems__icon {
		@include icon(-3.2rem, 0, auto, 0);
		background: url('../images/salon/icon_problems.svg') no-repeat scroll 0 0;
		background-size: 4.8rem 4.8rem;
		width: 4.8rem;
		height: 4.8rem;
		@media #{$md_devices} {
			background-size: 6.4rem 6.4rem;
			width: 6.4rem;
			height: 6.4rem;
			top: -4.8rem;
		}
	}
}
.salon-problems-container {
	max-width: 76rem;
	margin: 0 auto;
}

.salon-problems-title {
	text-align: left;
	margin-bottom: 2.4rem;
	@media #{$md_devices} {
		text-align: center;
		margin-bottom: 3.2rem;
	}
	img {
		width: 100%;
		max-width: 27.8rem;
		@media #{$md_devices} {
			max-width: 59rem;
		}
	}
}
.salon-problems-list {
	list-style: none;
	margin: 0;
	padding: 0;
	@media #{$md_devices} {
		display: flex;
		flex-wrap: wrap;
	}
	.salon-problems-list__item {
		color: $color_moss_gray;
		font-size: 1.4rem;
		line-height: 1.5714285714285714;
		min-height: 2.4rem;
		background: url(../images/salon/icon_check.svg) no-repeat scroll 0 0;
		font-weight: 700;
		padding: 0 0 0 3.2rem;
		margin-bottom: 1.6rem;
		@media #{$md_devices} {
			margin-top: 2.4rem;
			margin-bottom: 0;
			width: 50%;
			font-size: 1.6rem;
			line-height: 1.5;
		}

		&:nth-child(-n + 2) {
			margin-top: 0;
		}

		&:last-child {
			@media #{$sm_devices_only} {
				margin-bottom: 0;
			}
		}
	}
}



// solve
// --------------------------------------------------------------- //

.salon-solve {
	position: relative;
	margin: -2rem 0 0;
	box-sizing: border-box;
	padding: 0;

	@media #{$md_devices} {
		z-index: 4;
		margin: 0;
		padding-bottom: 8rem;
	}
}
.salon-solve-container {
	@media #{$md_devices} {
		max-width: 100rem;
		margin: 0 auto;
		width: 100%;
	}
}
.salon-solve-tilte {
	box-sizing: border-box;
	position: relative;
	text-align: center;
	padding: 0 6.4%;
	margin-top: -8rem;
	margin-bottom: .8rem;
	@media #{$md_devices} {
		background: none;
		padding: 0;
		margin-top: -11rem;
		margin-bottom: 4.8rem;
	}
	img {
		max-width: 31.2rem;
		width: 100%;
		@media #{$md_devices} {
			max-width: 51.2rem;
		}
	}
}
.salon-solve-image {
	width: 100%;
	@media #{$md_devices} {
		max-width: 48rem;
	}
	img {
		@media #{$md_devices} {
			border-radius: 12px;
		}
	}
}
.salon-solve-lists-wrapper {
	box-sizing: border-box;
	padding: .8rem 6.4% 9.6rem;
	background: $color_bg_primary;
	position: relative;
	margin-top: -2.4rem;
	@media #{$md_devices} {
		background: none;
		padding: .8rem 0;
		margin-top: -26rem;
	}
}
.salon-solve-lists {
	max-width: 62.8rem;
	width: 100%;
	background: #fff;
	box-sizing: border-box;
	border-radius: 12px;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
	list-style: none;
	padding: 0;
	margin: 0 0 4rem;
	@media #{$md_devices} {
		margin-right: 0;
		margin-left: auto;
		margin-bottom: 6.4rem;
	}
}
.salon-solve-list {
	margin: 0;
	position: relative;
	padding: 4rem 2rem 1.6rem;
	&:after {
		@include ps_icon(auto, 0, 0, 0);
		width: calc(100% - 4rem);
		height: 1px;
		background-size: 6px 1px;
		background-image: linear-gradient(to right, #CBB49E, #CBB49E 3px, transparent 3px, transparent 6px);
	}

	&:last-child {
		&:after {
			display: none;
		}
	}
}
.salon-solve-list__number {
	position: absolute;
	top: 1rem;
	left: -1.4rem;
	img {
		@media #{$md_devices} {
			width: 7.2rem;
		}
	}
}
.salon-solve-list__text {
	font-family: $font_title;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.75;
	margin: 0;
	@media #{$md_devices} {
		font-size: 2rem;
	}
	br {
		@media #{$md_devices} {
			display: none;
		}
	}
}



// realize
// --------------------------------------------------------------- //

.salon-realize {
	background: #fff;
	box-sizing: border-box;
	padding: 0;
	@media #{$md_devices} {
		padding: 9.6rem 0;
	}
}
.salon-realize-container {
}
.salon-realize-title {
	position: relative;
	z-index: 5;
	margin: -3.5rem auto 2.8rem;
	text-align: center;
	@media #{$md_devices} {
		margin-top: -3.2rem;
		margin-bottom: 4.8rem;
	}
}
.salon-realize-description {
	box-sizing: border-box;
	margin: 0 auto 3.2rem;
	padding: 0 6.4%;
	max-width: 100rem;
	@media #{$md_devices} {
		padding: 0;
		margin-bottom: 6.4rem;
	}

	strong {
		color: $color_main;
	}
}

.salon-realize-steps {
	max-width: 100rem;
	margin: 0 auto;
}
.salon-realize-step {
	@media #{$md_devices} {
		display: flex;
		gap: 4.8rem;
	}
	&:not(:first-child) {
		margin-top: 4rem;
		@media #{$md_devices} {
			margin-top: 4.8rem;
		}
	}
	.salon-realize-step__image {
		box-sizing: border-box;
		padding-left: 6.4%;
		@media #{$md_devices} {
			padding-left: 0;
			width: 38rem;
		}
		img{
			width: 100%;
			border-radius: 12px 0 0 0;
			@media #{$md_devices} {
				border-radius: 12px;
			}
		}
	}
	.salon-realize-step__container {
		box-sizing: border-box;
		padding: 0 6.4%;
		@media #{$md_devices} {
			padding: 0;
			flex: 1;
		}
	}
	.salon-realize-step__header {
		position: relative;
		margin-top: -4.8rem;
		@media #{$md_devices} {
			margin-top: 0;
		}
	}
	.salon-realize-step__number {
		display: inline-block;
		background-color: #E1454F;
		color: #fff;
		font-family: $font_title;
		font-size: 1.2rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.5;
		padding: .3rem .8rem;
		border-radius: 2px;
		position: absolute;
		z-index: 5;
		top: -1.2rem;
		margin-left: -.4rem;
		@media #{$md_devices} {
			position: relative;
			top: 0;
			margin: 0 0 .8rem;
		}
	}
	.salon-realize-step__title {
		background: #fff;
		display: inline-block;
		font-family: $font_title;
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.5;
		padding: 1.6rem 1.6rem .2rem;
		position: relative;
		@media #{$md_devices} {
			display: block;
			background: none;
			padding: 0;
			font-size: 2.8rem;
		}
	}
	.salon-realize-step__description {
		margin: 1.8rem 0 0;
		@media #{$md_devices} {
			margin-top: 2rem;
		}
	}

	&.is-reverse {
		@media #{$md_devices} {
			flex-direction: row-reverse;
		}
		.salon-realize-step__image {
			padding-right: 6.4%;
			padding-left: 0;
			@media #{$md_devices} {
				padding: 0;
			}
			img {
				border-radius: 0 12px 0 0;
				@media #{$md_devices} {
					border-radius: 12px;
				}
			}
		}
		.salon-realize-step__header {
			@media #{$sm_devices_only} {
				text-align: right;
			}
		}
	}
}



// realize result
// --------------------------------------------------------------- //

.salon-realize-result {
	background: #FFF5EB;
	box-sizing: border-box;
	position: relative;
	margin: 6rem auto 0;
	@media #{$md_devices} {
		margin-top: 0;
	}

	&:before {
		@media #{$md_devices} {
			@include ps_icon(0, 0, auto, 0);
			background: #fff;
			width: 100%;
			height: 33rem;
		}
	}

	.salon-realize-result__arrow {
		position: absolute;
		z-index: 3;
		right: 6.4rem;
		top: -4.1rem;
		@media #{$md_devices} {
			right: auto;
			left: calc(50% + 32rem);
		}

		img {
			@media #{$md_devices} {
				width: 6.4rem;
			}
		}
	}
	.salon-realize-result__image {
		text-align: center;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		@media #{$md_devices} {
			max-width: 100rem;
		}
		img {
			width: 100%;
			@media #{$md_devices} {
				border-radius: 12px;
			}
		}
	}
	.salon-realize-result__container {
		border-radius: 12px;
		background: rgba(255, 255, 255, 0.80);
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
		position: relative;
		z-index: 2;

		box-sizing: border-box;
		width: calc(100% - 12.8%);
		padding: 2.8rem 1.6rem 2.4rem;
		margin: -6.4rem auto 4rem;
		@media #{$md_devices} {
			padding: 4rem 6.4rem;
			width: 100%;
			max-width: 76rem;
			margin-top: -16rem;
			margin-bottom: 4.8rem;
		}
	}
	.salon-realize-result__title {
		margin: 0 auto 2.2rem;
		text-align: center;
		@media #{$md_devices} {
			margin-bottom: 3.2rem;
		}
		img {
			@media #{$md_devices} {
				width: 56rem;
			}
		}
	}
	.salon-realize-result__description {
		color: #3F3F3F;
		font-size: 1.3rem;
		font-style: normal;
		font-weight: 400;
		line-height: 2;
		@media #{$md_devices} {
			font-size: 1.6rem;
			line-height: 1.875;
			text-align: center;
		}
	}
	.salon-cta {
		padding: 0 0 8.4rem;
	}
}





// voice
// --------------------------------------------------------------- //

.salon-voice {
	background-color: $color_bg_primary;
	box-sizing: border-box;
	padding: 0 6.4% 25.1rem;
	@media #{$md_devices} {
		padding: 0 0 35rem;
	}
}
.salon-voice-container {
	max-width: 100rem;
	margin: 0 auto;
}
.salon-voice-title {
	text-align: center;
	margin: 0 auto 3.2rem;
	@media #{$md_devices} {
		margin-bottom: 6rem;
	}
}

.salon-voice-items {
	display: flex;
	gap: 1.6rem;
	flex-direction: column;
	@media #{$md_devices} {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		gap: 3.2rem 2%;
	}
}
.salon-voice-item {
	background: #fff;
	box-sizing: border-box;
	border: 2px solid #C1CCCD;
	position: relative;
	border-radius: 12px;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2;
	padding: 2.4rem 2rem;
	@media #{$md_devices} {
		width: 32%;
		font-size: 1.6rem;
		line-height: 1.875;
		padding: 3.2rem 2.4rem;
	}

	&:before {
		@include ps_icon(-.8rem, auto, auto, .8rem);
		background: url('../images/salon/icon_voice.svg') no-repeat scroll 0 0;
		width: 2.4rem;
		height: 2.4rem;
	}
	&:after {
		@include ps_icon(auto, .8rem, -.8rem, auto);
		background: url('../images/salon/icon_voice.svg') no-repeat scroll 0 0;
		width: 2.4rem;
		height: 2.4rem;
		transform: rotate(180deg);
		transform-origin: 50% 50%;
	}
}



// divider
// --------------------------------------------------------------- //

.salon-divider {
	margin-top: -18.6rem;
	position: relative;
	z-index: 10;
	margin-left: auto;
	margin-right: auto;
	@media #{$md_devices} {
		max-width: 100rem;
		margin-top: -25.6rem;
	}
	img {
		width: 100%;
	}
}




// flow
// --------------------------------------------------------------- //

.salon-flow {
	margin: 6.4rem auto 8rem;
	@media #{$sm_devices_only} {
		max-width: 100% !important;
	}
	@media #{$md_devices} {
		margin-top: 9.6rem;
		margin-bottom: 9.6rem;
	}
}
.salon-flow__title {
	font-family: $font_title;
	color: $color_default;
	text-align: center;
	font-size: 2.8rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
	margin: 0 auto 4rem;
	@media #{$md_devices} {
		margin-bottom: 7.2rem;
		font-size: 4.2rem;
	}
}
.salon-flow-item {
	background-color: #F4F7F8;
	box-sizing: border-box;
	padding: 4.8rem 6.4% 2.4rem;
	position: relative;
	@media #{$md_devices} {
		padding: 5.6rem 4rem 4.6rem;
		border-radius: 12px;
		max-width: 76rem;
		margin-left: auto;
		margin-right: auto;
	}

	&:not(:first-child) {
		margin-top: 4.8rem;
		@media #{$md_devices} {
			margin-top: 5.8rem;
		}
	}

	&:not(:last-child) {
		&:after {
			@include ps_icon(auto, 0, -1.2rem, 0);
			background: url(../images/salon/arrow_flow.svg) no-repeat scroll 0 0;
			background-size: 2.4rem 2.4rem;
			width: 2.4rem;
			height: 2.4rem;
			@media #{$md_devices} {
				background-size: 4rem 4rem;
				width: 4rem;
				height: 4rem;
				bottom: -2rem;
			}
		}
	}
}
.salon-flow-item__number {
	background: $color_main;
	box-sizing: border-box;
	width: 5.6rem;
	height: 5.6rem;
	border-radius: 6.4rem;
	color: #fff;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	top: -2.8rem;
	left: 50%;
	transform: translate(-50%, 0);
	@media #{$md_devices} {
		width: 6.4rem;
		height: 6.4rem;
		top: -3.2rem;
	}
	span {
		display: block;
		text-align: center;
		font-family: $font_english;
		font-size: 1.5rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1;
		letter-spacing: 0.03333333333333333em;
	}
	strong {
		box-sizing: border-box;
		padding-right: .6rem;
		display: block;
		text-align: center;
		font-family: $font_english;
		font-size: 3.5rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1;
		letter-spacing: 0.015000000000000001em;
	}
}
.salon-flow-item__description {
	margin: 0;
	text-align: center;
}
.salon-flow-item__button {
	margin-top: 2.4rem;
}
.salon-flow-item__list {
	text-align: left;
	li {
		line-height: 1.5;
		&:not(:first-child) {
			margin-top: 2.4rem;
		}
	}

	span {
		color: $color_moss_gray;
		font-size: 1.2rem;
	}
}
.salon-flow-item-note {
	background: #fff;
	box-sizing: border-box;
	border-radius: 12px;
	border: 1px solid $color_border;
	box-sizing: border-box;
	text-align: center;
	padding: 2rem;
	margin: 2.4rem auto 0;
	@media #{$md_devices} {
		margin-top: 3.2rem;
		padding: 2.4rem;
	}
}
.salon-flow-item-note__title {
	color: $color_main;
	font-weight: 700;
	font-size: 1.6rem;
	margin: 0 auto 1.6rem;
	@media #{$md_devices} {
		font-size: 1.8rem;
		margin-bottom: 1.2rem;
	}
}
.salon-flow-item-note__description {
	margin: 0;
}
.salon-flow-item-note__time {
	margin: .8rem auto 0;
	@media #{$md_devices} {
		margin-top: 1.6rem;
	}

}



// faq
// --------------------------------------------------------------- //

.salon-faq {
	margin: 8rem auto;
	@media #{$md_devices} {
		margin-top: 6.4rem;
		margin-bottom: 9.6rem;
	}
}
.salon-faq__title {
	font-family: $font_title;
	color: $color_default;
	text-align: center;
	font-size: 2.8rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5;
	margin: 0 auto 2.4rem;
	@media #{$md_devices} {
		margin-bottom: 4rem;
		font-size: 4.2rem;
	}
}
.salon-faq-item {
	margin: 0 auto 3.2rem;
	@media #{$md_devices} {
		margin-bottom: 5.6rem;
	}
}
.salon-faq-item__title {
	font-size: 1.4rem;
	line-height: 2.142857142857143;
	font-weight: 700;
	color: $color_main;
	box-sizing: border-box;
	padding: 0 0 0 4rem;
	background: url(../images/salon/icon_faq_question.svg) no-repeat scroll 0 0;
	background-size: 2.8rem 2.8rem;
	@media #{$md_devices} {
		font-size: 2.1rem;
		line-height: 1.4285714285714286;
		padding: .5rem 0 .5rem 6.4rem;
		background-size: 4rem 4rem;
	}
}
.salon-faq-item__content {
	margin: 1.2rem auto 0;
	box-sizing: border-box;
	padding: 0 0 0 4rem;
	background: url(../images/salon/icon_faq_answer.svg) no-repeat scroll 0 0;
	background-size: 2.8rem 2.8rem;
	min-height: 2.8rem;
	@media #{$md_devices} {
		margin-top: 1.6rem;
		padding: .5rem 0 .5rem 6.4rem;
		background-size: 4rem 4rem;

	}
	p {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.salon-more {
	margin: 3.2rem auto 0;
	@media #{$md_devices} {
		margin-top: 6.4rem;
	}
}
.salon-more__button {
	box-sizing: border-box;
	margin: 0 auto;
	display: block;
	padding: 1.6rem;
	border-radius: 6.4rem;
	max-width: 30.4rem;
	width: 100%;
	text-align: center;
	color: $color_link;
	border: 1px solid $color_link;
	text-decoration: none;
	font-size: 1.4rem;
	line-height: 2;
	font-weight: 700;
	@media #{$md_devices} {
		font-size: 1.6rem;
	}

}



// fixed button
// --------------------------------------------------------------- //

.salon-fixed-cta {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100% !important;
	max-width: 100% !important;
	z-index: 9999;
	background: rgba(255, 255, 255, .3);
	box-sizing: border-box;
	padding: 1rem 1.6rem;
	@media #{$md_devices} {
		padding: 1.6rem;
	}

	.salon-cta__button {
		.salon-action-button {
			font-size: 1.6rem;
			padding: 1.6rem 1.6rem;
			@media #{$md_devices} {
				padding: 2rem 1.6rem;
			}
			span {
				font-size: 1.2rem;
			}
		}
	}
}

