@charset "UTF-8";
// ================================================================================
// Elements
// HTMLタグのみのエレメントを記載
// ※クラス付与は原則行わない。あくまで直接セレクターに適用できるスタイルのみを記述します。
// ================================================================================

// ----------------------------------------------------------------
// Global
// ----------------------------------------------------------------

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	width: 100%;
	height: 100%;
	// scroll-behavior: smooth;
}

body {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	-webkit-text-size-adjust: 100%;
	-webkit-print-color-adjust: exact;
	color: $color_default;
	word-wrap: break-word;
	position: relative;
	font-family: $font;
	line-height: 1.875;
	font-weight: 400;
	letter-spacing: .04em;
	font-size: 1.4rem;
	@media #{$md_devices} {
		font-size: 1.6rem;
	}
}


// ----------------------------------------------------------------
// Links
// ----------------------------------------------------------------

a {
	cursor: pointer;
	color: $color_link;
	text-underline-offset: 2px;
	text-decoration-skip-ink: all;

	@media #{$md_devices} {
		@include anime_alpha;
	}
}

a:hover {
	text-decoration: none;
	text-decoration-skip-ink: none;

	@media #{$md_devices} {
		@include anime_alpha_hover(70);
	}
}

// tel link
a[href*="tel:"] {

	@media #{$md_devices} {
		color: $color_default;
		text-decoration: none;
		pointer-events: none;
		cursor: default;
	}
}


// ----------------------------------------------------------------
// Image
// ----------------------------------------------------------------

img {
	vertical-align: bottom;
	-ms-interpolation-mode: bicubic;
	max-width: 100%;
	height: auto;
}