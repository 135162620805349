@charset "UTF-8";
// ================================================================================
// Header
// ヘッダーのスタイル
// ================================================================================

// ----------------------------------------------------------------
// header
// ----------------------------------------------------------------

.site-header {
	width: 100%;
	height: $header_height;
	padding: 0 0 0 2rem;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;

	@media #{$md_devices} {
		min-width: $breakpoint_lg;
		height: $header_height_md;
		padding: 0 4.8rem 0 2.4rem;
	}

	&.has-animation {
		transition: transform .2s ease-in-out;
	}

	&.is-hidden {
		transform: translateY(-100%);
	}

}
.site-branding {}
.site-logo {
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: 600;

	img {
		width: 6.4rem;
		height: 4.8rem;

		@media #{$md_devices} {
			width: 10.1rem;
			height: 7.6rem;
		}
	}
}
.site-catch {
	margin-left: 1.2rem;
	font-family: $font_title;
	font-size: 1rem;
	color: $color_dark;
	line-height: 1.58;
	letter-spacing: .04rem;


	@media #{$md_devices} {
		margin-left: 1.75rem;
		font-size: 1.2rem;
	}
}


// navigation
// --------------------------------------------------------

.site-navigation {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	overflow: hidden;
	transition: all .4s ease-in-out;

	.site-header--active & {
		visibility: visible;
		opacity: 1;
	}

	@media #{$md_devices} {
		height: auto;
		position: static;
		visibility: visible;
		opacity: 1;
		flex: 1;
	}
}
.site-navigation-bg {
	width: 100%;
	height: 100%;
	background-color: rgba($color_dark_thin, .2);
	visibility: hidden;
	opacity: 0;
	@include anime_alpha;

	.site-header--active & {
		visibility: visible;
		opacity: 1;
	}

	@media #{$md_devices} {
		display: none;
	}
}
.site-menu-container {}

.site-menu-list {
	width: calc(100% - 7.2rem);
	height: 100vh;
	margin: 0;
	padding: 5.6rem 0;
	background: #fff;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	list-style: none;
	transform: translateX(100%);
	transition: transform .2s ease-in-out .15s;

	.site-header--active & {
		transform: translateX(0);
	}

	@media #{$md_devices} {
		width: auto;
		height: 100%;
		padding: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: static;
		overflow-y: inherit;
		transform: inherit;
	}
}
.site-menu-item {
	padding: 0 2rem 0 4rem;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.357;

	@media #{$md_devices} {
		margin: 0 1.6rem 0 0;
		padding: 0;
		font-size: 1.3rem;
		text-align: center;

		&:last-child {
			margin-right: 0;
		}
	}

	@media #{$xl_devices} {
		margin: 0 2.4rem 0 0;
		font-size: 1.4rem;
	}

	& + & {
		margin-top: 3.2rem;

		@media #{$md_devices} {
			margin-top: 0;
		}
	}
}
.site-menu-item__link {
	color: $color_default;
	text-decoration: none;

	@media #{$md_devices} {
		display: block;
	}
}
.site-menu-item__sub {
	margin-top: .4rem;
	font-size: 1.2rem;
	font-weight: 500;
	color: $color_main;
	display: block;

	@media #{$md_devices} {
		font-size: 1rem;
		font-weight: 500;
		color: $color_dark_thin;
	}
}


/* SP MENU
----------------------- */
.menu-button-container {
	height: 100%;
	display: flex;
	transition: all .2s ease-in-out;

	.site-header--active & {
		position: relative;
		z-index: 2;
	}

	@media #{$md_devices} {
		display: none;
	}
}
.menu-button {
	width: 6rem;
	height: 100%;
	color: $color_default;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}
.menu-button--shop {
	transition: all .2 s ease-in-out .15s;

	.site-header--active & {
		visibility: hidden;
		opacity: 0;
	}
}
.menu-button__text {
	margin-top: .6rem;
	font-size: .8rem;
	font-weight: 600;
	transition: all .2s ease-in-out .15s;

	.site-header--active & {
		visibility: hidden;
		opacity: 0;
	}
}

.menu-shop-icon {
	width: 2.4rem;
	height: 2.1rem;
	display: block;
}
.menu-shop-icon__img {
	width: 100%;
	height: 100%;
	display: block;
	background: url('../images/common/icon_menu_shop.svg') 50% 50% no-repeat;
	background-size: contain;
}

.menu-icon {
	width: 2.4rem;
	height: 2.1rem;
	display: block;
	position: relative;
	z-index: 1;
}
.menu-icon__line {
	@include icon();
	width: 2rem;
	height: .2rem;
	background-color: $color_main;
	@include anime_icon;


	&:nth-child(1) {
		transform: translateY(-.4rem);

		.site-header--active & {
			transform: translateY(0) rotate(45deg);
		}
	}
	&:nth-child(2) {
		transform: translateY(.4rem);

		.site-header--active & {
			transform: translateY(0) rotate(-45deg);
		}
	}
}