@charset "UTF-8";
// ================================================================================
// Footer
// フッターのスタイル
// ================================================================================

// ----------------------------------------------------------------
// banner
// ----------------------------------------------------------------

.footer-banner {
	text-align: center;
	margin: 2.4rem auto;
	width: calc( 100% - 6.4rem);
	@media #{$md_devices} {
		max-width: 75.8rem;
		margin-top: 7.2rem;
	}
}


// ----------------------------------------------------------------
// footer
// ----------------------------------------------------------------

.site-footer {
	width: 100%;
	margin-top: 6.4rem;
	background-color: $color_bg_gray;
	position: relative;
	z-index: 100;

	@media #{$md_devices} {
		margin-top: 14.4rem;
	}
}
.site-footer-content {
	width: 100%;
	padding: 4.8rem 0;

	@media #{$md_devices} {
		display: flex;
		justify-content: space-between;
	}
}

.site-footer-content__info {}
.site-footer-logo {
	color: $color_default;
	text-decoration: none;
	display: block;
	text-align: center;

	@media #{$md_devices} {
		display: flex;
		align-items: center;
	}

	img {
		 width: 9.4rem;
		 height: 9.6rem;

		 @media #{$md_devices} {
			width: 12.8rem;
		 	height: 13rem;
		}
	}

}
.site-footer-catch {
	margin: 1.2rem 0 0 0;
	font-size: 1.4rem;
	font-family: $font_title;
	font-weight: 600;
	line-height: 1.5;
	display: block;

	@media #{$md_devices} {
		margin: 0 0 0 2.4rem;
		font-size: 1.6rem;
		text-align: left;
	}
}


// navigation
// --------------------------------------------------------

.site-footer-content__nav {
	margin-top: 3.2rem;
	padding: 0 1.6rem;
	display: block;

	@media #{$md_devices} {
		margin-top: 0;
		padding: 0;
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}
}

.footer-menu-list {
	@include reset;
	list-style: none;

	& + & {

		@media #{$sm_devices_only} {
			border-top: .1rem solid $color_border_thin;
		}

		@media #{$md_devices} {
			margin-left: 5.6rem;
		}
	}

	&:last-child {

		@media #{$sm_devices_only} {
			border-bottom: .1rem solid $color_border_thin;
		}
	}
}
.footer-menu-item {
	margin-bottom: 0;
	font-size: 1.4rem;
	font-weight: 600;

	& + & {

		@media #{$sm_devices_only} {
			border-top: .1rem solid $color_border_thin;
		}

		@media #{$md_devices} {
			margin-top: 2.4rem;
		}
	}

	&.has-children {

		@media #{$sm_devices_only} {
			position: relative;
			z-index: 1;

			& > .footer-menu-item__link {
				padding-right: 3.2rem;
			}

			& > span {
				@include ps_icon(1.05rem, 0, auto, auto);
				width: 2.4rem;
				height: 2.4rem;
				border: .2rem solid $color_default;
				border-radius: 100%;
				z-index: -1;

				&::before,
				&::after {
					@include ps_icon(0,0,0,0);
					width: 1.2rem;
					height: .2rem;
					background-color: $color_default;
				}
				&::after {
					transform: rotate(-90deg);
					opacity: 1;
					@include anime_all;
				}
			}

			&.footer-menu-item--open > span::after {
				transform: rotate(0);
				opacity: 0;
			}
		}
	}
}
.footer-menu-item__link {
	padding: 1.3rem 0;
	color: $color_default;
	text-decoration: none;
	display: block;

	@media #{$sm_devices_only} {
		line-height: 1.428;
	}

	@media #{$md_devices} {
		padding: 0;
	}
}
.footer-menu-item__sub {
	margin-top: .3rem;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	display: block;

	@media #{$md_devices} {
		margin-top: .4rem;
		font-size: 1.1rem;
	}
}
.footer-menu-child {
	@include reset;
	list-style: none;

	@media #{$sm_devices_only} {
		margin-bottom: .9rem;
		display: none;
	}

	@media #{$md_devices} {
		margin-top: .8rem;
	}

	.footer-menu-item {
		padding-left: 1.6rem;
		font-size: 1.3rem;
		font-weight: 400;
		position: relative;
		z-index: 1;

		@media #{$md_devices} {
			margin-top: .8rem;
		}

		&::before {
			@include ps_icon(1.45rem, auto, auto, 0);
			width: .8rem;
			height: .1rem;
			background-color: $color_default;

			@media #{$md_devices} {
				top: 1.1rem;
			}
		}

		& + .footer-menu-item {

			@media #{$sm_devices_only} {
				border: none;
			}

			@media #{$md_devices} {
				margin-top: .4rem;
			}
		}
	}

	.footer-menu-item__link {

		@media #{$sm_devices_only} {
			padding: .6rem 0;
		}
	}
}



// copyright
// --------------------------------------------------------

.site-copyright {
	width: 100%;
	margin: 0;
	padding: 1.5rem 1rem;
	font-size: 1rem;
	font-weight: 600;
	color: #fff;
	text-align: center;
	background-color: $color_main;

	@media #{$md_devices} {
		font-size: 1.2rem;
	}
}