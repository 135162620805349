@charset "UTF-8";
// ================================================================================
// Media Text
// メディアと文章ブロックのスタイル
// ================================================================================

.wp-block-media-text {
	margin-top: 4.8rem;
	margin-bottom: 4.8rem;
	@media #{$md_devices} {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
	}

	&.alignwide {
	}
	&.alignfull {
		margin-top: 6.4rem;
		margin-bottom: 6.4rem;
		@media #{$md_devices} {
			margin-top: 9.6rem;
			margin-bottom: 9.6rem;
		}
	}


	// NOTE: コンテンツ左右スペースを上書き
	.wp-block-media-text__content {
		padding: 0 8.5%;
		@media #{$md_devices} {
			padding: 0 8%;
		}
	}


	// NOTE: 左右幅の打ち消しの調整
	&:not(.alignwide):not(.alignfull):not(.has-media-on-the-right) {
		.wp-block-media-text__content {
			padding-right: 0;
		}
	}
	&.has-media-on-the-right:not(.alignwide):not(.alignfull) {
		.wp-block-media-text__content {
			padding-left: 0;
		}
	}
	&.alignwide:not(.alignfull):not(.has-media-on-the-right) {
		.wp-block-media-text__content {
			@media #{$md_devices} {
				padding-right: 0;
			}
		}
	}
	&.has-media-on-the-right.alignwide:not(.alignfull) {
		.wp-block-media-text__content {
			@media #{$md_devices} {
				padding-left: 0;
			}
		}
	}


	// NOTE: SP の縦の場合はコンテンツに上スペースを持つ
	&.is-stacked-on-mobile {
		.wp-block-media-text__content {
			@media (max-width:600px) {
				margin-top: 2.4rem;
			}
		}
	}

	// NOTE: SP の縦の場合で逆方向でも順序を入れ替えない
	&.has-media-on-the-right.is-stacked-on-mobile {
		.wp-block-media-text__media {
			@media (max-width:600px) {
				grid-row: 1 !important;
			}
		}
		.wp-block-media-text__content {
			@media (max-width:600px) {
				grid-row: 2 !important;
			}
		}
	}

	// NOTE: SP の縦の場合はコンテンツの左右幅を打ち消す
	&.is-stacked-on-mobile:not(.alignwide):not(.alignfull) {
		.wp-block-media-text__content {
			@media (max-width:600px) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&:first-child {
		margin-top: 0;
	}
}

h1, h2, h3, h4, h5, h6 {
	+ .wp-block-media-text {
		margin-top: 0;
	}
}

