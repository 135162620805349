@charset "UTF-8";
// ================================================================================
// Variable
// NOTE:グローバルな変数を設定。それ以外はそのscssファイルのみで完結。

// ----------------------------------------------------------------
// Responsive
// ----------------------------------------------------------------

/*
	Xtra Small Device : 320pxまで
	Small Device : 960pxまで
	Middle Device : 961pxから
	Large Device : 1200pxから
	Xtra Large Device : 1400pxから
*/

// NOTE: CSS Variables と一部重複
//$breakpoint_xs: 320px;
$breakpoint_sm: 320px;
$breakpoint_md: 768px;
// $breakpoint_md: 961px;
$breakpoint_lg: 1200px;
$breakpoint_xl: 1400px;

// iPhone SE サイズ
$xs_devices_only: "only screen and ( max-width: #{$breakpoint_sm} )";

// モバイルまで
$sm_devices_only: "only screen and ( max-width: #{$breakpoint_md - 1} )";

// タブレット以上
$md_devices: "print, only screen and ( min-width: #{$breakpoint_md} )";

// 1200~
$lg_devices: "print, only screen and ( min-width: #{$breakpoint_lg} )";

// 1400~
$xl_devices: "print, only screen and ( min-width: #{$breakpoint_xl} )";



// ----------------------------------------------------------------
// Font Family
// ----------------------------------------------------------------

// ベースのフォントファミリー
$font: 'Noto Sans Japanese', 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;

// タイトルまわりのフォントファミリー
$font_title: dnp-shuei-mgothic-std, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;

// 英字のベースフォントファミリー
$font_english: 'Caveat', cursive, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;

// 英字の特殊フォント
$font_english_secondary: 'Josefin Sans', cursive, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;


// ----------------------------------------------------------------
// Color
// ----------------------------------------------------------------

// NOTE: CSS Variables を継承
$color_default: #3F3F3F;
$color_link: #306FBB;
$color_dark: #2F2F2F;
$color_dark_thin: #666;
$color_moss_gray: #6A7E80;
$color_main: #00693E;
$color_sub: #945E2A;
$color_blue: #306FBB;
$color_bg_primary: #FFF5EB;
$color_bg_secondary: #E9F8F2;
$color_bg_tertiary: #F0F5FB;
$color_bg_gray: #FAFAFA;
$color_bg_light_green: #E6EDED;
$color_border: #DEDEDE;
$color_border_thin: #EFEFEF;
$color_alert: #D71A26;
$color_white: #FFF;
$color_btn_default: #F6F6F6;
$color_btn_primary: #D71A26;
$color_btn_secondary: #306FBB;
$color_btn_tertiary: #00693E;
$color_pink: #E66A72;
$color_orange: #D57D4A;


// ----------------------------------------------------------------
// Font Size
// ----------------------------------------------------------------

// フォントサイズ
// NOTE: CSS Variables を継承
//$fz_2xs: var(--global--font-size-2xs); // 10 / 11
//$fz_xs: var(--global--font-size-xs); // 11 / 13
//$fz_sm: var(--global--font-size-sm); // 12 / 14
//$fz_md: var(--global--font-size-md); // 14 / 16
//$fz_lg: var(--global--font-size-lg); // 16 / 18
//$fz_xl: var(--global--font-size-xl); // 18 / 21
//$fz_2xl: var(--global--font-size-2xl); // 22 / 26
//$fz_3xl: var(--global--font-size-3xl); // 28 / 32

// スペース
$paragraph_space: 1.88em;



// ----------------------------------------------------------------
// Scale, Size
// ----------------------------------------------------------------

// header
$header_height: 6.8rem;
$header_height_md: 10rem;

// button
$button_width_default: 29.6rem;


