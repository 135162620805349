@charset "UTF-8";
// ================================================================================
// Front
// フロントページのスタイル
// ================================================================================

// ----------------------------------------------------------------
// Main Visual
// ----------------------------------------------------------------

.front-main {
	width: 100%;
	padding: 0 1.6rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		padding: 2.8rem 4rem 0 0;
	}
}
.front-main__bg {
	width: 100%;
	margin: 0 auto;
	height: 0;
	padding-top: calc( 224 / 343 * 100% );
	border-radius: 1.6rem;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		width: 54.35%;
		margin: 0 0 0 auto;
		padding-top: calc( 656 / 1560 * 100% );
		border-radius: 2.4rem;
	}

	&::before {
		@include ps_icon(1.2rem, auto, auto, 1.2rem);
		width: 6.4rem;
		height: 6.4rem;
		background: url('../images/common/logo_mark.png') 50% 50% no-repeat;
		background-size: cover;
		z-index: 2;

		@media #{$md_devices} {
			width: 8.8rem;
			height: 8.8rem;
			top: 2.4rem;
			left: 2.4rem;
		}
	}

	img {
		@include ps_icon(50%, auto, auto, 50%);
		content: none;
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		top: 50%;
		left: 50%;
		right: auto;
		bottom: auto;
		transform: translate(-50%, -50%);
	}
}
.front-main__inner {
	width: 100%;
	margin: -3.4rem auto 0;
	padding: 0 1.6rem;
	text-align: center;
	position: relative;
	z-index: 2;

	@media #{$md_devices} {
		width: calc( 100% - 8rem );
		max-width: 136rem;
		margin: 2.8rem auto 0;
		padding: 0;
		text-align: left;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
	}
}
.front-main-logo {
	width: 25.6rem;
	margin: 0 auto 1.6rem;
	padding: 1.3rem 2.4rem .7rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $color_main;

	@media #{$md_devices} {
		width: 49.2rem;
		margin: 0 0 5.6rem;
		padding: 2.7rem 4rem 1.3rem;
	}
}
.front-main-logo__main {
	line-height: 1;

	img {
		width: 20.5rem;

		@media #{$md_devices} {
			width: 41rem;
		}
	}
}
.front-main-logo__sub {
	margin: .9rem auto 0;
	line-height: 1;

	@media #{$md_devices} {
		margin: 1.7rem .7rem 0 auto;
	}

	img {
		width: 8.4rem;

		@media #{$md_devices} {
			width: 16.8rem;
		}
	}
}
.front-main-copy {
	margin: 0;
	font-size: 2.2rem;
	font-weight: 600;
	font-family: $font_title;
	line-height: 1.5;
	color: $color_dark;

	@media #{$md_devices} {
		font-size: 4.6rem;
		line-height: 1;
	}

	span {
		margin: 0 0 1.2rem 0;
		font-size: 1.8rem;
		color: $color_main;
		display: block;

		@media #{$md_devices} {
			margin: 0 0 3.4rem 0;
			font-size: 3.4rem;
			font-weight: 500;
		}
	}
}
.front-main-button {
	max-width: 24rem;
	margin: 1.6rem auto 0;

	@media #{$md_devices} {
		display: none;
	}
}



// ----------------------------------------------------------------
// Intro
// ----------------------------------------------------------------

.front-intro {
	padding: 5.6rem 0 6.8rem;
	overflow: hidden;

	@media #{$md_devices} {
		padding: 21.2rem 0 19rem;
	}

	.headline-01 {
		text-align: center;

		@media #{$md_devices} {
			text-align: left;
		}
	}
}
.front-intro-content {
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		max-width: 76.1%;
		margin: 0 auto 0 0;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
	}
}
.front-intro-content__img {
	width: 100%;
	margin-top: 1.3rem;

	@media #{$md_devices} {
		width: 69.4%;
		max-width: 52.8rem;
		margin-top: 0;
		position: absolute;
		top: 50%;
		right: -52%;
		z-index: -1;
		transform: translateY(-50%);
	}
}
.front-intro-content__description {
	margin-top: 3.4rem;

	@media #{$md_devices} {
		margin-top: 4rem;
	}
}
.front-intro-content-button {
	width: 100%;
	margin-top: 4rem;

	@media #{$md_devices} {
		margin-top: 5.6rem;
		display: flex;
		align-items: center;
	}
}
.front-intro-content-button__item {
	width: 100%;

	@media #{$md_devices} {
		width: 29.6rem;
	}

	& + & {
		margin: 2.4rem 0 0 0;

		@media #{$md_devices} {
			margin: 0 0 0 2.4rem;
		}
	}
}

.front-intro-banner {
	text-align: center;
	margin-top: 3.2rem;
	@media #{$md_devices} {
		margin: 9.6rem auto 0;
		max-width: 75.8rem;
	}
}


// ----------------------------------------------------------------
// Problem
// ----------------------------------------------------------------

.front-problem {
	margin: 2rem 0;
	background-color: $color_bg_light_green;
	z-index: 2;

	@media #{$md_devices} {
		margin: 6rem 0;
	}

	&::before {
		@include ps_icon(-2rem, 0, auto, 0);
		width: 100%;
		height: 2rem;
		background: url('../images/common/bg_wave_top.png') 50% 50% repeat-x;
		background-size: cover;

		@media #{$md_devices} {
			height: 6rem;
			top: -6rem;
		}
	}
	&::after {
		@include ps_icon(auto, 0, -2rem, 0);
		width: 100%;
		height: 2rem;
		background: url('../images/common/bg_wave_bottom.png') 50% 50% repeat-x;
		background-size: cover;

		@media #{$md_devices} {
			height: 6rem;
			bottom: -6rem;
		}
	}
}

.front-problem-content {
	padding: 6.4rem 0;

	@media #{$md_devices} {
		padding: 8rem 0;
	}

	.headline-01 {
		text-align: center;
	}
}
.front-problem-list {
	margin-top: 1.6rem;
	display: flex;
	justify-content: space-around;
	gap: 0 2%;
	align-items: end;

	@media #{$md_devices} {
		margin-top: 11.3rem;
		gap: 0;
	}
}
.front-problem-list__item {
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 1;
	width: 32%;

	@media #{$md_devices} {
		width: calc( ( 100% - 20.8rem ) / 3 );
	}

	.babble {

		@media #{$md_devices} {
			position: absolute;
			top: auto;
			left: 2rem;
			bottom: 18.5rem;
			z-index: 2;
		}
	}

	& + & {
		// @media #{$sm_devices_only} {
		// 	margin-top: 3.2rem;
		// }
	}
}
.front-problem-thumb {
	width: 84.4%;
	max-width: 40rem;
	margin: -.6rem auto 0;
	border-radius: 100%;
	overflow: hidden;

	@media #{$md_devices} {
		width: 100%;
		max-width: 20rem;
		margin: 0 auto;
	}
}
.front-problem-link {
	margin-top: 3.2rem;
	text-align: center;

	@media #{$md_devices} {
		margin-top: 4.8rem;
	}

	.link-text {
		font-size: 1.6rem;
		font-weight: bold;

		@media #{$md_devices} {
			font-size: 1.8rem;
		}
	}
}


// ----------------------------------------------------------------
// Lineup
// ----------------------------------------------------------------

.front-lineup {
	margin-top: -2rem;
	padding: 6.4rem 0;
	background: url('../images/front/bg_front_lineup.png') 50% 50% no-repeat;
	background-size: cover;

	@media #{$md_devices} {
		margin-top: -6rem;
		padding: 15.2rem 0 11.2rem;
	}
}
.front-lineup-heading {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		align-items: flex-end;
	}
}
.front-lineup-heading__img {
	width: 13rem;
	margin: 0 auto 2.4rem;

	@media #{$md_devices} {
		width: 24rem;
		margin: 0 4rem 0 0;
	}
}
.front-lineup-heading__head {
	text-align: center;

	@media #{$md_devices} {
		text-align: left;
		flex: 1;
	}
}
.front-lineup-heading-text {
	margin: 2.4rem 0 0 0;
	text-align: left;
}
.front-lineup-value {
	margin-top: 2.4rem;

	@media #{$md_devices} {
		margin-top: 5.8rem;
		padding: 0 6rem;
	}
}
.front-lineup-value__item {
	width: 100%;
	color: $color_default;
	text-decoration: none;
	background-color: #fff;
	border-radius: 1.2rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .15);
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		min-height: 25rem;
		flex-direction: row;
	}

	&::before {
		@include ps_icon(auto, 1.8rem, 2.3rem, auto);
		width: 2.4rem;
		height: .2rem;
		background-color: $color_main;
		transform: translateX(0);
		transition: transform .5s ease-out;
	}
	&::after {
		@include ps_icon(auto, 1.9rem, 1.9rem, auto);
		width: 1rem;
		height: 1rem;
		border: .2rem solid $color_main;
		border-left: 0;
		border-bottom: 0;
		transform: translateX(0) rotate(45deg);
		transition: transform .5s ease-out;
	}

	@media #{$md_devices} {

		&:hover {
			&::before {
				transform: translateX(.8rem);
			}
			&::after {
				transform: translateX(.8rem) rotate(45deg);
			}
		}
	}

	& + & {
		margin-top: 3.2rem;

		@media #{$md_devices} {
			margin-top: 4rem;
		}
	}
}
.front-lineup-value-thumb {
	width: 100%;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		width: 34.4rem;
		min-height: 25rem;
	}
}
.front-lineup-value-thumb__img {
	width: 100%;
	height: 0;
	padding-top: calc( 225 / 311 * 100% );
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media #{$md_devices} {
		height: 100%;
		padding-top: 0;
	}

	img {
		@include ps_icon(-100%, -100%, -100%, -100%);
		content: none;
		width: 100%;
		height: auto;
		z-index: 1;

		@media #{$md_devices} {
			width: auto;
			max-width: inherit;
			height: 100%;
		}
	}
}
.front-lineup-value-thumb__label {
	padding: .6rem 1.5rem .8rem 1.2rem;
	font-size: 2.2rem;
	font-family: $font_english;
	color: #fff;
	line-height: 1;
	letter-spacing: .146rem;
	border-radius: 1.2rem 0 1.2rem 0;
	display: inline-block;
	background-color: $color_main;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;

	@media #{$md_devices} {
		padding: .4rem 1.5rem .8rem 1.2rem;
		font-size: 2.6rem;
	}

	span {
		font-size: 2.6rem;

		@media #{$md_devices} {
			font-size: 3.2rem;
		}
	}
}
.front-lineup-value-description {
	width: 100%;
	padding: 2.4rem 2.4rem 2.8rem;

	@media #{$md_devices} {
		padding: 4rem;
		flex: 1;
	}
}
.front-lineup-value-description__title {
	margin: 0;
	font-size: 1.9rem;
	font-weight: 600;
	font-family: $font_title;
	line-height: 1.5;

	@media #{$md_devices} {
		font-size: 2.8rem;
	}
}
.front-lineup-value-description__text {
	margin-top: 1.3rem;

	@media #{$md_devices} {
		margin-top: 1.6rem;
	}

	span {
		font-size: .875em;
	}
}
.front-lineup-button {
	margin-top: 4rem;
	text-align: center;

	@media #{$md_devices} {
		margin-top: 5.6rem;
	}
}


// ----------------------------------------------------------------
// Measure
// ----------------------------------------------------------------

.front-measure {
	padding: 8.8rem 0 0;
	z-index: 2;
	overflow: hidden;

	@media #{$md_devices} {
		padding: 16rem 0 9rem;
	}
}
.front-measure-content {
	position: relative;
	z-index: 1;

	&::before {
		@include ps_icon(-2.6rem, 0, auto, auto);
		width: 60.7rem;
		height: 56.6rem;
		background: url('../images/common/bg_cloud_lightorange.svg') 50% 50% no-repeat;
		background-size: contain;
		z-index: -1;

		@media #{$md_devices} {
			width: 79.8rem;
			height: 74.4rem;
			top: -4.8rem;
			left: -22.2rem;
			right: auto;
		}
	}

	.headline-01 {
		text-align: center;

		@media #{$md_devices} {
			text-align: left;
		}
	}
}
.front-measure-box {
	margin-top: 3.2rem;
	display: flex;
	flex-direction: column-reverse;

	@media #{$md_devices} {
		margin-top: 4.8rem;
		flex-direction: row;
	}
}
.front-measure-box__description {

	@media #{$md_devices} {
		flex: 1;
	}
}
.front-measure-box__img {
	width: 100%;
	margin: 0 0 3.2rem 0;
	border-radius: 1.2rem;

	@media #{$md_devices} {
		width: 48rem;
		margin: 0 0 0 4rem;
	}
}
.front-measure-button {
	margin-top: 4rem;

	@media #{$md_devices} {
		margin-top: 5.6rem;
		display: flex;
		justify-content: center;
	}
}
.front-measure-button__item {

	& + & {
		margin: 2.4rem 0 0 0;

		@media #{$md_devices} {
			margin: 0 0 0 2.4rem;
		}
	}

	.button--secondary {

		@media #{$md_devices} {
			max-width: 32.4rem;
			text-align: left;
			padding: 1.7rem 6rem 1.7rem 2.4rem;
		}
	}
}


// ----------------------------------------------------------------
// Gift
// ----------------------------------------------------------------

.front-gift {
	padding: 8.8rem 0 0;
	overflow: hidden;

	@media #{$md_devices} {
		margin-top: -9rem;
		padding: 17.3rem 0 29.4rem;
	}
}
.front-gift-content {
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		width: 50%;
		padding-top: 4.4rem;
		text-align: left;
	}

	&::before {
		@include ps_icon(-5.4rem, auto, auto, 0);
		width: 59.4rem;
		height: 61.1rem;
		background: url('../images/common/bg_cloud_lightgreen.svg') 50% 50% no-repeat;
		background-size: contain;
		z-index: -1;

		@media #{$md_devices} {
			width: 78.6rem;
			height: 80.8rem;
			top: auto;
			left: auto;
			right: -150%;
			bottom: -29.4rem;
		}
	}
}
.front-gift-content__text {
	margin: 3.2rem 0 0 0;
	text-align: left;

	@media #{$md_devices} {
		margin: 4rem 0 0 0;
	}
}
.front-gift-content__img {
	width: 92.5%;
	max-width: 40.3rem;
	margin-top: 3.2rem;
	position: relative;
	left: 56%;
	z-index: 1;
	transform: translateX(-50%);

	@media #{$md_devices} {
		width: 44.3rem;
		max-width: inherit;
		margin: 0;
		position: absolute;
		top: 0;
		left: auto;
		right: -51.3rem;
		z-index: -1;
		transform: none;
	}
}
.front-gift-button {
	margin-top: 4rem;
}



// ----------------------------------------------------------------
// News
// ----------------------------------------------------------------

.front-news {
	margin-top: 8.8rem;

	@media #{$md_devices} {
		margin-top: -8rem;
	}

	.headline-01 {
		text-align: center;
	}
}
.front-news-list {
	@include reset;
	margin-top: 1.6rem;
	list-style: none;

	@media #{$md_devices} {
		margin-top: 4rem;
	}
}
.front-news-list__item {
	@include reset;
	padding: 1.6rem 0;
	border-bottom: .1rem solid $color-border;

	@media #{$md_devices} {
		padding: 1.9rem 0;
	}
}
.front-news-link {
	margin-top: 2.4rem;
	font-size: 1.6rem;
	font-weight: 600;
	text-align: right;

	@media #{$md_devices} {
		margin-top: 2rem;
		font-weight: 400;
	}
}


// ----------------------------------------------------------------
// Pick up area
// ----------------------------------------------------------------

.front-pickup-guide {
	margin: 4.8rem auto 0;
	text-align: center;
	@media #{$md_devices} {
		margin: 7.2rem auto 0;
	}

	.inner {
		box-sizing: border-box;
		border: $color_alert solid 2px;
		padding: 1.6rem;
		border-radius: 8px;
	}

	.front-pickup-guide__title {
		color: $color_alert;
		font-weight: 700;
		margin: 0 auto .4rem;
		font-size: 1.8rem;
	}
	.front-pickup-guide__text {
		margin: 0;
	}
}



// ----------------------------------------------------------------
// Pick up banner
// ----------------------------------------------------------------

.front-pickup-banner {
	width: calc( 100% - 6.4rem);
	margin: 5.6rem auto 0;
	text-align: center;
	@media #{$md_devices} {
		margin: 7.2rem auto -3.2rem;
	}
	a {
		&:hover{
		}
	}

}

