@charset "UTF-8";
// ================================================================================
// Mixin
// グローバルなmixinはここに格納します。それ以外はそのscssファイルのみで完結。
// ================================================================================

// -----------------------------------------------------------------
// レイアウト関連
// -----------------------------------------------------------------

// コンテナ
@mixin content($width, $padding) {
	box-sizing: border-box;
	max-width: $width;
	margin-left: auto;
	margin-right: auto;
	@media #{$md_devices} {
		padding-left: $padding;
		padding-right: $padding;
	}
}

// margin, padding リセット
@mixin reset {
	padding: 0;
	margin: 0;
}

// inline-blockレイアウト
@mixin ib($value) {
	text-align: $value;
	letter-spacing: -.40em;
}

@mixin ib_child {
	display: inline-block;
	letter-spacing: normal;
}

// tableレイアウト
@mixin table($value) {
	display: table;
	width: $value;
}

@mixin table_cell($value) {
	display: table-cell;
	vertical-align: $value;
}

// overflow型cleafix
@mixin flow($value) {
	width: $value;
	overflow: hidden;
}

// 背景画像型サムネイルの設定
@mixin img_bg {
	background-attachment: scroll;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

@mixin img_lqd_bg {
	background-attachment: scroll;
	background-repeat: no-repeat;
	background-position: 50% bottom;
	background-size: cover;
}

// clearfix(Compass使わないのでここに記載)
@mixin clearfix {
	zoom: 1;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
	clear: both;
	}
}

// 疑似要素の背景設定
@mixin ps_icon($t: 0, $r: 0, $b: 0, $l: 0) {
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
	margin: auto;
}

// 背景アイコン
@mixin icon($t: 0, $r: 0, $b: 0, $l: 0) {
	box-sizing: border-box;
	position: absolute;
	display: block;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
	margin: auto;
}


// -----------------------------------------------------------------
// アニメーション
// -----------------------------------------------------------------

//アルファアニメーション
@mixin anime_alpha {
	transition: opacity .2s ease-out;
	backface-visibility: hidden;
}
@mixin anime_alpha_input($value) {
	transition: opacity $value ease-out;
}

@mixin anime_alpha_hover($value) {
	opacity: $value / 100;
	filter:alpha(opacity=$value);
}

// 背景アニメーション
@mixin anime_bg {
	transition: background .2s ease-out;
}

// カラーアニメーション
@mixin anime_color {
	transition: color .2s ease-out;
}

// allアニメーション
@mixin anime_all {
	transition: all .2s ease-in-out;
	backface-visibility: hidden;
}

@mixin anime($value) {
	transition: all #{$value}s ease-in-out;
	backface-visibility: hidden;
}

// ボーダーアニメーション
@mixin anime_bdr {
	transition: border .2s ease-out;
}

// アイコン移動のアニメーション
@mixin anime_icon {
	transition: transform .2s ease-out;
}
@mixin anime_icon_hover($value) {
	transform: translateX($value);
}


// -----------------------------------------------------------------
// その他
// -----------------------------------------------------------------

// プレースホルダ
@mixin placeholder_color($value) {
	&:placeholder-shown {
		color: $value;
	}
	&::-webkit-input-placeholder {
		color:$value;
	}
	&:-moz-placeholder {
		color:$value;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$value;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$value;
	}
}

