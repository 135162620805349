@charset "UTF-8";
// ================================================================================
// Global
// グローバルなサイト設定
// ================================================================================

// ----------------------------------------------------------------
// 基本設定
// ----------------------------------------------------------------

.wrapper {

	@media #{$md_devices} {
		min-width: $breakpoint_lg;
	}
}

// layout
// --------------------------------------------------------

.site-content {}
.site-main {
	margin-top: 6.8rem;

	@media #{$md_devices} {
		margin-top: 10rem;
	}
}

.inner {
	width: calc( 100% - 6.4rem );
	margin: 0 auto;
	position: relative;
	z-index: 1;

	@media #{$md_devices} {
		width: calc( 100% - 16rem );
		max-width: 100rem;
	}
}
.inner--sm {

	@media #{$md_devices} {
		max-width: 76rem;
	}
}
.inner--md {

	// @media #{$md_devices} {
	// 	max-width: 144rem;
	// }
}
.inner--lg {

	@media #{$md_devices} {
		max-width: 144rem;
	}
}
.inner--flex {

	@media #{$md_devices} {
		display: flex;
		justify-content: space-between;
	}
}

.container {
	width: 100%;
	position: relative;
	z-index: 1;
}
.container--column {
	width: 100%;

	@media #{$md_devices} {
		display: flex;
		justify-content: space-between;
	}
}

.main-column {
	width: 100%;

	@media #{$md_devices} {
		width: 72%;
		margin-right: 4.8rem;
	}
}
.side-column {
	width: 100%;

	@media #{$sm_devices_only} {

		.single & {
			display: none;
		}
	}

	@media #{$md_devices} {
		width: 23.2rem;
	}
}


// ----------------------------------------------------------------
// align 設定
// ----------------------------------------------------------------

.has-text-align-left {
	text-align: left;
}
.has-text-align-center {
	text-align: center;
}
.has-text-align-right {
	text-align: right;
}



// ----------------------------------------------------------------
// カラー設定
// ----------------------------------------------------------------

//$theme-colors: (
//	default: var(--global--color-default),
//	thin: var(--global--color-thin),
//	main: var(--global--color-main),
//	sub: var(--global--color-sub),
//	bg-primary: var(--global--color-bg-primary),
//	bg-secondary: var(--global--color-bg-secondary),
//	bg-tertiary: var(--global--color-bg-tertiary),
//	border: var(--global--color-border),
//	alert: var(--global--color-alert),
//	white: var(--global--color-white)
//);
//
//// 文字色
//.has-text-color {
//
//	@each $key, $color in $theme-colors {
//		&.has-#{$key}-color {
//			color: $color;
//		}
//	}
//
//}

// 背景
//.has-background {
//	box-sizing: border-box;
//
//	@each $key, $color in $theme-colors {
//		&.has-#{$key}-background-color {
//			background-color: $color;
//			padding: 2rem;
//			@media #{$md_devices} {
//				padding: 4rem;
//			}
//			> * {
//				&:first-child {
//					margin-top: 0;
//				}
//				&:last-child {
//					margin-bottom: 0;
//				}
//			}
//		}
//	}
//
//}
//
//// NOTE: エディタ外では padding 要素が不要がケースが多いので切る
//@each $key, $color in $theme-colors {
//	.has-#{$key}-background-color:not(.has-background) {
//		background-color: $color !important;
//	}
//}


// ----------------------------------------------------------------
// フォント設定
// ----------------------------------------------------------------


// 特小 sm:10px, md:12px
.has-extra-small-font-size {
	font-size: 71%;
	@media #{$md_devices} {
		font-size: 75%;
	}
}

// 小 sm:13px, md:14px
.has-small-font-size {
	font-size: 93%;
	@media #{$md_devices} {
		font-size: 88%;
	}
}

// デフォルト sm:14px, md:16px
.has-regular-font-size,
.has-normal-font-size {
	font-size: 1.4rem;
	@media #{$md_devices} {
		font-size: 1.6rem;
	}
}

// 大 sm:16px, md:18px
.has-large-font-size {
	font-size: 114%;
	@media #{$md_devices} {
		font-size: 113%;
	}
}

// 特大 sm:21px, md:28px
.has-extra-large-font-size {
	font-size: 150%;
	@media #{$md_devices} {
		font-size: 175%;
	}
}

//$font-sizes: (
//	minimum: var(--global--font-size-2xs),
//	extra-small: var(--global--font-size-xs),
//	small: var(--global--font-size-sm),
//	normal: var(--global--font-size-md),
//	large: var(--global--font-size-lg),
//	extra-large: var(--global--font-size-xl),
//	huge: var(--global--font-size-2xl),
//	maximum: var(--global--font-size-3xl)
//);
//
//@each $key, $size in $font-sizes {
//	.has-#{$key}-font-size {
//		font-size: $size;
//	}
//}


// ----------------------------------------------------------------
// 幅の設定
// ----------------------------------------------------------------

// NOTE: WPのデフォルトの継承
//.default-max-width {
//	@include content( var(--aligndefault-width), var(--global--spacing-content) );
//}
//
//.wide-max-width {
//	@include content( var(--alignwide-width), var(--global--spacing-content) );
//}
//
//.full-max-width {
//	@include content( 100%, 0 );
//}
